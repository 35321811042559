import ReactHtmlParser from "html-react-parser";
import { v4 as uuid } from "uuid";
import { contains } from "./utils";

export const readXMLLesson = (xml: string, excludeQual: boolean = false) => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xml, "text/xml");

  const lesson = xmlDoc.getElementsByTagName("lesson");

  if (
    lesson.length > 0 &&
    !contains(window.location.href, ["/dashboard", "/editor", "/admin"])
  ) {
    const startPart = xmlDoc.createElement("part");
    startPart.setAttribute("type", "lessonintro");
    startPart.id = uuid();
    startPart.innerHTML = "";

    lesson[0].insertBefore(startPart, lesson[0].firstElementChild);

    const pronunciationPart = xmlDoc.createElement("part");
    pronunciationPart.setAttribute("type", "sound");
    pronunciationPart.id = uuid();
    pronunciationPart.innerHTML = "";

    lesson[0].insertBefore(pronunciationPart, lesson[0].lastElementChild);

    const writingPart = xmlDoc.createElement("part");
    writingPart.setAttribute("type", "writer");
    writingPart.id = uuid();
    writingPart.innerHTML = "";

    lesson[0].insertBefore(writingPart, lesson[0].lastElementChild);

    const endPart = xmlDoc.createElement("part");
    endPart.setAttribute("type", "lessonend");
    endPart.id = uuid();
    endPart.innerHTML = "";

    lesson[0].appendChild(endPart);

    const qual = lesson[0]
      .querySelector("part[type=quiz]")
      ?.cloneNode(true) as HTMLElement;
    qual.id = qual?.id + "gfgh2";
    qual?.setAttribute("type", "qual");

    // lesson[0].insertBefore(qual, lesson[0].firstElementChild);
  }

  return xmlDoc;
};

export const readXMLExercise = (xml: Document, count: number) => {
  return xml.childNodes[count];
};

export const readXMLExerciseById = (xml: Document, id: string) => {
  return xml.getElementById(id);
};

export const getLesson = (lessonData: Document, part: number) => {
  const thisLesson = lessonData.getElementsByTagName("part")?.[part]?.id || "";
  return thisLesson;
};

export const prepareFormat = (html: string) => {
  html = html.replace(/\*(.*?)\*/g, "<span class='bold'>$1</span>");
  html = html.replace(/_(.*?)_/g, "<span class='italic'>$1</span>");
  html = html.replace(/\{(.*?)\}/g, "<span class='colored'>$1</span>");
  return ReactHtmlParser(html);
};

export const prepareFormatTerms = (html: string) => {
  html = html.replace(/\*(.*?)\*/g, "<span class='termbold'>$1</span>");
  html = html.replace(/_(.*?)_/g, "<span class='italic'>$1</span>");
  html = html.replace(/\{(.*?)\}/g, "<span class='colored'>$1</span>");
  return ReactHtmlParser(html);
};

// export const prepareTableRow = (html: string, columns: string) => {
//   const sizes = columns.split(",");
//   return html.split("|").map((item: string, index: number) => (
//     <Grid
//       item
//       xs={sizes[index] as any}
//       key={index}
//       className={index ? "tablecell" : "tablecellfirst"}
//     >
//       {prepareFormat(item)}
//     </Grid>
//   ));
// };
