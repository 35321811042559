import React, { useState } from "react";

import { createStyles, makeStyles } from "@material-ui/styles";
import AccountResale from "account/account.resale";
import NewUserAdd from "UserEditor/userEditor.newUserAdd";
import { Button } from "semantic-ui-react";
import { tx } from "utils/globalize";
import EmailDlg from "./account.emailshow";

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    cat: {
      marginBottom: "0px !important",
      height: "100%",
      position: "absolute",
      top: "0px",
      bottom: "0px",
      left: "0px",
      right: "0px",
      overflowY: "auto",
      padding: "30px",
      "@media (max-width: 500px)": {
        padding: "15px",
      },
    },
    wrapper: {
      marginTop: "12px",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      overflow: "auto",
      marginBottom: "0px !important",
    },
    titleWrapper: { display: "flex", width: "100%", marginBottom: "3px" },
    texti: { fontSize: "18px", marginTop: "0px" },
    listitem: { display: "flex", marginBottom: "15px" },
    intro: { display: "flex", marginBottom: "15px" },
    introin: { display: "flex", marginBottom: "15px", paddingLeft: "15px" },
    number: { flex: "0 0 30px" },
    emails: { display: "flex", marginTop: "15px" },
    company: { fontWeight: "bold", marginTop: "15px", marginBottom: "10px" },
    emaillink: {
      color: "blue",
      textDecoration: "underline",
      paddingLeft: "30px",
    },
    listtext: { flex: "1 1 auto" },
    link: { fontWeight: "bold", marginTop: "10px" },
    title: {
      flex: "1 1 auto",
      fontWeight: "bold",
      marginBottom: "5px",
      fontSize: "16px",
      marginTop: "4px",
    },
    share: { marginTop: "10px", display: "flex" },
    left: { flex: "1 1 auto" },
    right: { flex: "1 1 auto", textAlign: "right" },
    mitem: { padding: "2px !important" },
  })
);

const AccountTipsBuy = (props: any) => {
  const classes = useStyles();
  const [userDlgOpen, setUserDlgOpen] = useState(false);
  const [emailOpen, setEmailOpen] = useState(false);

  return (
    <React.Fragment>
      <div className={classes.cat}>
        {/* <div className={classes.titleWrapper}>
          <div className={classes.title}>Info and Tips</div>
        </div> */}

        <div className={classes.texti}>
          <div className={classes.intro}>
            {tx("a12345d01", "This option can be used if:")}
          </div>
          <div className={classes.introin}>
            {tx(
              "a12345d011",
              "1) you want to gift WizzWords to your students or friends."
            )}
          </div>
          <div className={classes.introin}>
            {tx(
              "a12345d012",
              "2) you can't write invoices but you want to sell or give WizzWord licences to your students or friends."
            )}
          </div>
          <div className={classes.introin}>
            {tx(
              "a12345d013",
              "3) you want to give WizzWords as a bonus to your students for example as part of a campaign."
            )}
            <br />
          </div>
          <hr style={{ marginTop: "40px", marginBottom: "40px" }} />
          <div className={classes.listitem}>
            <div className={classes.number}>1.</div>
            <div className={classes.listtext}>
              {tx(
                "a12345d014ca",
                "To buy WizzWords licences click on the Buy More button in the lower left corner and then select the amount."
              )}
              <div style={{ height: "25px" }}></div>
              <AccountResale
                account={props.account}
                formChanged={props.formChanged}
                type={"buy"}
                prices={props.prices}
              />
              <div style={{ height: "25px" }}></div>
              {tx(
                "a12345d016",
                "Available means licences you can give out to people."
              )}{" "}
              {tx("a12345d017", "Used means licences already given away.")}
            </div>
          </div>

          <div className={classes.listitem}>
            <div className={classes.number}>3.</div>
            <div className={classes.listtext}>
              {tx(
                "a12345d018",
                "Now, if you have available licences, you can add new users by clicking on the *Add User* button and then typing in the email addresses you want to send the licences to."
              )}
              <div
                style={{
                  textAlign: "center",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <Button
                  onClick={() => setUserDlgOpen(true)}
                  style={{ minWidth: "100px" }}
                >
                  {tx("a12345d019", "Add User")}
                </Button>
              </div>
            </div>
          </div>

          <div className={classes.listitem}>
            <div className={classes.number}>4.</div>
            <div className={classes.listtext}>
              {tx(
                "a12345d0110",
                "You cannot revoke licences assigned in this way, but assigning to an already used email address will not use a new licence."
              )}
              <div style={{ height: "15px" }}></div>
              {tx(
                "a12345d0111",
                "An email will be sent to the email addresses with the login information."
              )}
              <div style={{ height: "15px" }}></div>
              {tx(
                "a12345d0112",
                "You can type in more than one email address separated by commas."
              )}
            </div>
          </div>
          <div className={classes.listitem}>
            <div className={classes.number}>5.</div>
            <div className={classes.listtext}>
              {tx(
                "a12345d0113cc",
                "If you want to promote the app or give some data to your students you can use these pre-made texts."
              )}
              <div className={classes.emails}>
                <div className={classes.emails}>
                  <Button onClick={() => setEmailOpen(true)}>
                    {tx("askdoweokwas", "Sample Emails")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NewUserAdd
        open={userDlgOpen}
        setOpen={setUserDlgOpen}
        account={props.account}
        setActive={props.setActive}
        doSearch={() => {}}
      />
      <EmailDlg open={emailOpen} setOpen={setEmailOpen} />
    </React.Fragment>
  );
};

export default AccountTipsBuy;
// AIzaSyA8d9SleAVkuA1BwyrcvBypzmt9rFDXFrA
