import React from "react";
import { Transition, Modal, Button } from "semantic-ui-react";
import { makeStyles, createStyles } from "@material-ui/styles";
//Import localization
import "survey-core/survey.i18n.js";
//Import Survey styles
import "survey-core/defaultV2.css";
import { tx } from "utils/globalize";
import { useSelector } from "react-redux";
import { RootState } from "Redux/reducers/_index";

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    textarea: {
      marginBottom: "15px",
      fontSize: "18px",
    },
    line: {
      paddingTop: "5px",
    },
    buttondiv: {
      textAlign: "right",
      marginBottom: "10px",
      paddingBottom: "10px",
      borderBottom: "1px solid lightgrey",
    },
    linkdiv: { color: "blue" },
  })
);

function copyDivToClipboard(name: string) {
  setTimeout(() => {
    var range = document.createRange();
    range.selectNode(document.getElementById(name) as any);
    window.getSelection()?.removeAllRanges(); // clear current selection
    window.getSelection()?.addRange(range); // to select text
    document.execCommand("copy");
  }, 100);
  //window.getSelection()?.removeAllRanges(); // to deselect
}

function fallbackCopyTextToClipboard(text: string) {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand("copy");
    var msg = successful ? "successful" : "unsuccessful";
    console.log("Fallback: Copying text command was " + msg);
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
}
function copyTextToClipboard(text: string) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      console.log("Async: Copying to clipboard was successful!");
    },
    function (err) {
      console.error("Async: Could not copy text: ", err);
    }
  );
}

const EmailDlg = (props: any) => {
  //eslint-disable-next-line
  const classes = useStyles();
  const currentUser = useSelector<RootState, any>((state) => state.currentUser);

  const close = async () => {
    props.setOpen(false);
  };

  return (
    <React.Fragment>
      <Transition
        unmountOnHide
        visible={props.open}
        animation="fade"
        duration={250}
      >
        <Modal size="small" open={true} onClose={() => props.setOpen(false)}>
          <Modal.Header
            style={{ padding: "5px 15px 10px 15px", display: "flex" }}
            color="olive"
          >
            <div style={{ paddingTop: "9px" }}>
              {tx("a12345c01wewwee", "Sample promotional texts")}
            </div>
          </Modal.Header>
          <Modal.Content
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "20px",
              overflowY: "auto",
              height: "450px",
              fontSize: "18px",
            }}
            id="list5"
          >
            <div className={classes.textarea} id="em1">
              {tx(
                "myu1",
                "I recently found an app I'd really recommend you to improve your vocabulary."
              )}
              <br />
              {tx("myu10", "It's called WizzWords and it costs only 39 Euros.")}
              <br />
              {tx(
                "myu11",
                "I do think it's worth it, as you will save a lot of time learning words faster (and not forgetting them every time)."
              )}
              <br />
              {tx(
                "myu12",
                "There're tons of apps around, and it is sometimes hard to chooose."
              )}{" "}
              {tx("myu13", "This one will definitely help you.")}{" "}
              {tx(
                "myu14",
                "Especially as it helps you to learn whenever you have a moment to work with it."
              )}
              <br />
              {tx(
                "myu15",
                "They also have a 14-day money-back guarantee, but I don't think you will need it."
              )}
              <br />
              {tx("myu16", "You can check out their webpage under this link:")}
              <br />
              <div className={classes.linkdiv}>
                https://wizzwords.net/{props.ccode}
              </div>
            </div>
            <div></div>
            <div className={classes.buttondiv}>
              <Button
                onClick={() =>
                  copyTextToClipboard(
                    document.getElementById("em1")?.innerText || ""
                  )
                }
              >
                {tx("copyoweowe", "Copy to clipboard")}
              </Button>
            </div>
            <div className={classes.textarea} id="em2">
              {tx(
                "myu17",
                "We always worked to help you learning English any way we could. We recently discovered a cheap, super simple, super useful app which could really help you improving your vocabulary."
              )}
              <br />
              {tx(
                "myu18",
                "It's called WizzWords and it is an ideal companion for learners of English no matter how you learn."
              )}
              <br />
              {tx(
                "myu19",
                "It turns the painful process of increasing your vocabulary into a more entertaining activity and can save you lots of hours of study, because it doesn't just give you a bunch of new words, it uses a scientific method to teach them, and all words have example sentences."
              )}
              <br />
              {tx(
                "myu20",
                "There are texts from beginner to advanced, all broken down into five levels - so you can start and continue your study always at the proper level."
              )}
              <br />
              {tx(
                "myu21",
                "You can check out their webpage and buy the app under this link:"
              )}
              <br />
              <div className={classes.linkdiv}>
                https://wizzwords.net/{props.ccode}
              </div>
            </div>
            <div className={classes.buttondiv}>
              <Button
                onClick={() =>
                  copyTextToClipboard(
                    document.getElementById("em2")?.innerText || ""
                  )
                }
              >
                {tx("copyoweowe", "Copy to clipboard")}
              </Button>
            </div>

            <div className={classes.textarea} id="em3">
              {tx(
                "myu22",
                "There is a new Vocabulary building app called WizzWords available in Italian which is all the buzz now."
              )}
              <br />
              {tx(
                "myu23",
                "I see many teachers and companies offering it now and I recommend it to you as well."
              )}
              <br />
              {tx(
                "myu24",
                "It takes a different approach than other apps and it says that it can triple the efficiency of learning words."
              )}{" "}
              {tx("myu25", "I can't verify that but it can definitely help.")}
              <br />
              {tx(
                "myu26",
                "It has more than 3000 words all with lots of simple example sentences, and they can definitely make study much easier."
              )}
              <br />
              {tx(
                "myu27",
                "There's a cool pronunciation module as well, and it can be a great extra to any other learning activities."
              )}
              <br />
              {tx("myu28", "It costs only 39 Euros as an introductory price.")}
              <br />
              {tx(
                "myu29",
                "You can check out its webpage, and buy the app under this link:"
              )}
              <br />
              <div className={classes.linkdiv}>
                https://wizzwords.net/{props.ccode}
              </div>
            </div>

            <div className={classes.buttondiv}>
              <Button
                onClick={() =>
                  copyTextToClipboard(
                    document.getElementById("em3")?.innerText || ""
                  )
                }
              >
                {tx("copyoweowe", "Copy to clipboard")}
              </Button>
            </div>

            <div className={classes.textarea} id="em4">
              {tx(
                "myu30",
                "There's a new app I recommend to my students to work with when not having lessons."
              )}
              <br />
              {tx(
                "myu32",
                "It's called WizzWords and it contains over 120 short stories taken from everyday life, with vocabulary selected from the most important subjects."
              )}
              <br />
              {tx(
                "myu33",
                "You can also LISTEN to these stories, and if you don't understand something, you receive instant help with the fitting definitions and example sentences."
              )}
              <br />
              {tx(
                "myu34",
                "The cool thing is that the app provides you with a personal digital vocabulary list, which teaches itself - you add the words you study, and it leads you through the learning process."
              )}{" "}
              {tx(
                "myu34a",
                "It utilizes a proven scientific method (called SM-2) with special word cards - that they say triples the effectiveness of learning words."
              )}
              <br />
              {tx(
                "myu35",
                "The app also contains the 3000 most important words of spoken English (and another 2000 for a little bonus) and it presents those in a very practical application, you can now use to learn them faster, more easily and definitely with less pain."
              )}
              <br />
              {tx(
                "myu36",
                "You will need tons of words to speak good English, and this app is a great way to get there. Also it is cheap."
              )}
              <br />
              {tx("myu37", "Check it out here:")}
              <br />
              <div className={classes.linkdiv}>
                https://wizzwords.net/{props.ccode}
              </div>
            </div>
            <div className={classes.buttondiv}>
              <Button
                onClick={() =>
                  copyTextToClipboard(
                    document.getElementById("em4")?.innerText || ""
                  )
                }
              >
                {tx("copyoweowe", "Copy to clipboard")}
              </Button>
            </div>

            <div className={classes.textarea} id="em5">
              <br />
              {tx(
                "myu41",
                "WizzWords is the ideal companion app for learner's of English no matter how you learn."
              )}
              <br />
              {tx(
                "myu42",
                "- Over 125 texts on six levels from beginner to advanced"
              )}
              <br />
              {tx("myu43", "- All texts come recorded by native speakers.")}
              <br />
              {tx(
                "myu44",
                "- Every single word available with the fitting definition and multiple SIMPLE example sentences."
              )}
              <br />
              {tx(
                "myu45",
                "- Add newly learned words to your vocabulary list and learn them with special study cards through example sentences."
              )}
              <br />
              {tx(
                "myu46",
                "- Use the scientific SM-2 word learning method to triple the efficiency of learning these words."
              )}
              <br />
              {tx(
                "myu47",
                "- Watch the special Voice of America English study video series and study them with the same method."
              )}
              <br />
              {tx(
                "myu48",
                "- Learn the most important 3000 words of the English language specially selected for you."
              )}
              <br />
              {tx(
                "myu49",
                "- Try and work with our special pronunciation drills to sound more like a native speaker."
              )}
              <br />
              <div className={classes.linkdiv}>
                https://wizzwords.net/{props.ccode}
              </div>
            </div>
            <div className={classes.buttondiv}>
              <Button
                onClick={() =>
                  copyTextToClipboard(
                    document.getElementById("em5")?.innerText || ""
                  )
                }
              >
                {tx("copyoweowe", "Copy to clipboard")}
              </Button>
            </div>
            {currentUser?.type !== "admin" ? (
              <React.Fragment>
                <div className={classes.textarea} id="em6">
                  <div className={classes.line}>
                    {tx(
                      "cuma01",
                      "Utilize your contact list of students and customers..."
                    )}
                  </div>
                  <div className={classes.line}>{tx("cuma02", "Hi,")}</div>
                  <div className={classes.line}>
                    {tx(
                      "cuma03",
                      "Our company NetEnglish Solutions OÜ sells an innovative special English vocabulary learning tool to mailing lists and customers of already existing, successful English teaching websites, schools and private teachers."
                    )}
                  </div>
                  <div className={classes.line}>
                    {tx(
                      "cuma04",
                      "The app can help your students and provide you with extra income utilizing your already existing contacts with very little extra work."
                    )}
                  </div>
                  <div className={classes.line}>
                    {tx("cuma05", "The simplicity is this:")}{" "}
                    {tx(
                      "cuma06",
                      "If you offer this app to your students and customers and they buy it, you receive 60% of the net of what they pay."
                    )}
                  </div>
                  <div className={classes.line}>
                    {tx(
                      "cuma07",
                      "As the price of the app is 39 Euros, you will receive a net 13 Euros after each app sold."
                    )}
                  </div>
                  <div className={classes.line}>
                    {tx(
                      "cuma08",
                      "At the simplest level you only have to customize and send out our pre-written emails to your students, and send us an invoice for your commission for anything sold and be done with it."
                    )}
                  </div>
                  <div className={classes.line}>
                    {tx(
                      "cuma09",
                      "But there are other options and you can even sell the app on your own webpage."
                    )}
                  </div>
                  <div className={classes.line}>
                    {tx("cuma010", "Is the app good?")}
                  </div>
                  <div className={classes.line}>
                    {tx(
                      "cuma011",
                      "The app is a very advanced vocabulary learning tool."
                    )}{" "}
                    {tx("cuma012", "You can read more about it [[[here]]].")}{" "}
                    {tx(
                      "cuma014",
                      "In Hungary where we first tested it with a company, we sold 2700 apps to a mailing list of 40000 in five days."
                    )}
                  </div>
                  <div className={classes.line}>
                    {tx(
                      "cuma015",
                      "The app got very good feedback, the refund rate is about 0.5% (despite a 14-day no-questions-asked money back guarantee). You students get value for their money."
                    )}{" "}
                    {tx(
                      "cuma016",
                      "We handle customer service, so you don’t get stuck with that either."
                    )}
                  </div>
                  <div className={classes.line}>
                    {tx(
                      "cuma015a",
                      "It doesn’t undermine your business in any way (unless you are selling a similar app yourself) and by contract we DO NOT use the personal data of people you send to us for ANY marketing purposes."
                    )}
                  </div>
                  <div className={classes.line}>
                    {tx("cuma017", "How can I offer it or sell it?")}
                  </div>
                  <div className={classes.line}>
                    {tx("cuma018", "You have three options:")}
                  </div>
                  <div className={classes.line}>
                    {tx(
                      "cuma019",
                      "1. You can either just send out emails or social media messages to your people with a special link to our page where they can buy the app, and then send us an invoice after the sold apps and we transfer your commission to you."
                    )}{" "}
                    {tx("cuma020", "(Best for teachers.)")}
                  </div>
                  <div className={classes.line}>
                    {tx(
                      "cuma021",
                      "2. You can buy licences yourself and sell or give them away any way you see fit."
                    )}{" "}
                    {tx(
                      "cuma022",
                      "(For example a language school can give it as an extra tool to all students, but you can also sell those licences in any way you see fit and for any price you see fit.)"
                    )}
                  </div>
                  <div className={classes.line}>
                    {tx(
                      "cuma023",
                      "3. If you have your own webshop you can contact us and you can be a reseller in which case you sell the app in your own webshop and pay for the licences to us at regular intervals."
                    )}
                  </div>
                  <div className={classes.line}>
                    {tx("cuma024", "It’s made easy")}
                  </div>
                  <div className={classes.line}>
                    {tx(
                      "cuma025",
                      "To follow your earnings and set up your work with us you will have access to a special website, where you can get the prewritten emails, see the number of apps sold through your promotion and the money paid to you AND you can set it up so, that in the app itself your personal business or company is promoted."
                    )}
                  </div>
                  <div className={classes.line}>
                    {tx("cuma026", "We know it’s new...")}
                  </div>
                  <div className={classes.line}>
                    {tx(
                      "cuma027",
                      "We know it’s a new idea and a new business model, but we recommend you try it."
                    )}{" "}
                    {tx(
                      "cuma028",
                      "You’ve got nothing to loose, your students will be happy, and you can earn some (or even a lot of) money in the process."
                    )}
                  </div>
                  <div className={classes.line}>
                    {tx(
                      "cuma029",
                      "You often recommend your students various tools."
                    )}{" "}
                    {tx("cuma030", "Why not earn some money meanwhile?")}
                  </div>
                  <div className={classes.line}>
                    {tx(
                      "cuma031",
                      "See our webpage where you can sign up free and without any obligations:"
                    )}
                  </div>
                  <div className={classes.line}>
                    {tx("cuma032", "Best regards,")}
                  </div>
                </div>
                <div className={classes.buttondiv}>
                  <Button
                    onClick={() =>
                      copyTextToClipboard(
                        document.getElementById("em6")?.innerText || ""
                      )
                    }
                  >
                    {tx("copyoweowe", "Copy to clipboard")}
                  </Button>
                </div>
              </React.Fragment>
            ) : null}
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={close}>{tx("a12345cijlk04", "Close")}</Button>
          </Modal.Actions>
        </Modal>
      </Transition>
    </React.Fragment>
  );
};

export default EmailDlg;
