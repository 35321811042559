import React, { useEffect, useState } from "react";
import { Form, Message, Image } from "semantic-ui-react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useDropzone } from "react-dropzone";
import { cloudStorage } from "../firebaseUtil/firebaseUtil";
import { useSelector } from "react-redux";
import { RootState } from "Redux/reducers/_index";
import { tx } from "utils/globalize";
import { v4 } from "uuid";

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    segment: { padding: "0px !important", marginTop: "0px !important" },
  })
);

const CustomizePic = (props: any) => {
  const classes = useStyles();
  const [files, setFiles] = useState<any>([]);

  const ccodeGlobal = useSelector<RootState, any>((state) => state.ccodeGlobal);

  const [picUrl, setPicUrl] = useState("");

  console.log(props);

  const { getRootProps, getInputProps } = useDropzone({
    accept: { "image/*": [".png", ".gif", ".jpeg", ".jpg"] },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );

      if (acceptedFiles[0]) {
        uploadPic(acceptedFiles[0], ccodeGlobal, props.saveCustomize.current);
      }
    },
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file: any) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  useEffect(() => {
    const getPic = async () => {
      setPicUrl(
        files[0]?.preview ||
          (await getProfileUrl(props.pic)) ||
          "/images/imageph.png"
      );
    };
    if (props.pic) {
      getPic();
    }
  }, [ccodeGlobal, files, props.pic]);

  return (
    <React.Fragment>
      <Form
        style={{
          position: "relative",
          flexDirection: "column",
          height: "calc(100% - 55px)",
        }}
      >
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <Message icon style={{ marginBottom: "8px" }}>
            <Image
              spaced="right"
              circular
              size="mini"
              src={picUrl || "/images/download.png"}
            />
            <Message.Content>
              <Message.Header>{tx("a12345e01", "Your picture")}</Message.Header>
              {tx("a12345e02", "Drop your picture here.")}
            </Message.Content>
          </Message>
        </div>
      </Form>
    </React.Fragment>
  );
};

export default CustomizePic;

const getProfileUrl = async (ccode: string) => {
  let url;

  try {
    url = await cloudStorage
      .refFromURL("gs://netenglishm.appspot.com/profilepics/" + ccode + ".png")
      .getDownloadURL();

    return url;
  } catch (error) {
    return "/images/download.png";
  }
};

const uploadPic = (file: any, ccode: any, customizeSave: any) => {
  const id = v4();

  cloudStorage
    .ref()
    .child("profilepics/" + id + ".png")
    .put(file)
    .then((snapshot) => {
      console.log("Uploaded.");
      customizeSave(id);
      // console.log(customizeSave);
    })
    .catch((e) => console.log(e));
};
