import React from "react";
import { useSelector } from "react-redux";
import { Button, Dropdown, Form, Segment, TextArea } from "semantic-ui-react";

import { createStyles, makeStyles } from "@material-ui/styles";

import { firestore } from "../firebaseUtil/firebaseUtil";
import { RootState } from "../Redux/reducers/_index";
import { countries } from "./account.flags";

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    cat: {
      marginBottom: "0px !important",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },

    titleWrapper: { display: "flex", width: "100%", marginBottom: "3px" },
    title: {
      flex: "1 1 auto",
      fontWeight: "bold",
      marginBottom: "5px",
      fontSize: "16px",
      marginTop: "4px",
    },

    invisible: { display: "none !important" },
  })
);

const AccountPersData = (props: any) => {
  const classes = useStyles();

  const ccodeGlobal = useSelector<RootState, string>(
    (state) => state.ccodeGlobal
  );

  const { account, setAccount, formChanged, setFormChanged } = props;

  const selectCountry = (e: any, data: any) => {
    setFormChanged(true);
    setAccount({ ...account, [data.name]: data.value });
  };

  const changeField = (e: any) => {
    setFormChanged(true);

    let taxAdd = {};

    if (e.currentTarget.id === "companyName" && e.currentTarget.value === "") {
      taxAdd = { taxNumber: "" };
    }

    setAccount({
      ...account,
      [e.currentTarget.id]: e.currentTarget.value,
      ...taxAdd,
    });
  };

  const saveAccount = async () => {
    await firestore
      .doc("/companies/" + ccodeGlobal + "/data/account")
      .set(account, { merge: true });
    setFormChanged(false);
  };

  return (
    <React.Fragment>
      <Segment className={classes.cat} color="olive">
        <div className={classes.titleWrapper}>
          <div className={classes.title}>Invoicing Data</div>
        </div>
        <Form
          size="small"
          style={{
            position: "relative",
            flexDirection: "column",
            height: "calc(100% - 55px)",
          }}
        >
          <Form.Group widths="equal">
            <Form.Field error={!account?.firstName} required>
              <label>First Name</label>
              <input
                id="firstName"
                type="text"
                value={account?.firstName || ""}
                onChange={changeField}
              />
            </Form.Field>
            <Form.Field error={!account?.lastName} required>
              <label>Last Name</label>
              <input
                id="lastName"
                type="text"
                value={account?.lastName || ""}
                onChange={changeField}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field error={!verifyEmail(account?.email || "")} required>
              <label>E-mail</label>
              <input
                disabled
                id="email"
                type="email"
                value={account?.email || ""}
                onChange={changeField}
              />
            </Form.Field>
            <Form.Field>
              <label>City</label>
              <input
                id="city"
                type="text"
                value={account?.city || ""}
                onChange={changeField}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field error={!account?.country} required>
              <label>Country</label>
              <Dropdown
                placeholder="Country"
                search
                selection
                options={countries}
                onChange={selectCountry}
                name={"country"}
                value={account?.country || ""}
              />
            </Form.Field>
            <Form.Field
              error={!account?.zip}
              required
              style={{ display: "none" }}
            >
              <label>Zip</label>
              <input
                id="zip"
                type="text"
                value={account?.zip || ""}
                onChange={changeField}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal" style={{ display: "none" }}>
            <Form.Field error={!account?.address} required>
              <label>Address</label>
              <input
                id="address"
                type="text"
                value={account?.address || ""}
                onChange={changeField}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal" style={{ display: "none" }}>
            <Form.Field>
              <label>Company Name:</label>
              <input
                id="companyName"
                type="text"
                value={account?.companyName || ""}
                onChange={changeField}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal" className={classes.invisible}>
            <Form.Field error={account?.isCompany && !account?.taxNumber}>
              <label>Tax number:</label>
              <input
                id="taxNumber"
                type="text"
                value={account?.taxNumber || ""}
                onChange={changeField}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal" style={{ display: "none" }}>
            <Form.Field>
              <label>Currency</label>
              <input
                id="currency"
                onChange={changeField}
                value={account?.currency || "EUR"}
              />
            </Form.Field>
            <Form.Field style={{ textAlign: "right" }}>
              <Button positive onClick={saveAccount} disabled={!formChanged}>
                Save
              </Button>
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field style={{ textAlign: "right" }}>
              <Button positive onClick={saveAccount} disabled={!formChanged}>
                Save
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>
      </Segment>
    </React.Fragment>
  );
};

export default AccountPersData;
// AIzaSyA8d9SleAVkuA1BwyrcvBypzmt9rFDXFrA

const verifyEmail = (value: any) => {
  var emailRex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
};
