import React from "react";

import { createStyles, makeStyles } from "@material-ui/styles";

import CreatorWord from "./creator.word";

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    text: {},
  })
);

const CreatorSentence = (props: any) => {
  // const classes = useStyles();
  // const [words, setWords] = useState<any[]>([]);

  // useEffect(() => {
  //   if (props.sentence) {
  //     let tempWords = props.sentence.full
  //       .replace(/[. -()/'"'?,]/g, " ")
  //       .replace(/\s{2,}/g, " ")
  //       .trim()
  //       .split(" ");
  //     setWords(tempWords);
  //   }
  // }, [props.sentence]);

  return (
    <React.Fragment>
      {props.sentence.map((word: string, index: number) => (
        <CreatorWord
          word={word}
          key={props.title + index}
          mergeWord={props.mergeWord}
          scount={props.scount}
          openWord={props.openWord}
          title={props.title}
          wcount={index}
          loaded={props.loaded}
          getSentence={props.getSentence}
          myChange={props.myChange}
          openDlg={props.openDlg}
        />
      ))}
    </React.Fragment>
  );
};

export default CreatorSentence;
