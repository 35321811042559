import React, { useEffect, useState } from "react";
import { Transition, Modal, Form, Button } from "semantic-ui-react";
import { makeStyles, createStyles } from "@material-ui/styles";
//Import localization
import "survey-core/survey.i18n.js";
//Import Survey styles
import "survey-core/defaultV2.css";
import { firestore } from "firebaseUtil/firebaseUtil";
import { useSelector } from "react-redux";
import { RootState } from "Redux/reducers/_index";
import { setBookOpen } from "Redux/reducers/reducer.bookOpen";
import { tx } from "utils/globalize";

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    text: {},
  })
);

const BankdataDlg = (props: any) => {
  //eslint-disable-next-line
  const classes = useStyles();
  const ccodeGlobal = useSelector<RootState, string>(
    (state) => state.ccodeGlobal
  );

  const changeField = (e: any) => {
    props.setAccount({
      ...props.account,
      bank: {
        ...props.account.bank,
        [e.currentTarget.id]: e.currentTarget.value,
      },
    });
  };

  const saveAccount = async () => {
    await firestore
      .doc("/companies/" + ccodeGlobal + "/data/account")
      .set(props.account, { merge: true });

    props.setOpen(false);
  };

  return (
    <React.Fragment>
      <Transition
        unmountOnHide
        visible={props.open}
        animation="fade"
        duration={250}
      >
        <Modal size="small" open={true} onClose={() => props.setOpen(false)}>
          <Modal.Header
            style={{ padding: "0px 15px", display: "flex" }}
            color="olive"
          >
            <div style={{ paddingTop: "9px" }}>
              {tx("a12345c01", "Bank Account Data")}
            </div>
          </Modal.Header>
          <Modal.Content
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "20px",
              overflowY: "auto",
              height: "450px",
            }}
            id="list5"
          >
            <Form
              size="small"
              style={{
                position: "relative",
                flexDirection: "column",
                height: "calc(100% - 55px)",
              }}
            >
              <Form.Group widths="equal">
                <Form.Field required>
                  <label>{tx("a12345c02", "Account Holder Name")}</label>
                  <input
                    id="accountName"
                    type="text"
                    value={props.bank?.accountName || ""}
                    onChange={changeField}
                  />
                </Form.Field>
                <Form.Field required>
                  <label>{tx("a12345c03", "Account Number")}</label>
                  <input
                    id="accountNumber"
                    type="text"
                    value={props.bank?.accountNumber || ""}
                    onChange={changeField}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field required>
                  <label>{tx("a12345c02wewe", "Country")}</label>
                  <input
                    id="accountCountry"
                    type="text"
                    value={props.bank?.accountCountry || ""}
                    onChange={changeField}
                  />
                </Form.Field>
                <Form.Field required>
                  <label>{tx("a12345c03city", "City")}</label>
                  <input
                    id="accountCity"
                    type="text"
                    value={props.bank?.accountCity || ""}
                    onChange={changeField}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field required>
                  <label>{tx("a12345c02wewwee", "Zip")}</label>
                  <input
                    id="accountZip"
                    type="text"
                    value={props.bank?.accountZip || ""}
                    onChange={changeField}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={saveAccount}>{tx("a12345c04", "Save")}</Button>
          </Modal.Actions>
        </Modal>
      </Transition>
    </React.Fragment>
  );
};

export default BankdataDlg;
