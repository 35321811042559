import AuthCheck from "app/App.authCheck";
import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import Waiter from "utils/Waiter";

// import { createStyles, makeStyles } from "@material-ui/styles";

import { RootState } from "../Redux/reducers/_index";
// import AdminUi from './ui.admin';
import LoginUi from "./ui.login";
import Dashboard from "../dashboard/dashboard";
import { useLoadAppTrans } from "utils/useLoadAppTrans";

// export const useStyles = makeStyles((theme: any) =>
//   createStyles({
//     circle: { color: "var(--toolbar-background)" },
//     backdrop: {
//       width: "812px",
//       display: "block",
//       top: 0,
//       bottom: 0,
//       left: 0,
//       right: 0,
//       "@media (min-width: 813px)": {
//         display: "block",
//         top: "25px",
//         bottom: "25px",
//         left: "calc(50% - 406px)",
//         right: "calc(50% - 406px)",
//       },
//       position: "fixed",
//       backgroundColor: "white",
//     },
//     fixedcenter: {
//       position: "fixed",
//       top: "50%",
//       left: "50%",
//       transform: "translate(-50%, -50%)",
//     },
//   })
// );

const BasicUi = () => {
  // const classes = useStyles();

  useLoadAppTrans();

  const backdropOpen = useSelector<RootState, any>(
    (state) => state.backdropOpen
  );

  return (
    <React.Fragment>
      <div id="dimmerlite" className={"mfhidden"}>
        <Waiter text={backdropOpen.text} backdropOpen={backdropOpen.open} />
        <Switch>
          <Route path="/login">
            <LoginUi />
          </Route>
          <Route path="/dashboard/:page">
            <Dashboard />
          </Route>
          <Route path="/dashboard">
            <Redirect to="/dashboard/account" />
          </Route>
          <Route path="/">
            <Redirect to="/dashboard/account" />
          </Route>
        </Switch>
      </div>

      {/*<BaseDialog />
      <FullDialog />*/}
      <AuthCheck />
    </React.Fragment>
  );
};

export default BasicUi;

// <div className={classes.backdrop} />
