import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface StatsType {
  done?: any;
  repeat?: any;
  all: any;
  today?: any;
}

const statsSlice = createSlice({
  initialState: {} as StatsType,
  name: "wordbar",
  reducers: {
    setStats: (state: StatsType, action: PayloadAction<StatsType>) => {
      return action.payload;
    },
  },
});

export const { setStats } = statsSlice.actions;

export default statsSlice.reducer;
