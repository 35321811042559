import { signOut } from "firebaseUtil/firebaseUtil";
import React, { useEffect, useState } from "react";
import { useLoadAppTrans } from "utils/useLoadAppTrans";
import { countries } from "../../account/account.flags";

// @material-ui/core components
import { createStyles, makeStyles } from "@material-ui/styles";

import {
  phaseBodyIn,
  phaseIn,
  hideOut,
  hideIn,
  phaseOut,
} from "../../utils/utils";
import {
  Checkbox,
  Button,
  Message,
  Input,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { useHistory } from "react-router";
import { tx } from "../../utils/globalize";
import { toast } from "react-semantic-toasts";
import { functions } from "../../firebaseUtil/firebaseUtil";
import { languagesList } from "../../utils/languages";
import SignUpWarn from "./signup.warn";
import store from "Redux/store";
import { setAuthLoaded } from "Redux/reducers/reducer.authLoaded";
import firebase from "../../firebaseUtil/firebaseUtil";
import { phaseBodyOut } from "../../utils/utils";
import {
  Step,
  Icon,
  Segment,
  Image,
  Form,
  Dropdown,
  Header,
} from "semantic-ui-react";

var passwordValidator = require("password-validator");

const pwCheck = new passwordValidator()
  .is()
  .min(6)
  .has()
  .letters()
  .has()
  .digits();

const useStyles = makeStyles((theme: any) =>
  createStyles({
    pic: { width: "60%", "@media (min-width: 600px)": { width: "40%" } },
  })
);

const tabs = ["xwho", "xdata", "xlast"];

const Signup = (props: any) => {
  const [tab, setTab] = useState(0);
  const [you, setYou] = useState(-1);
  const [from, setFrom] = useState(-1);
  const [many, setMany] = useState(-1);
  const [pwVisible1, setPwVisible1] = useState(false);
  const [pwVisible2, setPwVisible2] = useState(false);
  const [completed1, setCompleted1] = useState(false);
  const [completed2, setCompleted2] = useState(false);
  const [working, setWorking] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [terms, setTerms] = useState(false);
  const [okayNL, setOkayNL] = useState(false);

  const history = useHistory();

  useLoadAppTrans();
  const [account, setAccount] = useState<any>({});

  useEffect(() => {
    signOut();
  }, []);

  useEffect(() => {
    const lang = localStorage.getItem("language");

    if (lang) {
      setAccount({ language: lang });
    }
  }, []);

  useEffect(() => {
    switch (tab) {
      case 0:
        hideOut("xdata");
        hideOut("xlast");
        break;
      case 1:
        hideOut("xwho");
        hideOut("xlast");
        break;
      case 2:
        hideOut("xwho");
        hideOut("xdata");
        break;
    }

    hideIn(tabs[tab]);
  }, [tab]);

  const classes = useStyles();

  useEffect(() => {
    phaseIn();
    phaseBodyIn();
  }, []);

  const selectCountry = (e: any, data: any) => {
    setAccount({ ...account, [data.name]: data.value });
  };

  const changeLanguage = (e: any, data: any) => {
    setAccount({ ...account, [data.name]: data.value });
  };

  const changeField = (e: any) => {
    setAccount({ ...account, [e.currentTarget.id]: e.currentTarget.value });
  };

  const changeIsCompany = (e: any, data: any) => {
    setAccount({ ...account, [e.target.id]: e.target.checked });
  };

  const goLogin = () => {
    phaseOut();
    setTimeout(() => history.push("/login/login"), 150);
  };

  const checkTrans = () => {
    if (you === -1 || from === -1 || many === -1) {
      toast({
        title: tx("xkoweowe", "More info needed..."),
        description: tx("08908asd0a8sdf1", "Please answer all questions"),
        type: "warning",
        time: 3000,
      });
      return;
    }

    if (verifyForm(account)) {
      toast({
        title: tx("xkoweowe", "More info needed..."),
        description: tx(
          "08908asd0a8sdf2",
          "Please fill out all mandatory fields."
        ),
        type: "warning",
        time: 3000,
      });
      return;
    }

    if (!verifyEmail(account?.email)) {
      toast({
        title: tx("xkoweowe", "More info needed..."),
        description: tx(
          "08908asd0a8sdf3a",
          "Please enter a valid email address."
        ),
        type: "warning",
        time: 3000,
      });
      return;
    }

    if (!account?.language) {
      toast({
        title: tx("xkoweowe", "More info needed..."),
        description: tx("08908asd0a8sdf3x", "You need to select a language"),
        type: "warning",
        time: 3000,
      });
      return;
    }

    // if (!account?.appName) {
    //   toast({
    //     title: "More info needed...",
    //     description: tx(
    //       "08908asd0a8sdf3x",
    //       "You need to give a name to your app"
    //     ),
    //     type: "warning",
    //     time: 3000,
    //   });
    //   return;
    // }

    if (account?.password !== account?.password1) {
      toast({
        title: tx("xkoweowe", "More info needed..."),
        description: tx("08908asd0a8sdf3", "The two passwords do not match."),
        type: "warning",
        time: 3000,
      });
      return;
    }

    if (!terms) {
      toast({
        title: tx("xkoweowe", "More info needed..."),
        description: tx(
          "08908asd0wewea8sdf3",
          "Please accept the Terms and the Privacy Policy."
        ),
        type: "warning",
        time: 3000,
      });
      return;
    }

    if (!pwCheck.validate(account?.password)) {
      const valid = pwCheck.validate(account?.password, { list: true });

      if ((account?.password || "").length < 6) {
        toast({
          title: tx("xkoweowe", "More info needed..."),
          description: tx(
            "08908asd0a8sdf4",
            "Passwords must be at least 6 characters long"
          ),
          type: "warning",
          time: 3000,
        });
        return;
      }

      if (valid.includes("digits")) {
        toast({
          title: tx("xkoweowe", "More info needed..."),
          description: tx(
            "08908asd0a8sdf5",
            "The password must have at least one digit"
          ),
          type: "warning",
          time: 3000,
        });
        return;
      }

      if (valid.includes("letters")) {
        toast({
          title: tx("xkoweowe", "More info needed..."),
          description: tx(
            "08908asd0a8sdf5",
            "The password must have at least one letter"
          ),
          type: "warning",
          time: 3000,
        });
        return;
      }

      return;
    }
    return true;
  };

  const changeTab = (tab: number) => {
    setCompleted1(you > -1 && from > -1 && many > -1);
    setCompleted2(verifyForm(account));
    if (checkTrans()) {
      setTab(tab);
    }
  };

  const signUp = async () => {
    if (!checkTrans()) {
      return;
    }
    setWorking(true);
    const createUser = functions.httpsCallable("createNetAdminUser");

    let gresult: any;

    try {
      gresult = await createUser({
        ...account,
        okayNL: okayNL,
        preferences: { many, from, you },
      });
    } catch (error) {
      console.log(error);
      setWorking(false);
      alert(error);
      return;
    }

    if (gresult.data.error) {
      setWorking(false);
      setOpenWarning(true);
      return;
    }

    setWorking(false);

    store.dispatch(setAuthLoaded(false));

    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(function () {
        firebase
          .auth()
          .signInWithEmailAndPassword(account.email, account.password)
          .then(() => {
            phaseBodyOut();
            setTimeout(() => history.push("/dashboard"), 150);
          })
          .catch(function (error: any) {
            console.log("problem logging in");
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log("problem setting persistence");
        console.log(error);
      });
  };

  return (
    <React.Fragment>
      <Segment>
        <Image src="/images/netlogow.png" className={classes.pic} />
      </Segment>

      <Segment inverted style={{ background: "#339999" }}>
        <Header size="small">
          {tx("a12345n01", "Sign Up as a partner")}
          <div
            onClick={goLogin}
            style={{
              float: "right",
              paddingRight: "5px",
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            {tx("a12345n010", "Login")}
          </div>
        </Header>
      </Segment>

      {/* <Step.Group attached="top" stackable="tablet">
        <Step
          active={tab === 0}
          onClick={() => changeTab(0)}
          completed={completed1}
        >
          <Icon name="question" />
          <Step.Content>
            <Step.Title>{tx("a12345n011", "Who are you?")}</Step.Title>
            <Step.Description>
              {tx("a12345n012", "Describe your status")}
            </Step.Description>
          </Step.Content>
        </Step>

        <Step
          active={tab === 1}
          onClick={() => changeTab(1)}
          id="step2"
          completed={completed2}
        >
          <Icon name="payment" />
          <Step.Content>
            <Step.Title>{tx("a12345n012", "Personal Data")}</Step.Title>
            <Step.Description>
              {tx("a12345n03", "Enter personal data")}
            </Step.Description>
          </Step.Content>
        </Step>

        <Step active={tab === 2} onClick={() => changeTab(2)} id="step3">
          <Icon name="info" />
          <Step.Content>
            <Step.Title>{tx("a12345n04", "Finalize account")}</Step.Title>
            <Step.Description>
              {tx("a12345n05", "Set password")}
            </Step.Description>
          </Step.Content>
        </Step>
      </Step.Group> */}

      <Segment attached>
        <Form
          size="small"
          style={{
            position: "relative",
            flexDirection: "column",
            height: "calc(100% - 55px)",
          }}
        >
          <Form.Group widths="equal">
            <Form.Field required>
              <label>{tx("a12345n026", "First Name")}</label>
              <input
                id="firstName"
                type="text"
                value={account?.firstName || ""}
                onChange={changeField}
              />
            </Form.Field>
            <Form.Field required>
              <label>{tx("a12345n027", "Last Name")}</label>
              <input
                id="lastName"
                type="text"
                value={account?.lastName || ""}
                onChange={changeField}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field required>
              <label>{tx("a12345n028", "Country")}</label>
              <Dropdown
                placeholder="Country"
                search
                selection
                options={countries}
                onChange={selectCountry}
                name={"country"}
                value={account?.country || ""}
              />
            </Form.Field>
            <Form.Field required>
              <label>{tx("a12345n029", "City")}</label>
              <input
                id="city"
                type="text"
                required
                value={account?.city || ""}
                onChange={changeField}
              />
            </Form.Field>
          </Form.Group>
          {/* <Form.Group widths="equal">
            <Form.Field error={!account?.address} required>
              <label>Address</label>
              <input
                id="address"
                type="text"
                value={account?.address || ""}
                onChange={changeField}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Checkbox
              checked={account.isCompany || false}
              id="isCompany"
              label="This account belongs to a company"
              onChange={changeIsCompany}
            />
          </Form.Group>
          <Segment disabled={!account.isCompany}>
            <Form.Group widths="equal">
              <Form.Field
                error={account?.isCompany && !account?.companyName}
                required
              >
                <label>Company Name:</label>
                <input
                  id="companyName"
                  type="text"
                  value={account?.companyName || ""}
                  onChange={changeField}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                error={account?.isCompany && !account?.companyCity}
                required
              >
                <label>City:</label>
                <input
                  id="companyCity"
                  type="text"
                  value={account?.companyCity || ""}
                  onChange={changeField}
                />
              </Form.Field>
              <Form.Field
                error={account?.isCompany && !account?.taxNumber}
                required
              >
                <label>Tax number:</label>
                <input
                  id="taxNumber"
                  type="text"
                  value={account?.taxNumber || ""}
                  onChange={changeField}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                error={account?.isCompany && !account?.companyCountry}
                required
              >
                <label>Country</label>

                <Dropdown
                  placeholder="Country"
                  search
                  selection
                  options={countries}
                  onChange={selectCountry}
                  name={"companyCountry"}
                  value={account?.companyCountry || ""}
                />
              </Form.Field>
              <Form.Field
                error={account?.isCompany && !account?.companyZip}
                required
              >
                <label>Zip</label>
                <input
                  id="companyZip"
                  type="text"
                  value={account?.companyZip || ""}
                  onChange={changeField}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                error={account?.isCompany && !account?.companyAddress}
                required
              >
                <label>Address</label>
                <input
                  id="companyAddress"
                  type="text"
                  value={account?.companyAddress || ""}
                  onChange={changeField}
                />
              </Form.Field>
            </Form.Group> */}
        </Form>
        <Form
          size="small"
          style={{
            position: "relative",
            flexDirection: "column",
            height: "calc(100% - 55px)",
          }}
        >
          <Form.Field required>
            <label>
              {tx("a12345n044", "App Language (Can't be changed later)")}
            </label>
            <Dropdown
              placeholder="Language"
              search
              selection
              options={languagesList}
              onChange={changeLanguage}
              name={"language"}
              value={account?.language || ""}
            />
          </Form.Field>
          {/* <Form.Field error={!account?.appName} required>
            <label>{tx("a12345n045", "Name of your app")}</label>
            <Input
              type={"text"}
              fluid
              value={account?.appName || ""}
              id="appName"
              onChange={changeField}
            />
          </Form.Field> */}
          <Form.Group widths="equal">
            <Form.Field required>
              <label>{tx("a12345n046", "E-mail")}</label>
              <Input
                type={"email"}
                fluid
                value={account?.email || ""}
                id="email"
                onChange={changeField}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field required>
              <label>{tx("a12345n049", "Password")}</label>
              <Input
                icon={{
                  name: pwVisible1 ? "eye" : "eye slash",
                  onClick: () => setPwVisible1(!pwVisible1),
                  link: true,
                }}
                type={pwVisible1 ? "text" : "password"}
                fluid
                value={account?.password || ""}
                id="password"
                onChange={changeField}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field required>
              <label>{tx("a12345n050", "Confirm Password")}</label>
              <Input
                icon={{
                  name: pwVisible2 ? "eye" : "eye slash",
                  onClick: () => setPwVisible2(!pwVisible2),
                  link: true,
                }}
                type={pwVisible2 ? "text" : "password"}
                fluid
                value={account?.password1 || ""}
                id="password1"
                onChange={changeField}
              />
            </Form.Field>
          </Form.Group>
        </Form>
        <Header size="medium" style={{ marginTop: "10px" }}>
          {tx("a12345n06", "Which one describes you best?")}
        </Header>

        <Segment vertical>
          <Form.Field>
            <Checkbox
              radio
              label={tx("a12345n07", "I'm a private English teacher")}
              name="checkboxRadioGroup"
              value="this"
              checked={you === 0}
              onChange={() => setYou(0)}
            />
          </Form.Field>
        </Segment>
        <Segment vertical>
          <Form.Field>
            <Checkbox
              radio
              label={tx("a12345n08", "I teach in a language school")}
              name="checkboxRadioGroup"
              value="this"
              checked={you === 1}
              onChange={() => setYou(1)}
            />
          </Form.Field>
        </Segment>
        <Segment vertical>
          <Form.Field>
            <Checkbox
              radio
              label={tx("a12345n09af", "I represent a language school")}
              name="checkboxRadioGroup"
              value="this"
              checked={you === 2}
              onChange={() => setYou(2)}
            />
          </Form.Field>
        </Segment>
        <Segment vertical>
          <Form.Field>
            <Checkbox
              radio
              label={tx("a12345n010werwr", "I run online English courses")}
              name="checkboxRadioGroup"
              value="this"
              checked={you === 3}
              onChange={() => setYou(3)}
            />
          </Form.Field>
        </Segment>
        <Segment vertical>
          <Form.Field>
            <Checkbox
              radio
              label={tx("a12345n011cwewe", "I sell English books")}
              name="checkboxRadioGroup"
              value="this"
              checked={you === 4}
              onChange={() => setYou(4)}
            />
          </Form.Field>
        </Segment>
        <Header size="medium" style={{ marginTop: "30px" }}>
          {tx("a12345n012dcwe", "How did you find out about WizzWords?")}
        </Header>

        <Segment vertical>
          <Form.Field>
            <Checkbox
              radio
              label={tx("a12345n013", "Form a friend")}
              name="checkboxRadioGroup"
              value="this"
              checked={from === 0}
              onChange={() => setFrom(0)}
            />
          </Form.Field>
        </Segment>
        <Segment vertical>
          <Form.Field>
            <Checkbox
              radio
              label={tx("a12345n014", "On social media")}
              name="checkboxRadioGroup"
              value="this"
              checked={from === 1}
              onChange={() => setFrom(1)}
            />
          </Form.Field>
        </Segment>
        <Segment vertical>
          <Form.Field>
            <Checkbox
              radio
              label={tx("a12345n015", "E-mail")}
              name="checkboxRadioGroup"
              value="this"
              checked={from === 2}
              onChange={() => setFrom(2)}
            />
          </Form.Field>
        </Segment>
        <Segment vertical>
          <Form.Field>
            <Checkbox
              radio
              label={tx("a12345n016", "Internet search")}
              name="checkboxRadioGroup"
              value="this"
              checked={from === 3}
              onChange={() => setFrom(3)}
            />
          </Form.Field>
        </Segment>
        <Segment vertical>
          <Form.Field>
            <Checkbox
              radio
              label={tx("a12345n017", "Other")}
              name="checkboxRadioGroup"
              value="this"
              checked={from === 4}
              onChange={() => setFrom(4)}
            />
          </Form.Field>
        </Segment>

        <Header size="medium" style={{ marginTop: "30px" }}>
          {tx("a12345n018", "How many students do you think you will reach?")}
        </Header>

        <Segment vertical>
          <Form.Field>
            <Checkbox
              radio
              label={tx("a12345n019", "1 to 5")}
              name="checkboxRadioGroup"
              value="this"
              checked={many === 0}
              onChange={() => setMany(0)}
            />
          </Form.Field>
        </Segment>
        <Segment vertical>
          <Form.Field>
            <Checkbox
              radio
              label={tx("a12345n020", "6 to 10")}
              name="checkboxRadioGroup"
              value="this"
              checked={many === 1}
              onChange={() => setMany(1)}
            />
          </Form.Field>
        </Segment>
        <Segment vertical>
          <Form.Field>
            <Checkbox
              radio
              label={tx("a12345n021", "11 - 30")}
              name="checkboxRadioGroup"
              value="this"
              checked={many === 2}
              onChange={() => setMany(2)}
            />
          </Form.Field>
        </Segment>
        <Segment vertical>
          <Form.Field>
            <Checkbox
              radio
              label={tx("a12345n022", "31 - 100")}
              name="checkboxRadioGroup"
              value="this"
              checked={many === 3}
              onChange={() => setMany(3)}
            />
          </Form.Field>
        </Segment>
        <Segment vertical>
          <Form.Field>
            <Checkbox
              radio
              label={tx("a12345n023", "more than 100")}
              name="checkboxRadioGroup"
              value="this"
              checked={many === 4}
              onChange={() => setMany(4)}
            />
          </Form.Field>
        </Segment>
        <Segment vertical>
          <Form.Field>
            <Checkbox
              radio
              label={tx("a12345n024", "more than 1000")}
              name="checkboxRadioGroup"
              value="this"
              checked={many === 5}
              onChange={() => setMany(5)}
            />
          </Form.Field>
        </Segment>
        <Segment vertical>
          <Form.Field style={{ paddingBottom: "15px" }}>
            <Checkbox
              label={tx(
                "a12ewe345n024",
                "I have read and accept the Terms and Conditions and the Privacy Policy of NetEnglish Solutions OÜ Hungarian PE available through the footer."
              )}
              name="checkboxRadioGroup"
              checked={terms}
              onChange={() => setTerms(!terms)}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              label={tx(
                "a1332ewe345nwe024",
                "You can send me emails concerning the WizzWords partnership program."
              )}
              name="checkboxRadioGroup"
              checked={okayNL}
              onChange={() => setOkayNL(!okayNL)}
            />
          </Form.Field>
        </Segment>

        <div style={{ width: "100%", textAlign: "right", paddingTop: "10px" }}>
          <Button color="teal" onClick={signUp}>
            Sign Up
          </Button>
        </div>
      </Segment>

      {/* <Segment attached id="xdata">
        <div style={{ width: "100%", textAlign: "right", paddingTop: "10px" }}>
          <Button
            color="teal"
            onClick={() => {
              changeTab(2);
              document
                .getElementById("step3")
                ?.scrollIntoView({ behavior: "smooth", block: "start" });
            }}
          >
            {tx("a12345n040", "Next")}
          </Button>
        </div>
      </Segment> */}
      {/* <Segment attached id="xlast">
        <Message info>
          <Message.Header>
            {tx("a12345n041", "We are almost there")}
          </Message.Header>
          <p>
            {tx(
              "a12345n042",
              "Just a few last things before you get your account."
            )}{" "}
            {tx(
              "a12345n043",
              "These will be your username and password for login."
            )}
          </p>
        </Message>

        <div style={{ width: "100%", textAlign: "right", paddingTop: "10px" }}>
          <Button color="teal" onClick={signUp}>
            {tx("a12345n053", "Sign Up")}
          </Button>
        </div>
      </Segment> */}
      <Dimmer active={working} page>
        <Loader size="medium" />
      </Dimmer>
      <SignUpWarn open={openWarning} setOpen={setOpenWarning} />
    </React.Fragment>
  );
};

export default Signup;

const verifyEmail = (value: any) => {
  var emailRex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
};

const verifyForm = (a: any) => {
  console.log(a);
  const privateWrong = !a.firstName || !a.lastName || !a.country || !a.city;

  return privateWrong;
};
