import React, { useRef } from "react";
import { Modal, Form, Button, Transition } from "semantic-ui-react";

export default function NewLessonDialog(props: any) {
  const inputRefName = useRef<HTMLInputElement>(null);
  const inputRefId = useRef<HTMLInputElement>(null);

  return (
    <Transition
      unmountOnHide={true}
      animation="fade"
      visible={props.newLessonDlgOpen}
      duration={250}
    >
      <Modal size={"small"} open={true} onClose={() => props.setOpen(false)}>
        <Modal.Header>New Lesson</Modal.Header>

        <Modal.Content>
          Provide name and ID for the new lesson.
          <Form>
            <Form.Field required>
              <label>Category Title</label>
              <input ref={inputRefId} name="Lesson ID" placeholder="ID..." />
            </Form.Field>

            <Form.Field required>
              <label>Lesson Name</label>
              <input ref={inputRefName} name="name" placeholder="name..." />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            negative
            onClick={() => {
              props.setOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            positive
            onClick={() =>
              props.makeNewLesson(
                inputRefName.current?.value || "",
                inputRefId.current?.value || ""
              )
            }
          >
            Create
          </Button>
        </Modal.Actions>
      </Modal>
    </Transition>
  );
}
