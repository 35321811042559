import React from "react";

import { createStyles, makeStyles } from "@material-ui/styles";
import { Dimmer, Segment } from "semantic-ui-react";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    backdrop: {
      zIndex: 1800,
      color: "var(--toolbar-background)",
    },
    flex: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    flexfirst: {
      marginBottom: "8px",
    },
    text: {
      fontFamily: "var(--font-medium)",
      color: "white",
      fontSizes: "18px",
    },
  })
);

const Waiter = (props: any) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Dimmer dimmed={false} className={classes.backdrop} page as={Segment}>
        <div className={classes.flex}>
          <div className={classes.flexfirst}>
            <img
              src="/logo192.png"
              alt="Waiting..."
              width="100px"
              height="100px"
            />
          </div>
          <div className={classes.text}>{props.text}</div>
        </div>
      </Dimmer>
    </React.Fragment>
  );
};

export default Waiter;
