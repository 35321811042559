import React, { useCallback, useEffect, useRef, useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";

import { createStyles, makeStyles } from "@material-ui/styles";
import { firestore } from "../firebaseUtil/firebaseUtil";
import { useSelector } from "react-redux";
import { RootState } from "../Redux/reducers/_index";
import { Modal, Transition, Button, Form, Input } from "semantic-ui-react";

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    horiz: { display: "flex", width: "100%", alignItems: "center" },
    horizLeft: { flex: "1 1 auto" },
    horizRight: { flex: "0 0 80px" },
    cont: { width: "1000px", maxWidth: "1000px" },
    sentences: {
      width: "100%",
      height: "150px",
      maxHeight: "350px !important",
    },
    line: { display: "flex", width: "100%" },
    item: { width: "100%" },
    wrapper: { display: "flex", width: "100%" },
    wordlist: {
      flex: "0 0 320px",
      border: "1px solid lightgrey",
    },
    word: { flex: "1 1 auto", paddingLeft: "20px" },
    text: { width: "100%", padding: "5px" },
    textActive: { background: "lightgrey", width: "100%", padding: "5px" },
  })
);

export class Row extends React.PureComponent<any> {
  render() {
    const data = this.props.data;

    return (
      <div
        style={this.props.style}
        className={
          data.id === data.dic[this.props.index].id
            ? data.classes.textActive
            : data.classes.text
        }
        onClick={() => data.loadId(data.dic[this.props.index].id)}
      >
        {data.dic[this.props.index].word}
      </div>
    );
  }
}

const FormDialog = (props: any) => {
  const [my, setMy] = useState<any>({});
  const listRef = useRef<any>(null);

  const classes = useStyles();

  const currentUser = useSelector<RootState, any>((state) => state.currentUser);

  const { dic, id, setId, open, setOpen } = props;

  const loadId = useCallback(
    async (id: string) => {
      let item: any;
      if (!id) {
        return;
      }
      try {
        item = await firestore.doc("/vocab5/" + id).get();
      } catch (e) {
        console.log(e);
      }

      if (item.exists) {
        setMy(item.data());
        setId(id);
        listRef.current.scrollToItem(
          dic.findIndex((item: any) => item.id === id)
        );
      } else {
      }
    },
    [dic, setId]
  );

  useEffect(() => {
    if (currentUser && id) {
      loadId(id);
    }
  }, [currentUser, id, loadId]);

  const handleClose = () => {
    props.setOpen(false);
  };

  const myChange = (e: any) => {
    setMy({ ...my, [e.currentTarget.id]: e.currentTarget.value });
  };

  const myChangeSentences = (e: any) => {
    setMy({ ...my, s: e.currentTarget.value.split("\n") });
  };

  // const getNewText = async (id: string) => {
  //   let item: any;

  //   try {
  //     item = await firestore.doc("/texts/" + id).get();
  //   } catch (e) {
  //     console.log(e);
  //   }

  //   if (item.exists) {
  //     setNewText(item.data().text);
  //     setNewTitle(item.data().title);
  //   } else {
  //     alert("not found");
  //   }
  // };

  const setWordPart = (wp: string) => {
    let temp: string = "";
    switch (wp) {
      case "v":
        temp = my.word;
        if (temp.slice(-1) === "e") {
          temp = temp + "/" + temp + "d/" + temp + "d/" + temp + "s+v.";
        } else {
          temp = temp + "/" + temp + "ed/" + temp + "ed/" + temp + "s+v.";
        }
        break;
      case "v1":
        temp = my.word;
        temp = temp + "+v.";

        break;
      case "n":
        temp = my.word;
        if (temp.slice(-1) === "s") {
          temp = temp + "/" + temp + "es+n.";
        } else {
          temp = temp + "/" + temp + "s+n.";
        }
        break;
      default:
        temp = my.word;
        temp = temp + "+" + wp + ".";
    }

    setMy({ ...my, word: temp });
  };

  const changeMy = () => {
    firestore.doc("/vocab5/" + my.id).update(my);
  };

  // const changeText = () => {
  //   firestore.doc("/texts/" + textId).update({ text: newText });
  // };

  // const textIdChange = (e: any) => {
  //   setTextId(e.currentTarget.value);
  // };

  const deleteMy = () => {
    firestore.doc("/vocab5/" + my.id).delete();
  };

  return (
    <Transition unmountOnHide visible={open} animation="fade" duration={250}>
      <Modal centered={false} open={true} onClose={() => setOpen(false)}>
        <Modal.Header>Edit Word</Modal.Header>
        <Modal.Content>
          <div className={classes.wrapper}>
            <div className={classes.wordlist}>
              <AutoSizer>
                {({ height, width }: any) => (
                  <List
                    ref={listRef}
                    className="List"
                    height={height}
                    itemCount={dic?.length || 0}
                    itemSize={30}
                    width={width}
                    itemData={{
                      dic: dic || [],
                      classes,
                      id,
                      loadId,
                    }}
                  >
                    {Row as any}
                  </List>
                )}
              </AutoSizer>
            </div>

            <div className={classes.word}>
              <Form>
                <div className={classes.horiz}>
                  <div className={classes.horizLeft}>
                    <Form.Field
                      control={Input}
                      label="Word ID"
                      type="text"
                      value={id}
                      onChange={(e: any) => setId(e.currentTarget.value)}
                    />
                  </div>
                </div>

                <hr />
                <Form.Group widths="equal">
                  <Form.Field
                    control={Input}
                    label="English word"
                    type="text"
                    id="word"
                    value={my.word || ""}
                    onChange={myChange}
                  />

                  <Form.Field
                    control={Input}
                    label="Hungarian"
                    id="d"
                    type="text"
                    value={my.d || ""}
                    onChange={myChange}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field
                    control={Input}
                    label="Pronunciation"
                    type="text"
                    id="pron"
                    value={my.pron || ""}
                    onChange={myChange}
                  />
                  <Form.Field
                    control={Input}
                    label="Source"
                    type="text"
                    id="source"
                    value={my.source || ""}
                    onChange={myChange}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field
                    control={Input}
                    label="Head"
                    type="text"
                    id="head"
                    value={my.head || ""}
                    onChange={myChange}
                  />

                  <Form.Field
                    control={Input}
                    label="Short"
                    type="text"
                    id="short"
                    value={my.short || ""}
                    onChange={myChange}
                  />
                </Form.Group>
                <div style={{ marginBottom: "5px" }}>
                  <Button onClick={() => setWordPart("v1")}>v. simple</Button>
                  <Button onClick={() => setWordPart("v")}>v.</Button>
                  <Button onClick={() => setWordPart("n")}>n.</Button>
                  <Button onClick={() => setWordPart("adj")}>adj.</Button>
                  <Button onClick={() => setWordPart("adv")}>adv.</Button>
                  <Button onClick={() => setWordPart("prep")}>prep.</Button>
                  <Button onClick={() => setWordPart("conj")}>conj.</Button>
                </div>
                <div>
                  <textarea
                    className={classes.sentences}
                    id="cards"
                    value={(
                      my.cards?.map((item: any) => item.english) || []
                    ).join("\n")}
                    onChange={myChangeSentences}
                  />
                </div>
              </Form>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={deleteMy}>Delete</Button>
          <Button onClick={changeMy}>Save</Button>
        </Modal.Actions>
      </Modal>
    </Transition>
  );
};

export default FormDialog;
