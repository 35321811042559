import { firestore } from "firebaseUtil/firebaseUtil";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "Redux/reducers/_index";
import { UserType } from "Redux/reducers/reducer.currentUser";
import { phaseBodyIn, phaseIn } from "utils/utils";
import InfiniteScroll from "react-infinite-scroll-component";

// react component for creating dynamic tables
// @material-ui/core components

// import NewUserForm from "./userEditor.newUserForm.tsxa";
import { makeStyles } from "@material-ui/styles";
import NewUserForm from "./userEditor.newUserForm";
import NewUserAdd from "./userEditor.newUserAdd";
import { Grid, Input, Button, Icon, Segment } from "semantic-ui-react";
import firebase from "firebase/app";

const styles = {
  cardIconTitle: {
    paddingTop: "0px",
    marginBottom: "0px",
    width: "100%",
    marginTop: "0px",
  },
  card: {
    width: "100%",
    marginTop: "0px",
  },
  mainCard: {
    position: "absolute",
    top: 20,
    bottom: 20,
    left: 0,
    right: 0,
    maxWidth: "1400px",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "25px",
    background: "white",
    borderRadius: "5px",
  },
  container: {
    paddingTop: "5px",
    borderBottom: "1px solid lightblue",
    alignItems: "center",
    height: "100%",
    overflow: "auto",
    top: 34,
    bottom: 0,
    display: "block !important",
    paddingBottom: "1.3rem !important",
  },
  containerHeader: {
    paddingTop: "5px",
    paddingBottom: "5px",
    borderBottom: "1px solid grey",
    fontWeight: 700,
    paddingRight: "6px",
    background: "antiquewhite",
  },
  button: {
    backgroundColor: "var(--toolbar-background)",
    color: "var(--toolbar-color)",
  },
  modal: {
    marginTop: "0px",
    overflow: "visible",
  },
  adminHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  headerIcon: {
    width: "50px",
  },
  headerText: {
    flex: "1 1 auto",
  },
  topSegment: {
    marginBottom: "0px !important",
    borderBottom: "0px solid white !important",
    paddingTop: "8px !important",
    paddingBottom: "8px !important",
    borderBottomLeftRadius: "0px !important",
    borderBottomRightRadius: "0px !important",
  },
  bottom: {
    marginTop: "0px !important",
    boxShadow: "none !important",
    height: "100%",
  },
  rowMine: {
    "&:first-child": { marginTop: "inherit !important" },
    marginTop: "0.2rem !important",
    marginBottom: "0.2rem !important",
    paddingTop: "0rem !important",
    paddingBottom: "0rem !important",
  },
  rowMineLoading: {
    "&:first-child": { marginTop: "inherit !important" },
    marginTop: "0.2rem !important",
    marginBottom: "0.2rem !important",
    paddingTop: "11px !important",
    paddingBottom: "5px !important",
    paddingLeft: "1rem !important",
  },
};

const useStyles = makeStyles(styles as any);
let ind = 0;

const UserEditor = (props: any) => {
  const [items, setItems] = useState<
    firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[]
  >([]);
  const [lastVisible, setLastVisible] = useState<
    firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
  >();
  // const [count, setCount] = useState(0);
  const [userDlgOpen, setUserDlgOpen] = useState(false);
  const [user, setUser] = useState<any>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [startNew, setStartNew] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const currentUser = useSelector<RootState, UserType>(
    (state) => state.currentUser
  );

  const ccodeGlobal = useSelector<RootState, string>(
    (state) => state.ccodeGlobal
  );

  useEffect(() => {
    setTimeout(() => {
      if (currentUser) {
        phaseBodyIn();
        phaseIn();
      }
    }, 0);
  }, [currentUser]);

  useEffect(() => {
    if (currentUser?.type === "admisdn") {
      firestore
        .collection("users")
        .where("email", ">=", searchTerm)
        .where("email", "<=", searchTerm + "zzzzzzzz")
        .orderBy("email")
        .limit(25)
        .get()
        .then((documentSnapshots) => {
          setItems(documentSnapshots.docs);
          setLastVisible(
            documentSnapshots.docs[documentSnapshots.docs.length - 1]
          );
          if (documentSnapshots.docs.length) {
            setHasMore(true);
          } else {
            setHasMore(false);
          }
          // setCount(documentSnapshots.docs.length);
        });
    } else {
      firestore
        .collection("users")
        .where("email", ">=", searchTerm)
        .where("email", "<=", searchTerm + "zzzzzzzz")
        .where("ccode", "==", ccodeGlobal || "")
        .orderBy("email")
        .limit(25)
        .get()
        .then((documentSnapshots) => {
          setItems(documentSnapshots.docs);
          setLastVisible(
            documentSnapshots.docs[documentSnapshots.docs.length - 1]
          );
          if (documentSnapshots.docs.length) {
            setHasMore(true);
          } else {
            setHasMore(false);
          }
          // setCount(documentSnapshots.docs.length);
        });
    }
  }, [startNew, searchTerm, currentUser, ccodeGlobal]);

  const fetchNext = () => {
    if (lastVisible)
      if (currentUser?.type === "admin") {
        firestore
          .collection("users")
          .where("ccode", "==", ccodeGlobal || "")
          .orderBy("email")
          .startAfter(lastVisible)
          .limit(25)
          .get()
          .then((documentSnapshots) => {
            setItems([...items, ...documentSnapshots.docs]);
            setLastVisible(
              documentSnapshots.docs[documentSnapshots.docs.length - 1]
            );
            if (documentSnapshots.docs.length) {
              setHasMore(true);
            } else {
              setHasMore(false);
            }
            // setCount(items.length + documentSnapshots.docs.length);
          });
      } else {
        if (lastVisible)
          if (currentUser?.type === "admin") {
            firestore
              .collection("users")
              .orderBy("email")
              .startAfter(lastVisible)
              .limit(25)
              .get()
              .then((documentSnapshots) => {
                setItems([...items, ...documentSnapshots.docs]);
                setLastVisible(
                  documentSnapshots.docs[documentSnapshots.docs.length - 1]
                );
                if (documentSnapshots.docs.length) {
                  setHasMore(true);
                } else {
                  setHasMore(false);
                }
                // setCount(items.length + documentSnapshots.docs.length);
              });
          }
      }
  };

  const handleSearchTermChange = (e: any) => {
    setSearchTerm(e.currentTarget.value);
  };

  const doSearch = () => {
    setStartNew(!startNew);
  };

  // const infiniteRef = useInfiniteScroll({
  //   loading,
  //   hasNextPage: Boolean(setLastVisible),
  //   onLoadMore: fetchNext,
  //   scrollContainer: "parent",
  // });

  const classes = useStyles();
  return (
    <React.Fragment>
      <Segment className={classes.topSegment} inverted>
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <div className={classes.adminHeader}>
                <div className={classes.headerIcon}>
                  <Icon name="user" />
                </div>
                <div className={classes.headerText}>
                  <h4 className={classes.cardIconTitle}>Users</h4>
                </div>
                <Input
                  value={searchTerm}
                  onChange={handleSearchTermChange}
                  icon="search"
                  placeholder="Search..."
                />
                &nbsp;
                <Button
                  onClick={() => {
                    setUser(++ind);
                    setUserDlgOpen(true);
                  }}
                  className={classes.button}
                >
                  New user
                </Button>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <Segment attached="top" className={classes.bottom} color="olive" compact>
        <Grid>
          <Grid.Row className={classes.containerHeader}>
            <Grid.Column width={3}>E-mail</Grid.Column>
            <Grid.Column width={4}>Name</Grid.Column>
            <Grid.Column width={2}>Joined</Grid.Column>
            <Grid.Column width={2}>Type</Grid.Column>
            <Grid.Column width={1}>Language</Grid.Column>
          </Grid.Row>
        </Grid>
        <InfiniteScroll
          className={"ui vertically divided grid " + classes.container}
          style={{
            marginTop: "14px",
            position: "absolute",
            left: "17px",
            right: "14px",
            top: "34px",
            marginBottom: "0px",
            bottom: 0,
          }}
          dataLength={items.length}
          next={fetchNext}
          hasMore={hasMore}
          loader={
            <Grid.Row className={classes.rowMineLoading}>Loading...</Grid.Row>
          }
          height="calc(100% - 100px)"
        >
          {items.map((i, index) => {
            const data = i.data();
            return (
              <Grid.Row
                key={index}
                className={classes.rowMine}
                onClick={() => {
                  setTimeout(() => setUserDlgOpen(true), 0);
                  setUser({ ...data, id: i.id });
                }}
              >
                <Grid.Column width={3}>{data.email}</Grid.Column>
                <Grid.Column width={4}>
                  {(data.lastName + ", " + data.firstName)
                    .replace(/^undefined, /g, "")
                    .replace(/, undefined$/g, "")}
                </Grid.Column>
                <Grid.Column width={2}>
                  {new Date(parseInt(data.joined || 0))
                    .toISOString()
                    .slice(2, 10)}
                </Grid.Column>
                <Grid.Column width={2}>{data.type}</Grid.Column>
                <Grid.Column width={4}>{data.language}</Grid.Column>
                <Grid.Column width={1}>
                  <Icon
                    name="edit"
                    link
                    onClick={() => {
                      setTimeout(() => setUserDlgOpen(true), 0);
                      setUser({ ...data, id: i.id });
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
            );
          })}
        </InfiniteScroll>
      </Segment>

      {user?.email ? (
        <NewUserForm
          open={userDlgOpen}
          user={user}
          doSearch={doSearch}
          setOpen={setUserDlgOpen}
        />
      ) : (
        <NewUserAdd
          open={userDlgOpen}
          setOpen={setUserDlgOpen}
          account={props.account}
          setActive={props.setActive}
          doSearch={doSearch}
        />
      )}
    </React.Fragment>
  );
};

export default UserEditor;
