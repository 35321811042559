import React, { useEffect } from "react";
import { makeStyles, createStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    wrapper: {
      marginTop: "0px",
      padding: "25px",
      paddingTop: "0px",
      maxWidth: "900px",
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: "45px",
    },
    text: {
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      marginBottom: "10px",
      lineHeight: "130%",
    },
    mainHeader: {
      fontSize: "26px",
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
      marginTop: "25px",
      marginBottom: "15px",
      lineHeight: 1.3,
    },
    header: {
      fontSize: "24px",
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
      marginTop: "25px",
      marginBottom: "15px",
      lineHeight: 1.3,
    },
  })
);

const Terms = (props: any) => {
  const classes = useStyles();
  const { tt } = props;

  useEffect(() => {
    if (props.subscription) {
      document.getElementById("subscription")?.scrollIntoView();
    }
  }, [props.subscription]);

  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <div className={classes.mainHeader}>
          {tt("t1a", "Terms and conditions")}
        </div>
        <div className={classes.text}>
          {tt(
            "t2aert",
            "These terms and conditions (“Terms”, “Agreement”) are an agreement between NetEnglish Solutions OÜ Hungarian Permanent Establishment (“Service Provider”, “NetEnglish Solutions OÜ”, “us”, “we” or “our”) and you (“User”, “you” or “your”)."
          )}{" "}
          {tt(
            "t3a",
            "This Agreement sets forth the general terms and conditions of your use of the netenglish.com website and any of its products or services (collectively, “Website” or “Services”)."
          )}{" "}
          {tt(
            "t3absdse",
            "The Agreement was formulated in Hungarian and translated to other languages. In case of disputes the provisions of the Hungarian version should prevail."
          )}
        </div>
        <div className={classes.header}>
          {tt("t13awe", "Company and contact data of Service Provider")}
        </div>
        <div className={classes.text}>
          {tt(
            "t214acxw",
            "*Name:* NetEnglish Solutions OÜ Hungarian Permanent Establishment."
          )}
        </div>
        <div className={classes.text}>
          {tt(
            "t214aacxw",
            "*Address:* 9700 Hungary, Szombathely, Deák Ferenc utca 29."
          )}
        </div>
        <div className={classes.text}>
          {tt("t215acx", "*Website:* https://netenglish.com")}
        </div>
        <div className={classes.text}>
          {tt("t216acx", "*Tax Number:* 27972984-2-18")}
        </div>
        <div className={classes.text}>
          {tt("t217acx", "*E-mail:* support@netenglish.com")}
        </div>
        <div className={classes.text}>
          {tt("t218acx", "*Company Registry Number:* 18-17-000025")}
        </div>
        <div className={classes.text}>
          {tt(
            "t219acx",
            "*Hosted by:* Google Cloud Hosting (support@google.com)"
          )}
        </div>
        <div className={classes.header}>
          {tt("t4a", "Accounts and membership")}
        </div>
        <div className={classes.text}>
          {tt(
            "t5a",
            "If you create an account on the Website, you are responsible for maintaining the security of your account and you are fully responsible for all activities that occur under the account and any other actions taken in connection with it."
          )}{" "}
          {tt(
            "t6a",
            "We may, but have no obligation to, monitor and review new accounts before you may sign in and use our Services."
          )}{" "}
          {tt(
            "t7a",
            "Providing false contact information of any kind may result in the termination of your account."
          )}{" "}
          {tt(
            "t8a",
            "You must immediately notify us of any unauthorized uses of your account or any other breaches of security."
          )}{" "}
          {tt(
            "t9a",
            "We will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions."
          )}{" "}
          {tt(
            "t10a",
            "We may suspend, disable, or delete your account (or any part thereof) if we determine that you have violated any provision of this Agreement or that your conduct or content would tend to damage our reputation and goodwill."
          )}{" "}
          {tt(
            "t11a",
            "If we delete your account for the foregoing reasons, you may not re-register for our Services."
          )}{" "}
          {tt(
            "t12a",
            "We may block your email address and Internet protocol address to prevent further registration."
          )}
        </div>
        <div className={classes.header}>
          {tt("t20a", "Billing and payments")}
        </div>
        <div className={classes.text}>
          {tt(
            "t21a",
            "You shall pay all fees or charges to your account in accordance with the fees, charges, and billing terms in effect at the time a fee or charge is due and payable."
          )}
        </div>

        <div className={classes.text}>
          {tt(
            "apayt523fwewewerwercs",
            "Payment on the Website is made by our contracted resellers, Paddle.com Market Limited (https://www.paddle.com/legal/terms) or Apple App Store or Google Play through their own pages."
          )}{" "}
          {tt(
            "apayt523fweweas",
            "We do not receive or store your credit card or payment information."
          )}{" "}
        </div>

        <div className={classes.text}>
          {tt(
            "t21wesb",
            "All prices include VAT as required by law for your country and Hungary."
          )}{" "}
        </div>
        <div className={classes.text}>
          {tt(
            "t21apocsd",
            "Our partners send you an invoice of all your purchases and payments to the specified e-mail address."
          )}{" "}
          {tt(
            "t21apowerwerxxxxxxx",
            "If you provide an incorrect email address and we receive notification about it, we will re-send the invoice to the correct address, but providing a correct email address is the responsibility of the user, and Service Provider takes no responsibility for errors in service or invoicing resulting from incorrect email addresses provided."
          )}
        </div>
        <div className={classes.text}>
          {tt(
            "t24c",
            "We reserve the right to change products and product pricing at any time."
          )}{" "}
          {tt(
            "t25d",
            "We also reserve the right to refuse any order you place with us."
          )}{" "}
          {tt(
            "t26e",
            "In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made."
          )}{" "}
        </div>

        <div className={classes.header}>
          {tt("t20aui", "One-time payments")}
        </div>
        <div className={classes.text}>
          {tt(
            "t523fwerwer",
            "You can buy gift coupons on our website and you can buy unlimited access to the WizzWords application. These are one-time payments."
          )}{" "}
          {tt(
            "t524h",
            "Coupons are only valuable if you or the person you give it to signs up for the application."
          )}{" "}
          {tt(
            "524ixcs",
            "When you buy a coupon you can send it to the person you intend it to, and the person can use the coupon in the sign-up process to receive unlimited access."
          )}{" "}
          {tt("t529k", "One gift coupon can only be used by one person.")}{" "}
          {tt(
            "t530l",
            "You are responsible for keeping the coupon code safe and only give it to the intended user."
          )}{" "}
          {tt(
            "t531m",
            "NetEnglish takes no responsibility in the event your code gets in the wrong hands."
          )}{" "}
        </div>
        <div className={classes.header}>{tt("com1uh21", `Commissions`)}</div>

        <div className={classes.text}>
          {tt(
            "com2uh21",
            `When you buy WizzWords you agree that you are entitled for commissions for properly recommending WizzWords to others.`
          )}{" "}
          {tt(
            "com3uh21",
            `Any activitiy connected to this can be done through the WizzWords Admin Panel available through https://admin.wizzwords.net.`
          )}
        </div>
        <div className={classes.text}>
          {tt(
            "com4uh21",
            `You can sign up for a free account and use the panel.`
          )}{" "}
          {tt(
            "com5uh21",
            `When you log in there, you will receive your personalized link for our sales page you can send out to other people.`
          )}{" "}
          {tt(
            "com6uh21",
            `If they buy WizzWords after clicking on your link, you will be credited with a commission.`
          )}{" "}
          {tt(
            "com7uh21",
            `(The amount of this commission at any time can be found on the https://wizzwords.net/it/reseller page.)`
          )}
        </div>
        <div className={classes.text}>
          {tt(
            "com8uh21",
            `On the same page you can provide your bank account information and you can send us a standard invoice for the sum given under the READY heading on the admin page.`
          )}
        </div>
        <div className={classes.text}>
          {tt(
            "com9uh21",
            `You are specifically entitled to the commission after each sale 14 day after the sale if no refund happened on that sale.`
          )}
        </div>
        <div className={classes.text}>
          {tt(
            "com10uh21",
            `Service Provider has the full authority on deciding whether you are entitled to a commission and there can be no disputes on this.`
          )}{" "}
          {tt(
            "com11uh21",
            `Service Provider however guarantees that the above formula will always be used.`
          )}
        </div>
        <div className={classes.text}>
          {tt(
            "com12uh21",
            `When you sent your invoice to the email address provided in the admin panel, Service Provider will verify your claim (if it is covered by the sum under the Ready heading) and will transfer your the money within three working days to the bank account number you provided.`
          )}
        </div>

        <div id="subscription" className={classes.header}>
          {tt("t20ns", "Description of the Service")}
        </div>
        <div className={classes.text}>
          {tt(
            "t23oaasa",
            "WizzWords is a paid service where users may study English through various exercises provided by Service Provider in the form of a web application."
          )}{" "}
          {tt(
            "t23oaasb",
            "Service Provider reserves the right to grant access to its service free of charge or as a paid service at its descretion."
          )}{" "}
          {tt(
            "t23oaasc",
            "In some areas new users signing up for the first time will be granted a seven day free trial with all functionality of WizzWords available."
          )}{" "}
          {tt(
            "t23oaasd",
            "Continued use of the WizzWords application requires the user to buy a licence to use the service."
          )}{" "}
        </div>
        <div id="subscription" className={classes.header}>
          {tt("t20nsa", "Order Delivery")}
        </div>
        <div className={classes.text}>
          {tt(
            "at23oaasads",
            "Users can access WizzWords buying a licence to use the service."
          )}{" "}
          {tt(
            "at23oaasa",
            "Such licences can be bought from the website of the Service Provider or from any authorized reseller."
          )}
        </div>
        <div id="subscription" className={classes.header}>
          {tt(
            "at23oaasads1",
            "Licences bought on our website for personal use"
          )}
        </div>
        <div className={classes.text}>
          {tt(
            "at23oaasads2",
            "When the user buys a licence from the WizzWords website the user reads and accepts the Terms and Conditions and the Privacy Policy of the Service Provider, submits invoicing data to the Service Provider and provides a password."
          )}{" "}
          {tt(
            "at23oaasads3clko",
            "At this point the user is forwarded to our contracted reseller's webpage where they can enter their payment data."
          )}{" "}
          {tt(
            "at23oaasads4",
            "In case of successfull payment the user can use their credentials to access the WizzWords application."
          )}
        </div>
        <div id="subscription" className={classes.header}>
          {tt("at23oaasads5", "Licences bought on our website for others")}
        </div>
        <div className={classes.text}>
          {tt(
            "at23oaasads6",
            "When the user buys a licence from the WizzWords website for somebody else, the user reads and accepts the Terms and Conditions and the Privacy Policy of the Service Provider, submits invoicing data to the Service Provider and provides a password."
          )}{" "}
          {tt(
            "at23oaasads3clko",
            "At this point the user is forwarded to our contracted reseller's webpage where they can enter their payment data."
          )}{" "}
          {tt(
            "at23oaasads8",
            "In case of successfull payment the user will receive an email with a coupon code for each licence they bought."
          )}{" "}
          {tt(
            "at23oaasads9",
            "These coupons can then be sent to anybody they want to give full access to the WizzWords application."
          )}{" "}
          {tt(
            "at23oaasads10",
            "The buyer must be aware that anybody utilizing the coupons to access the service will have to accept these same Terms and Conditions and Privacy Policies for them to access the Service."
          )}
        </div>

        <div className={classes.header}>{tt("t127u", "Refund policy")}</div>
        <div className={classes.text}>
          {tt(
            "t128v",
            "We're so convinced you'll absolutely love our services, that we're willing to offer a 14 day risk-free money back guarantee."
          )}{" "}
          {tt(
            "t128w",
            "If you are not satisfied with the service for any reason you can ask a refund within 14 days of making a purchase."
          )}{" "}
          {tt(
            "t129d",
            "To request a refund clearly express your wish in an email sent to [*support@netenglish.com*] from the email address you use for your subscription."
          )}{" "}
        </div>
        <div className={classes.text}>
          {tt(
            "t130d",
            "Refunds will be initiated by the Service Provider within 48 hours after receiving your email through standard bank procedures and the sum of your last payment will be credited to your bank account. Length of processing depends on your card issuer or bank."
          )}{" "}
          {tt(
            "t131de",
            "To avoid abuse, the Service Provider maintains the right to refuse selling further licences to those who requested and got a refund."
          )}{" "}
        </div>
        <div className={classes.text}>
          {tt(
            "t131df",
            "In case of gift cards refunds can be initiated before they are used for 14 days after their purchase."
          )}{" "}
          {tt(
            "t131dg",
            "After they were used we need an email requesting refund from the buyer as well as from the person using it for a refund to be initiated."
          )}{" "}
          {tt(
            "t131dh",
            "Such refund requests can also be made within 14 days of the purchase date."
          )}{" "}
          {tt(
            "t131dw",
            "In special cases refund requests not specified as valid here may also be considered but this is wholly at the discretion of the Service Provider."
          )}{" "}
        </div>
        <div className={classes.header}>{tt("t13art", "User content")}</div>
        <div className={classes.text}>
          {tt(
            "t14a",
            "We do not own any data, information or material (“Content”) that you submit on the Website in the course of using the Service."
          )}{" "}
          {tt(
            "t15a",
            "You shall have sole responsibility for the accuracy, quality, integrity, legality, reliability, appropriateness, and intellectual property ownership or right to use of all submitted Content."
          )}{" "}
          {tt(
            "t16a",
            "We may monitor and review Content on the Website submitted or created using our Services by you."
          )}{" "}
          {tt(
            "t17a",
            "You grant us permission to access, copy, distribute, store, transmit, reformat, display and perform the Content of your user account solely as required for the purpose of providing the Services to you."
          )}{" "}
          {tt(
            "t18a",
            "Without limiting any of those representations or warranties, we have the right, though not the obligation, to, in our own sole discretion, refuse or remove any Content that, in our reasonable opinion, violates any of our policies or is in any way harmful or objectionable."
          )}{" "}
          {tt(
            "t19a",
            "Unless specifically permitted by you, your use of the Website does not grant us the license to use, reproduce, adapt, modify, publish or distribute the Content created by you or stored in your user account for commercial, marketing or any similar purpose."
          )}
        </div>
        <div className={classes.header}>
          {tt("t27a", "Accuracy of information")}
        </div>
        <div className={classes.text}>
          {tt(
            "t28a",
            "Occasionally there may be information on the Website that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, availability, promotions and offers."
          )}{" "}
          {tt(
            "t29d",
            "We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information on the Website or on any related Service is inaccurate at any time without prior notice (including after you have submitted your order)."
          )}{" "}
          {tt(
            "t30d",
            "We undertake no obligation to update, amend or clarify information on the Website including, without limitation, pricing information, except as required by law."
          )}{" "}
          {tt(
            "t31d",
            "No specified update or refresh date applied on the Website should be taken to indicate that all information on the Website or on any related Service has been modified or updated."
          )}
        </div>
        <div className={classes.header}>{tt("t32d", "Backups")}</div>
        <div className={classes.text}>
          {tt(
            "t33d",
            "We perform regular backups of the Website and  Content, however, these backups are for our own administrative purposes only and are in no way guaranteed. You are responsible for maintaining your own backups of your data."
          )}{" "}
          {tt(
            "t34d",
            "We do not provide any sort of compensation for lost or incomplete data in the event that backups do not function properly."
          )}{" "}
          {tt(
            "t35d",
            "We will do our best to ensure complete and accurate backups, but assume no responsibility for this duty."
          )}
        </div>
        <div className={classes.header}>
          {tt("t36d", "Links to other websites")}
        </div>
        <div className={classes.text}>
          {tt(
            "t37d",
            "Although this Website may link to other websites, we are not, directly or indirectly, implying any approval, association, sponsorship, endorsement, or affiliation with any linked website, unless specifically stated herein."
          )}{" "}
          {tt(
            "t38d",
            "We are not responsible for examining or evaluating, and we do not warrant the offerings of, any businesses or individuals or the content of their websites."
          )}{" "}
          {tt(
            "t39d",
            "We do not assume any responsibility or liability for the actions, products, services, and content of any other third parties."
          )}{" "}
          {tt(
            "t40d",
            "You should carefully review the legal statements and other conditions of use of any website which you access through a link from this Website."
          )}{" "}
          {tt(
            "t41d",
            "Your linking to any other off-site websites is at your own risk."
          )}
        </div>
        <div className={classes.header}>{tt("t42d", "Prohibited uses")}</div>
        <div className={classes.text}>
          {tt(
            "t43d",
            "In addition to other terms as set forth in the Agreement, you are prohibited from using the Website or its Content:"
          )}{" "}
          {tt(
            "t44d",
            "(a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances;"
          )}{" "}
          {tt(
            "t45d",
            "(d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability;"
          )}{" "}
          {tt(
            "t46d",
            "(f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet;"
          )}{" "}
          {tt(
            "t47d",
            "(h) to spam, phish, pharm, pretext, spider, crawl, or scrape; (i) for any obscene or immoral purpose; or (j) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet."
          )}{" "}
          {tt(
            "t48d",
            "We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses."
          )}
        </div>
        <div className={classes.header}>
          {tt("t49d", "Intellectual property rights")}
        </div>
        <div className={classes.text}>
          {tt(
            "t50d",
            "“Intellectual Property Rights” means all present and future rights conferred by statute, common law or equity in or in relation to any copyright and related rights, trademarks, designs, patents, inventions, goodwill and the right to sue for passing off, rights to inventions, rights to use, and all other intellectual property rights, in each case whether registered or unregistered and including all applications and rights to apply for and be granted, rights to claim priority from, such rights and all similar or equivalent rights or forms of protection and any other results of intellectual activity which subsist or will subsist now or in the future in any part of the world."
          )}{" "}
          {tt(
            "t51d",
            "This Agreement does not transfer to you any intellectual property owned by the Service Provider or third parties, and all rights, titles, and interests in and to such property will remain (as between the parties) solely with the Service Provider."
          )}{" "}
          {tt(
            "t52d",
            "All trademarks, service marks, graphics and logos used in connection with the Website or Services, are trademarks or registered trademarks of the Service Provider or the licensors of the Service Provider."
          )}{" "}
          {tt(
            "t53d",
            "Other trademarks, service marks, graphics and logos used in connection with the Website or Services may be the trademarks of other third parties."
          )}{" "}
          {tt(
            "t54d",
            "Your use of the Website and Services grants you no right or license to reproduce or otherwise use any Service Provider or third party trademarks."
          )}
        </div>
        <div className={classes.header}>
          {tt("t55d", "Disclaimer of warranty")}
        </div>
        <div className={classes.text}>
          {tt(
            "t56d",
            "You agree that such Service is provided on an “as is” and “as available” basis and that your use of our Website or Services is solely at your own risk."
          )}{" "}
          {tt(
            "t57d",
            "We expressly disclaim all warranties of any kind, whether express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose and non-infringement."
          )}{" "}
          {tt(
            "t58d",
            "We make no warranty that the Services will meet your requirements, or that the Service will be uninterrupted, timely, secure, or error-free; nor do we make any warranty as to the results that may be obtained from the use of the Service or as to the accuracy or reliability of any information obtained through the Service or that defects in the Service will be corrected."
          )}{" "}
          {tt(
            "t59d",
            "You understand and agree that any material and/or data downloaded or otherwise obtained through the use of Service is done at your own discretion and risk and that you will be solely responsible for any damage or loss of data that results from the download of such material and/or data."
          )}{" "}
          {tt(
            "t60d",
            "We make no warranty regarding any goods or services purchased or obtained through the Service or any transactions entered into through the Service."
          )}{" "}
          {tt(
            "t61d",
            "No advice or information, whether oral or written, obtained by you from us or through the Service shall create any warranty not expressly made herein."
          )}
        </div>
        <div className={classes.header}>
          {tt("t62d", "Limitation of liability")}
        </div>
        <div className={classes.text}>
          {tt(
            "t63d",
            "To the fullest extent permitted by applicable law, in no event will Service Provider, its affiliates, directors, officers, employees, agents, suppliers or licensors be liable to any person for any indirect, incidental, special, punitive, cover or consequential damages (including, without limitation, damages for lost profits, revenue, sales, goodwill, use of content, impact on business, business interruption, loss of anticipated savings, loss of business opportunity) however caused, under any theory of liability, including, without limitation, contract, tort, warranty, breach of statutory duty, negligence or otherwise, even if the liable party has been advised as to the possibility of such damages or could have foreseen such damages."
          )}{" "}
          {tt(
            "t64d",
            "To the maximum extent permitted by applicable law, the aggregate liability of Service Provider and its affiliates, officers, employees, agents, suppliers and licensors relating to the services will be limited to an amount greater of one dollar or any amounts actually paid in cash by you to Service Provider for the prior one month period prior to the first event or occurrence giving rise to such liability."
          )}{" "}
          {tt(
            "t65d",
            "The limitations and exclusions also apply if this remedy does not fully compensate you for any losses or fails of its essential purpose."
          )}
        </div>
        <div className={classes.header}>{tt("t66d", "Severability")}</div>
        <div className={classes.text}>
          {tt(
            "t67d",
            "All rights and restrictions contained in this Agreement may be exercised and shall be applicable and binding only to the extent that they do not violate any applicable laws and are intended to be limited to the extent necessary so that they will not render this Agreement illegal, invalid or unenforceable."
          )}{" "}
          {tt(
            "t68d",
            "If any provision or portion of any provision of this Agreement shall be held to be illegal, invalid or unenforceable by a court of competent jurisdiction, it is the intention of the parties that the remaining provisions or portions thereof shall constitute their agreement with respect to the subject matter hereof, and all such remaining provisions or portions thereof shall remain in full force and effect."
          )}
        </div>
        <div className={classes.header}>{tt("t69d", "Dispute resolution")}</div>
        <div className={classes.text}>
          {tt(
            "t70da",
            "Service Provider tries to resolve any problems and disputes arising during the use of our services peacefully. We encourage you to always contact us with any problem you might have through our email address."
          )}{" "}
          {tt(
            "t70daa",
            "If simple communication can't satisfactorily resolve the dispute our refund terms apply."
          )}{" "}
        </div>
        <div className={classes.header}>
          {tt("vt69d", "Other enforcement possibilities")}
        </div>
        <div className={classes.text}>
          {tt(
            "vt70da",
            "If a consumer dispute between the Service Provider and User cannot be resolved by means of negotiations with the Service Provider, the User, in its capacity as consumer, can resort to the competent arbitration board located in the jurisdiction of the User’s place of residence or stay, and can initiate the procedure of the Board, or the User can resort to the competent Arbitration Board located in the jurisdiction of Service Provider’s registered office, or alternatively, the User can resort to the following enforcement possibilities:"
          )}
        </div>
        <div className={classes.text}>
          {tt(
            "vt70dac",
            "- File a complaint with the authority for consumer protection,"
          )}
        </div>
        <div className={classes.text}>
          {tt(
            "vt70d",
            "- Initiate the proceedings of the arbitration board Competent Board located in the jurisdiction of the Service Provider’s registered office:"
          )}
        </div>
        <div className={classes.text}>
          {tt("vt71d", "*ARBITRATION BOARD OF VAS COUNTY*")}
        </div>
        <div className={classes.text}>
          {tt("vt72d", "*Address:* 9700 Szombathely, Honvéd tér 2.")}
        </div>
        <div className={classes.text}>
          {tt("avt72d", "*Tel:* +36-94-312-356, +36-94-506-645")}
        </div>
        <div className={classes.text}>
          {tt("avt72dw", "*Administrator:* Pergel Bea")}
        </div>
        <div className={classes.text}>
          {tt("avt73d", "*E-mail:* pergel.bea@vmkik.hu")}
        </div>
        <div className={classes.header}>{tt("t69da", "Other means")}</div>
        <div className={classes.text}>
          {tt(
            "t70dr",
            "Should the dispute stay unresolved, the formation, interpretation, and performance of this Agreement and any disputes arising out of it shall be governed by the substantive and procedural laws of Hungary without regard to its rules on conflicts or choice of law and, to the extent applicable, the laws of Hungary."
          )}{" "}
          {tt(
            "t71de",
            "The exclusive jurisdiction and venue for actions related to the subject matter hereof shall be the courts located in Hungary, and you hereby submit to the personal jurisdiction of such courts."
          )}{" "}
        </div>
        <div className={classes.header}>
          {tt("sat73d", "Changes and amendments")}
        </div>
        <div className={classes.text}>
          {tt(
            "t74d",
            "We reserve the right to modify this Agreement or its policies relating to the Website or Services at any time, effective upon posting of an updated version of this Agreement on the Website."
          )}{" "}
          {tt(
            "t75d",
            "When we do, we will revise the updated date at the bottom of this page."
          )}{" "}
          {tt(
            "t76d",
            "Continued use of the Website after any such changes shall constitute your consent to such changes."
          )}
        </div>
        <div className={classes.header}>
          {tt("t77d", "Acceptance of these terms")}
        </div>
        <div className={classes.text}>
          {tt(
            "t78d",
            "You acknowledge that you have read this Agreement and agree to all its terms and conditions."
          )}{" "}
          {tt(
            "t79d",
            "By using the Website or its Services you agree to be bound by this Agreement."
          )}{" "}
          {tt(
            "t80d",
            "If you do not agree to abide by the terms of this Agreement, you are not authorized to use or access the Website and its Services."
          )}
        </div>
        <div className={classes.header}>{tt("t81d", "Contacting us")}</div>
        <div className={classes.text}>
          {tt(
            "t82d",
            "If you would like to contact us to understand more about this Agreement or wish to contact us concerning any matter relating to it, you may send an email to [*support@netenglish.com*]."
          )}
        </div>
        <div className={classes.text}>
          {tt("t83dcrf", "This document was last updated on May 2, 2022")}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Terms;
