import { functions } from "firebaseUtil/firebaseUtil";
import React, { useLayoutEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Confirm,
  Header,
  Icon,
  Message,
  Segment,
  Statistic,
} from "semantic-ui-react";

import { createStyles, makeStyles } from "@material-ui/styles";

import { firestore } from "../firebaseUtil/firebaseUtil";
import { RootState } from "../Redux/reducers/_index";
import ConfirmAct from "./account.confirmAct";
import ConfirmCardStore from "./account.confirmCardStore";
import EmailEditor from "./account.emailEditor";

import { loadStripe } from "@stripe/stripe-js";
import NewUserAdd from "../UserEditor/userEditor.newUserAdd";
import { Popup, Menu } from "semantic-ui-react";
import ConfirmInvoices from "./account.confirmInvoices";
import { tx } from "utils/globalize";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  "pk_test_51I0aXALSmpZDscoFmLNNOPD8h2GkbUNTR7eUq8Nxez5MEDb3VYOfc3AKX0HqkVzG8uhCm2u6B50b90xMTMDrLI3s00o3ZncGor"
);

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    saving: { color: "grey" },
    buymore: {
      display: "flex",
      width: "100%",
      alignItems: "center",
    },
    buttons: {
      flex: "0 0 auto",
    },
    price: {
      flex: "1 1 auto",
      fontWeight: 500,
      fontSize: "18px",
      paddingRight: "15px",
      paddingLeft: "15px",
    },
    checkout: { flex: "0 0 80px" },
    failed: { padding: "20px" },
  })
);

export const prices: any = {
  hu: {
    full: "23 800 Ft",
    discount: "12 900 Ft",
    price: 12900,
    currency: "HUF",
    language: "hu",
    code: "GXGCMEW21SHU",
    signature:
      "daebe0f53757984152fb6d4532630ce98007c248687eeb42ad84f514badaf3cd",
  },
  en: {
    full: "23 800 Ft",
    discount: "12 900 Ft",
    price: 12900,
    currency: "HUF",
  },
  es: { full: "€78", discount: "€39", price: 39, currency: "EUR" },
  it: {
    full: "€58",
    discount: "€29",
    discount1: 20,
    discount3: 14.5,
    discount5: 12.5,
    discount10: 10,
    price: 29,
    currency: "EUR",
    currencySign: "€xx",
    language: "it",
    signature:
      "c1f73afb59c2c8c190fb780546d9e4a4a4408453e131e1edd0dcc9ead89b3860",
    code: "REGXGCMEW21SIT",
  },
  pl: {
    full: "319,98 zl",
    discount: "159,99 zl",
    price: 159.99,
    currency: "PLN",
    discount1: 20,
    discount3: 14.5,
    discount5: 12.5,
    discount10: 10,
    currencySign: "xx zl",
    language: "pl",
    signature:
      "1c54b53795fb99fb77d0b621974e70702d206ed545fd7fb093881fe9f3fa12d6",
    code: "GXGCMEW21SPL",
  },
};

const AccountResale = (props: any) => {
  const classes = useStyles();

  const [buyMore, setBuyMore] = useState(false);
  const [buyOpen, setBuyOpen] = useState(false);
  const [price, setPrice] = useState(2000);
  const [pcs, setPcs] = useState(1);
  const [emailOpen, setEmailOpen] = useState(false);
  const [payLoad, setPayLoad] = useState(false);
  const [cardLoad, setCardLoad] = useState(false);
  const [openPay, setOpenPay] = useState(false);
  const [openCardStore, setOpenCardStore] = useState(false);
  const [openInvoices, setOpenInvoices] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [savedOpen, setSavedOpen] = useState(false);
  const [userDlgOpen, setUserDlgOpen] = useState(false);
  const [payedOpen, setPayedOpen] = useState(false);
  const [failedOpen, setFailedOpen] = useState(false);
  const [failReason, setFailReason] = useState("");

  const buyRef = useRef<any>(null);

  const currentUser = useSelector<RootState, any>((state) => state.currentUser);
  const ccodeGlobal = useSelector<RootState, string>(
    (state) => state.ccodeGlobal
  );

  const { account, formChanged, prices } = props;

  const lang = currentUser?.language;

  useLayoutEffect(() => {
    let onePrice = prices?.discount10;

    if (pcs < 10) {
      onePrice = prices?.discount5;
    }
    if (pcs < 5) {
      onePrice = prices?.discount3;
    }
    if (pcs < 3) {
      onePrice = prices?.discount1;
    }

    onePrice = 13;

    setPrice(pcs * onePrice || 0);
  }, [pcs, lang, prices]);

  // const payWindowOpen = async () => {
  //   if (!verifyForm(account)) {
  //     setConfirmOpen(true);
  //     return;
  //   }

  //   if (formChanged) {
  //     setSavedOpen(true);
  //     return;
  //   }

  //   if (account?.paymentMethod?.card?.brand) {
  //     setOpenPay(true);
  //   } else {
  //     instantPay();
  //   }
  // };

  // const huLink = `https://secure.2checkout.com/order/pf.php?MERCHANT=250631471495&BILL_COMPANY=${account.company}&BILL_FNAME=${account.firstName}&BILL_LNAME=${account.lastName}&BILL_EMAIL=${account.email}&BILL_ADDRESS=${account.address}&BILL_ZIPCODE=${account.zip}&BILL_CITY=${account.city}&BILL_COUNTRYCODE=${account.country}&URL=https%3A%2F%2Fsecure.2checkout.com%2Forder%2Fcheckout.php%3FPRODS%3D33912065%26QTY%3D1%26CART%3D1%26CARD%3D1%26SHORT_FORM%3D1%26CURRENCY%3DEUR%26LANG%3Dit%26ORDERSTYLE%3DnLWsm5XPnLo%3D%26PAY_TYPE%3DCCVISAMC%26BACK_REF%3Dhttps%253A%252F%252Fnetadmin.web.app%252F%26SECURE_CART%3D1%26PHASH%3D6738cbe9fddcf1c31f7e42ab7a635db2`;

  //secure.2checkout.com/checkout/buy?merchant=250631471495&currency=EUR&return-url=https%3A%2F%2Fnetadmin.web.app&return-type=redirect&style=default5fe47968b13ed&tpl=default&prod=REGXGCMEW21SIT&qty=1&signature=6c33f8020dfd9b0bc5c819b07169676e646ee154a7d8599fbb0ebd4068b26012

  const instantPay = async () => {
    //@ts-ignore
    Paddle.Checkout.open({
      product: 772204,
      //product: 28903,
      passthrough: ccodeGlobal,
      allowQuantity: true,
      quantity: pcs,
      email: account.email,
    });

    // setOpenPay(false);
    // if (!verifyForm(account)) {
    //   setConfirmOpen(true);
    //   return;
    // }

    // if (formChanged) {
    //   setSavedOpen(true);
    //   return;
    // }
    // setPayLoad(true);

    // //@ts-ignore
    // const TwoCoInlineCart = window.TwoCoInlineCart;

    // TwoCoInlineCart.setup.setMerchant("250631471495"); // your Merchant code
    // TwoCoInlineCart.register();

    // TwoCoInlineCart.billing.setCountry(account.country); // customer billing country
    // TwoCoInlineCart.billing.setName(account.firstName + " " + account.lastName); // customer billing Name
    // TwoCoInlineCart.billing.setEmail(account.email); // customer email address
    // TwoCoInlineCart.billing.setAddress(account.address);
    // TwoCoInlineCart.billing.setCity(account.city);
    // TwoCoInlineCart.billing.setZip(account.zip);
    // TwoCoInlineCart.billing.setCompanyName(account.companyName || "");
    // TwoCoInlineCart.billing.setFiscalCode(account.taxNumber);
    // TwoCoInlineCart.cart.setReset(true); // erase previous cart sessions
    // TwoCoInlineCart.products.removeAll();
    // TwoCoInlineCart.cart.setTest(true);
    // TwoCoInlineCart.cart.setSource(ccodeGlobal);
    // TwoCoInlineCart.products.add({
    //   code: `REGXGCMEW21S${currentUser.language.toUpperCase()}`,
    //   quantity: pcs,
    // });

    // TwoCoInlineCart.cart.setCurrency(prices?.currency); // order currency
    // TwoCoInlineCart.cart.setLanguage(currentUser?.language); // language code

    // const sign = functions.httpsCallable("signRequest");
    // let signature: any = {};

    // try {
    //   signature = await sign({
    //     data: JSON.stringify({
    //       merchant: "250631471495",
    //       lock: 1,
    //       "return-method": {
    //         type: "redirect",
    //         url: "https://admin.netenglish.com/dashboard/account/",
    //       },
    //       reset: true,
    //       products: [
    //         {
    //           code: "REGXGCMEW21S" + currentUser?.language.toUpperCase(),
    //           quantity: pcs,
    //         },
    //       ],
    //       currency: prices?.currency,
    //       language: currentUser?.language,
    //       test: true,
    //     }),
    //   });
    // } catch (e) {
    //   console.log(e);
    // }

    // console.log(signature);

    // TwoCoInlineCart.cart.setSignature(signature?.data?.signature || "");

    // setPayLoad(false);

    // TwoCoInlineCart.cart.checkout(); // start checkout process
  };

  //secure.2checkout.com/checkout/buy?merchant=250631471495&currency=EUR&return-url=https%3A%2F%2Fadmin.netenglish.com%2Fdashboard%2Faccount%2F&return-type=redirect&src=35002&style=default5fe47968b13ed&tpl=default&prod=REGXGCMEW21SIT&qty=1&signature=9b7b28698e55ba29831dc2274b889bdc3fdd227a0839a779aa280c908b5321c4

  const autoPay = async () => {
    if (!verifyForm(account)) {
      setConfirmOpen(true);
      return;
    }

    if (formChanged) {
      setSavedOpen(true);
      return;
    }

    setOpenPay(false);
    const paymentLink = functions.httpsCallable("barionAutoPay");

    if (formChanged) {
      setSavedOpen(true);
      return;
    }

    let linkData: any;
    setPayLoad(true);

    try {
      linkData = await paymentLink({
        host: document.location.host,
        email: account.email,
        price: 2000,
        currency: "HUF",
        language: (currentUser.language || "hu").replace("en", "hu"),
        pcs: pcs,
        ccode: ccodeGlobal,
        data: account,
      });
    } catch (e) {
      console.log(e);
    }

    if (linkData.data?.errors) {
      setPayLoad(false);
      return;
    }

    if (linkData.data.response?.Status === "Succeeded") {
      setOpenPay(false);
      setPayLoad(false);
    }

    // if (linkData.data?.url) {
    //   window.location.href = linkData.data.url;
    // }
  };

  const saveCard = async () => {
    if (!verifyForm(account)) {
      setConfirmOpen(true);
      return;
    }

    if (formChanged) {
      setSavedOpen(true);
      return;
    }

    setCardLoad(true);

    const saveCardStart = functions.httpsCallable("saveCardStart");

    let session: any = {};

    try {
      session = await saveCardStart(account);
    } catch (e) {
      console.log(e);
    }

    const stripe: any = await stripePromise;

    console.log(session.data.session);

    const { error } = await stripe.redirectToCheckout({
      sessionId: session.data.session.id,
    });

    console.log(error);
  };

  // const onAuthorize = () => {
  //   firestore
  //     .doc("/companies/" + ccodeGlobal + "/data/account")
  //     .set({ authorize: !account.authorize }, { merge: true });
  //   setOpenAuthorize(false);
  // };

  const deleteCard = async () => {
    setCardLoad(true);
    await firestore
      .doc("/companies/" + ccodeGlobal + "/data/account")
      .set({ authorize: false, paymentMethodAuto: {} }, { merge: true });

    // await firestore
    //   .doc("/companies/" + ccodeGlobal + "/data/subscription")
    //   .delete();
    setCardLoad(false);
    setOpenCardStore(false);
  };

  const priceFormat = (inprice: number) => {
    const fullPrice = prices.discount1 * pcs;
    const saving = fullPrice - price;

    return (
      <React.Fragment>
        <span>{prices?.currencySign?.replace("xx", inprice)}</span>
        {saving ? (
          <span className={classes.saving}>
            {" "}
            ({prices?.currencySign?.replace("xx", price / pcs)}/licence)
          </span>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  };

  const showBuyMenu = () => {
    if (formChanged) {
      setSavedOpen(true);
      return;
    }
    setBuyOpen(true);
  };

  const showInvoices = () => {
    setOpenInvoices(true);
  };

  const buyFromMenu = async (data: any) => {
    setBuyOpen(false);
    if (data.func === "new") {
      instantPay();
    } else {
      setPayLoad(true);
      const x2cbuy = functions.httpsCallable("x2cbuy");

      let buy: any = {};

      console.log(account);

      try {
        buy = await x2cbuy({
          account: account,
          code: `REGXGCMEW21S${currentUser.language.toUpperCase()}`,
          quantity: pcs,
          currency: prices?.currency,
          language: currentUser?.language,
          ccode: ccodeGlobal,
          ref: account.paymentMethod?.card?.ref || "",
        });
      } catch (e) {
        setPayLoad(false);
        setFailedOpen(true);
        return;
      }

      if (buy.data.error) {
        console.log(buy.data.error);
        setFailedOpen(true);
        setFailReason(buy.data.error.message);
      } else {
        setPayedOpen(true);
        setBuyMore(false);
      }
      setPayLoad(false);
    }
  };

  return (
    <React.Fragment>
      {props.type === "buy" ? (
        <div style={{ flex: "0 0 auto" }}>
          <Header
            attached="top"
            style={{
              marginTop: "0px",
              background: "#f5f5f5",
              padding: "5px",
              minHeight: "42px",
            }}
          >
            <div style={{ padding: "5px", display: "inline-block" }}>
              {tx("avused", "Available/Used licences")}
            </div>
            {/* <Button
              size="tiny"
              floated="right"
              onClick={() => setUserDlgOpen(true)}
              style={{ minWidth: "100px" }}
            >
              Add User
            </Button> */}
          </Header>
          <Segment attached textAlign="center">
            <Statistic.Group style={{ marginRight: "1.5em", display: "block" }}>
              <Statistic color="green">
                <Statistic.Value>
                  {account.tba !== undefined ? account.tba : "0"}
                </Statistic.Value>
                <Statistic.Label>{tx("wlekkooo", "available")}</Statistic.Label>
              </Statistic>
              <Statistic color="brown">
                <Statistic.Value>{account.used || 0}</Statistic.Value>
                <Statistic.Label>{tx("usedokok", "used")}</Statistic.Label>
              </Statistic>
            </Statistic.Group>
          </Segment>
          <Message
            success
            attached="bottom"
            style={{
              padding: "5px",
              textAlign: buyMore ? "left" : "right",
            }}
          >
            {buyMore ? (
              <React.Fragment>
                <div className={classes.buymore}>
                  <div className={classes.buttons}>
                    <Button
                      style={{ padding: "9px 4px 9px 8px" }}
                      onClick={() => {
                        if (pcs > 1) {
                          setPcs(pcs - 1);
                        }
                      }}
                      size="mini"
                      attached="left"
                    >
                      <Icon name="minus" />
                    </Button>

                    <input
                      value={pcs}
                      onChange={(e: any) => {
                        setPcs(e.currentTarget.value);
                      }}
                      min={1}
                      type="number"
                      style={{
                        borderStyle: "solid",
                        borderRadius: "0px",
                        width: "35px",
                        height: "30px",
                        textAlign: "center",
                        border: "1px solid lightgrey",
                      }}
                    />

                    <Button
                      style={{ padding: "9px 4px 9px 8px" }}
                      positive
                      size="mini"
                      attached="right"
                      onClick={() => {
                        setPcs(pcs + 1);
                      }}
                    >
                      <Icon name="plus" />
                    </Button>
                  </div>
                  <div className={classes.price}>{priceFormat(price)}</div>
                  <div className={classes.checkout}>
                    <Button
                      size="tiny"
                      onClick={
                        account?.paymentMethod?.card?.brand
                          ? () => showBuyMenu()
                          : () => instantPay()
                      }
                      positive
                      loading={payLoad}
                    >
                      {tx("buywolklkwe", "Buy")}
                    </Button>
                    <Popup
                      basic
                      context={buyRef}
                      onClose={() => setBuyOpen(false)}
                      open={buyOpen}
                    >
                      <b>Pay with:</b>
                      <Menu
                        items={[
                          {
                            key: "saved",
                            func: "saved",
                            content:
                              account?.paymentMethod?.card?.brand +
                              " ..." +
                              account?.paymentMethod?.card?.last4,
                            icon: "copy",
                          },
                          {
                            func: "new",
                            key: "new",
                            content: "New payment method",
                            icon: "code",
                          },
                        ]}
                        onItemClick={(e, data) => buyFromMenu(data)}
                        secondary
                        vertical
                      />
                    </Popup>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <Button
                size="tiny"
                onClick={() => setBuyMore(true)}
                style={{ minWidth: "100px" }}
              >
                {tx("buaweruo", "Buy More")}
              </Button>
            )}
            <span ref={buyRef} />
          </Message>
        </div>
      ) : null}
      {/* <div style={{ flex: "0 0 auto" }}>
        <Header
          attached="top"
          style={{
            marginTop: "10px",
            background: "#f5f5f5",
          }}
        >
          Payment Method
        </Header>
        <Segment
          attached
          style={{
            padding: "5px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ paddingLeft: "10px", flex: "1 1 auto" }}>
            {account?.paymentMethodAuto?.card?.brand
              ? account?.paymentMethodAuto?.card?.brand +
                " ending in ..." +
                account?.paymentMethodAuto?.card?.last4
              : "No saved card."}
          </div>
          <div>
            <Button
              size="tiny"
              floated="right"
              onClick={() => setOpenCardStore(true)}
              style={{ minWidth: "100px" }}
            >
              {account?.paymentMethodAuto?.card?.brand ? "Change" : "Add card"}
            </Button>
          </div>
        </Segment>
        {account?.paymentMethodAuto?.card?.brand ? (
          <Message
            success
            attached="bottom"
            style={{ padding: "5px", marginBottom: "0px" }}
          >
            <Icon name="check" />
            Verified.
          </Message>
        ) : (
          ""
        )}
      </div> */}

      {/* <div
        style={{
          flex: "1 1 auto",
          marginTop: "10px",
          overflow: "hidden",
          padding: "1px",
        }}
      >
        <Header
          attached="top"
          style={{ marginTop: "0px", background: "#f5f5f5" }}
        >
          Invoices
        </Header>
        <Segment
          attached
          style={{
            flex: "1 1 auto",
            height: "calc(100% - 41px)",
            overflowY: "auto",
            paddingTop: "0px",
          }}
        >
          <Button onClick={showInvoices} />
        </Segment>
      </div> */}

      <EmailEditor open={emailOpen} setOpen={setEmailOpen} />
      <ConfirmCardStore
        open={openCardStore}
        setOpen={setOpenCardStore}
        onAction={saveCard}
        account={account}
        onDelete={deleteCard}
        cardLoad={cardLoad}
      />
      <ConfirmInvoices
        open={openInvoices}
        setOpen={setOpenInvoices}
        onAction={saveCard}
        account={account}
        onDelete={deleteCard}
        cardLoad={cardLoad}
      />
      <ConfirmAct
        open={openPay}
        setOpen={setOpenPay}
        onNewPay={instantPay}
        onSavedPay={autoPay}
        account={account}
        payLoad={payLoad}
        amount={pcs * price}
      />
      <Confirm
        open={confirmOpen}
        content="Please make sure you correct the fields in red before purchasing."
        onCancel={() => setConfirmOpen(false)}
        onConfirm={() => setConfirmOpen(false)}
        cancelButton="Cancel"
        confirmButton="OK"
      />
      <Confirm
        open={savedOpen}
        content="Save your account data first."
        onCancel={() => setSavedOpen(false)}
        onConfirm={async () => {
          setSavedOpen(false);
        }}
        cancelButton="Cancel"
        confirmButton="OK"
      />
      <NewUserAdd
        open={userDlgOpen}
        setOpen={setUserDlgOpen}
        account={props.account}
        setActive={props.setActive}
        doSearch={() => {}}
      />
      <Confirm
        cancelButton={null}
        confirmButton="OK"
        header="Payment result..."
        content="Your payment was successful. In a few seconds you will get the additional licences."
        open={payedOpen}
        onCancel={() => setPayedOpen(false)}
        onConfirm={() => setPayedOpen(false)}
        size="mini"
      />
      <Confirm
        cancelButton={null}
        confirmButton="OK"
        header="Payment result..."
        content={
          <React.Fragment>
            <div className={classes.failed}>
              <p>
                Your payment was unsuccessful. Please try again probably with a
                different payment method.
              </p>
              <p>Reason:</p>
              <p>{failReason}</p>
            </div>
          </React.Fragment>
        }
        open={failedOpen}
        onCancel={() => setFailedOpen(false)}
        onConfirm={() => setFailedOpen(false)}
        size="mini"
      />
    </React.Fragment>
  );
};

export default AccountResale;
// AIzaSyA8d9SleAVkuA1BwyrcvBypzmt9rFDXFrA

const verifyForm = (a: any) => {
  const privateWrong =
    !a.firstName ||
    !a.lastName ||
    !a.country ||
    !a.zip ||
    !a.email ||
    !a.city ||
    !a.address;

  const companyWrong =
    !a.companyName ||
    !a.taxNumber ||
    !a.companyCity ||
    !a.companyZip ||
    !a.companyCountry ||
    !a.companyCity ||
    !a.companyAddress;

  if (a.isCompany) {
    return !(companyWrong || privateWrong);
  } else {
    return !privateWrong;
  }
};
