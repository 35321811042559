import "./App.css";

import BasicUi from "mainComponents/ui";
import React, { useEffect } from "react";
import { setAuthLoaded } from "Redux/reducers/reducer.authLoaded";
import { setCurrentUser } from "Redux/reducers/reducer.currentUser";
import { setProgram } from "Redux/reducers/reducer.program";
import store from "Redux/store";

import { phaseBodyOut } from "utils/utils";

import { auth, getUserProfileDocument } from "../firebaseUtil/firebaseUtil";
import { phaseOut } from "../utils/utils";
import { confirmUpgrade, versionCheck } from "./App.upgrade";
import { setupListeners } from "./App.utils";
import { setLessonDataChange } from "Redux/reducers/reducer.lessonData";

export const setLessonData = (i: any) => {
  //@ts-ignore
  window.lessonData = i;
  //@ts-ignore
  console.log(window.lessonData);
};

//@ts-ignore
window.lessonData = new Document();

//@ts-ignore
export const lessonData = window.lessonData;

function App() {
  useEffect(() => {
    const dimmer = document.getElementById("dimmerlite");
    if (dimmer) {
      dimmer.style.transition = "none";
    }
    phaseOut();
    phaseBodyOut();
    setTimeout(() => {
      if (dimmer) {
        dimmer.style.transition = "";
      }
    }, 0);
  }, []);

  useEffect(() => {
    const vCheck = setInterval(confirmUpgrade, 30000);
    versionCheck("");
    return () => clearInterval(vCheck);
  }, []);

  useEffect(() => {
    auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        const userRef = await getUserProfileDocument(userAuth, {});
        if (userRef) {
          setupListeners(userRef, userAuth);
        }
      } else {
        store.dispatch(setCurrentUser(null));
        store.dispatch(setProgram({ index: [] }));
        store.dispatch(setAuthLoaded(true));
      }
    });
  }, []);

  return (
    <React.Fragment>
      <BasicUi />
      {/*<Confirmation />*/}
    </React.Fragment>
  );
}

export default App;
