import React from "react";
import { createStyles, makeStyles } from "@material-ui/styles";

import store from "Redux/store";
import { setMouse } from "Redux/reducers/reducer.mouse";
// import loadable from "@loadable/component";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";

// @ts-ignore
// const { UnControlled } = loadable(() => import("react-codemirror2")) as any;

import { UnControlled } from "react-codemirror2";

// // @ts-ignore
// loadable.lib(() => import("codemirror/mode/xml/xml"));
// // @ts-ignore
// loadable.lib(() => import("codemirror/mode/javascript/javascript"));
// loadable.lib(() => import("codemirror/addon/search/searchcursor"));
// // @ts-ignore
// loadable.lib(() => import("codemirror/addon/search/search"));

require("codemirror/mode/xml/xml");
require("codemirror/mode/javascript/javascript");
require("codemirror/addon/search/searchcursor");
require("codemirror/addon/search/search");

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    paper1: {
      padding: "5px",
      userSelect: "none",
      marginBottom: "7px",
      marginRight: "0px",
      position: "relative",
    },
    codemirror: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      height: "100%",
    },
    container: {
      padding: "7px",
    },
    menu: {
      height: "400px",
      overflowY: "scroll",
    },
    menudiv: {
      border: "1px solid lightgrey",
      background: "#eeeeff",
      marginBottom: "8px",
    },
    button: {
      marginLeft: "6px",
      marginRight: "6px",
      minWidth: "180px",
    },
    buttonchoose: {
      marginLeft: "6px",
      marginRight: "6px",
    },
    select: {
      paddingTop: "9px",
      paddingBottom: "9px",
    },
  })
);

const CodeEditor = (props: any) => {
  const classes = useStyles();

  const contextMenu = (
    editor: any,
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    event.preventDefault();
    store.dispatch(
      setMouse({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
      })
    );
  };

  return (
    <div className={classes.paper1}>
      <UnControlled
        className={classes.codemirror}
        value="<h1>I ♥ react-codemirror2</h1>"
        options={{
          mode: "xml",
          theme: "material",
          lineNumbers: true,
          lineWrapping: true,
        }}
        editorDidMount={(editor) => {
          props.setCM(editor);
        }}
        onChange={(editor, data, value) => {}}
        onContextMenu={contextMenu}
      />
    </div>
  );
};

export default CodeEditor;
