import React from "react";
import { Form, Select, Menu } from "semantic-ui-react";
import { LessonListType } from "../Redux/reducers/reducer.lessonList";
import { makeStyles, createStyles } from "@material-ui/styles";
import store from "Redux/store";
import {
  setBackdrop,
  setBackdropOpen,
} from "Redux/reducers/reducer.backdropOpen";
import { loadLesson } from "../utils/saver.loadLesson";
import { tx } from "utils/globalize";
import { readXMLLesson } from "../utils/readxml";
import { setLessonData } from "app/App";
import { setLessonDataChange } from "Redux/reducers/reducer.lessonData";

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    text: {},
  })
);

const typeList = [
  "Lessons",
  "Webpages",
  "Application",
  "Promos",
  "Words",
  "Grammar",
];

var beautify_html = require("js-beautify").html;

const EditorFiles = (props: any) => {
  //eslint-disable-next-line
  const classes = useStyles();

  const changeType = (e: any, data: any) => {
    e.persist();
    props.setType(data.value);
  };

  const changeLesson = async (e: any, data: any) => {
    store.dispatch(
      setBackdrop({
        open: true,
        text: tx("pasdwwsdaypage", "Processing..."),
      })
    );
    const newLesson = data.name;

    if (!newLesson) {
      return;
    }

    const thisLesson = await loadLesson(data.name);

    const formattedXML = beautify_html(thisLesson.xml || "", {
      indent_size: 2,
    });
    props.cm.doc.setValue(formattedXML);

    setLessonData(readXMLLesson(formattedXML, true));
    store.dispatch(setBackdropOpen(false));
    setTimeout(() => props.setLesson(newLesson), 0);
    store.dispatch(setLessonDataChange(Date.now()));
  };

  return (
    <React.Fragment>
      <Form style={{ height: "100%", marginBottom: "5px", overflow: "hidden" }}>
        <Form.Field
          control={Select}
          options={typeList.map((option: string) => ({
            key: option,
            text: option,
            value: option.toLowerCase(),
          }))}
          placeholder="Type..."
          value={props.type}
          onChange={changeType}
        />
        <Menu
          vertical
          style={{
            height: "calc(100% - 80px)",
            marginBottom: "5px",
            overflow: "auto",
          }}
        >
          {props.lessonList.map((option: LessonListType) => (
            <Menu.Item
              key={option.seq}
              name={option.seq}
              content={
                option ? option.seq + " (" + option.number + ")" : "None"
              }
              active={props.lesson === option.seq}
              onClick={changeLesson}
            />
          ))}
        </Menu>
      </Form>
    </React.Fragment>
  );
};

export default EditorFiles;
