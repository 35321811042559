import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Dimmer, Loader, Segment, Tab } from "semantic-ui-react";

import { createStyles, makeStyles } from "@material-ui/styles";

import { firestore } from "../firebaseUtil/firebaseUtil";
import { RootState } from "../Redux/reducers/_index";
import AccountPersData from "./account.persdata";
import AccountRecommend from "./account.recommend";
import AccountResale from "./account.resale";
import AccountSharing from "./account.sharing";
import AccountTipsRecommend from "accounttips/account.recommendtips";
import AccountTipsBuy from "accounttips/account.buytips";
import AccountTipsResell from "accounttips/account.reselltips";
import AccountTipsCustomize from "accounttips/account.customize";
import { tx } from "utils/globalize";

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    adminHeader: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    headerIcon: {
      width: "50px",
    },
    headerText: {
      flex: "1 1 auto",
    },

    topSegment: {
      marginBottom: "0px !important",
      borderBottom: "0px solid white !important",
      paddingTop: "8px !important",
      paddingBottom: "8px !important",
      height: "54px",
      display: "flex !important",
      alignItems: "center !important",
      borderBottomLeftRadius: "0px !important",
      borderBottomRightRadius: "0px !important",
    },
    cardIconTitle: {
      paddingTop: "0px",
      marginBottom: "0px",
      width: "100%",
      marginTop: "0px",
    },
    flexWrapper: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      position: "absolute",
      top: 0,
      bottom: 0,
      paddingTop: "10px",
      paddingBottom: "10px",
      lineHeight: "170%",
      "&& > div": {
        display: "flex",
        flexDirection: "column",
        width: "100%",
      },
    },

    custom: {
      flex: "0 0 400px",
      padding: "0px 0px 0px 15px",
      display: "flex",
      flexDirection: "column",
      marginRight: "15px",
      height: "100%",
      minWidth: "400px",
    },
    customMiddle: {
      flex: "0 0 600px",
      padding: "0px 0px 0px 0px",
      display: "flex",
      flexDirection: "column",
      marginRight: "15px",
      height: "100%",
      minWidth: "500px",
    },
    customEnd: {
      flex: "1 0 375px",
      padding: "0px 0px 0px 0px",
      display: "flex",
      flexDirection: "column",
      marginRight: "15px",
      height: "100%",
      minWidth: "350px",
    },
    cat: {
      marginBottom: "0px !important",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
  })
);

const Account = (props: any) => {
  const classes = useStyles();

  const [tabIndex, setTabIndex] = useState(0);
  const [prices, setPrices] = useState<any>({});

  const currentUser = useSelector<RootState, any>((state) => state.currentUser);

  const { account, setAccount, formChanged, setFormChanged } = props;

  useEffect(() => {
    const loadPrice = async () => {
      const pricing = await firestore
        .doc("/pricing/" + currentUser?.language)
        .get();
      setPrices(pricing.data() || {});
    };

    if (currentUser?.language) {
      loadPrice();
    }
  }, [currentUser?.language]);

  const handleTabChange = (e: any, b: any) => setTabIndex(b.activeIndex);

  return (
    <React.Fragment>
      {/* <Segment className={classes.topSegment} inverted>
        <Grid style={{ flex: "1 1 auto" }}>
          <Grid.Row>
            <Grid.Column width={16}>
              <div className={classes.adminHeader}>
                <div className={classes.headerIcon}>
                  <Icon name="address card" />
                </div>
                <div className={classes.headerText}>
                  <h4 className={classes.cardIconTitle}>
                    Account ({account.ccode})
                  </h4>
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment> */}
      <div className={classes.flexWrapper} id="flexwrap">
        {/* <Dimmer active={account?.tba === undefined}>
          <Loader size="medium">Loading...</Loader>
        </Dimmer> */}
        <React.Fragment>
          <Tab
            renderActiveOnly={false}
            onTabChange={handleTabChange}
            menu={{ secondary: true, pointing: true }}
            activeIndex={tabIndex}
            panes={[
              {
                menuItem: tx("coweaowowoe2", "Customize"),
                pane: (
                  <Tab.Pane
                    key={0}
                    style={{
                      height: "100%",
                      border: "none",
                      padding: "0px",
                      marginBottom: "0px",
                    }}
                  >
                    <AccountTipsCustomize
                      account={account}
                      setAccount={setAccount}
                      language={currentUser?.language}
                    />
                    {/* <AccountRecommend
                          account={account}
                          prices={prices}
                          setAccount={setAccount}
                        /> */}
                  </Tab.Pane>
                ),
              },
              {
                menuItem: tx("coweaowowoe1", "Get Commission"),
                pane: (
                  <Tab.Pane
                    key={1}
                    style={{
                      height: "100%",
                      border: "none",
                      padding: "0px",
                      marginBottom: "0px",
                    }}
                  >
                    <AccountTipsRecommend
                      account={account}
                      setAccount={setAccount}
                    />
                    {/* <AccountRecommend
                          account={account}
                          prices={prices}
                          setAccount={setAccount}
                        /> */}
                  </Tab.Pane>
                ),
              },
              {
                menuItem: tx("coweaowowoe0", "Buy"),
                pane: (
                  <Tab.Pane
                    key={2}
                    style={{
                      height: "100%",
                      border: "none",
                      padding: "0px",
                      marginBottom: "0px",
                    }}
                  >
                    <AccountTipsBuy
                      account={account}
                      formChanged={formChanged}
                      prices={prices}
                    />
                    {/* <AccountResale
                      account={account}
                      formChanged={formChanged}
                      prices={prices}
                    /> */}
                  </Tab.Pane>
                ),
              },
              {
                menuItem: tx("coweaowowoe3", "Resell"),
                pane: (
                  <Tab.Pane
                    key={3}
                    style={{
                      height: "100%",
                      border: "none",
                      padding: "0px",
                    }}
                  >
                    <AccountTipsResell account={account} />
                    {/* <AccountRecommend
                      account={account}
                      prices={prices}
                      setAccount={setAccount}
                    /> */}
                  </Tab.Pane>
                ),
              },
              {
                menuItem: tx("coweaowowoe4", "Account Data"),
                pane: (
                  <Tab.Pane
                    key={4}
                    style={{
                      height: "100%",
                      border: "none",
                      padding: "0px",
                    }}
                  >
                    <AccountPersData
                      account={account}
                      setAccount={setAccount}
                      formChanged={formChanged}
                      setFormChanged={setFormChanged}
                    />
                  </Tab.Pane>
                ),
              },
            ]}
          />
        </React.Fragment>
      </div>
    </React.Fragment>
  );
};

export default Account;
// AIzaSyA8d9SleAVkuA1BwyrcvBypzmt9rFDXFrA
