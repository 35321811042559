import firebase from "firebase/app";
// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/database";
import "firebase/functions";
import { tx } from "../utils/globalize";
import store from "Redux/store";
import { setAuthLoaded } from "Redux/reducers/reducer.authLoaded";
import { phaseOut, phaseBodyOut } from "../utils/utils";

type User = firebase.User;
type FirebaseError = firebase.FirebaseError;
// load firebase

const config = {
  apiKey: "AIzaSyBMpDpBCa8nfMDjxfTB0P1uygq3e-6wllo",
  authDomain: "netenglishm.firebaseapp.com",
  databaseURL: "https://netenglishm.firebaseio.com",
  projectId: "netenglishm",
  storageBucket: "netenglishm.appspot.com",
  messagingSenderId: "381928610475",
  appId: "1:381928610475:web:8083562d0d865e0067b5ee",
  measurementId: "G-ZTHR1MTHCZ",
};

firebase.initializeApp(config);
// firebase.app().auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

export interface FreeData {
  [key: string]: any;
}

export const getUserProfileDocument = async (
  userAuth: User,
  additionalData: FreeData
) => {
  if (!userAuth) return;

  const userRef = firestore.doc(`users/${userAuth.uid}`);

  try {
    // const snapShot = await userRef.get();

    // if (!snapShot.exists) {
    //   console.log("no such user");
    // }
    return userRef;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const signInWithEmail = (
  email: string,
  password: string,
  rememberme: boolean,
  callbacks: FreeData = {}
) => {
  store.dispatch(setAuthLoaded(false));

  firebase
    .auth()
    .setPersistence(
      rememberme
        ? firebase.auth.Auth.Persistence.LOCAL
        : firebase.auth.Auth.Persistence.SESSION
    )
    .then(function () {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(() => {
          if (callbacks.success) {
            callbacks.success();
          }
        })
        .catch(function (error: FirebaseError) {
          if (callbacks.failure) {
            callbacks.failure(
              tx("35F917BE-E7E5-46DA-8A86-0770A4ECBAA9", "Login error.") +
                " (" +
                error.code.replace("auth/", "") +
                ")"
            );
          }
        });
    })
    .catch(function (error) {
      callbacks.failure(
        callbacks.failure(
          tx("35F917BE-E7E5-46DA-8A86-0770A4ECBAA9", "Login error.") +
            " (" +
            error.code +
            ")"
        )
      );
    });
};

export const signOut = async () => {
  store.dispatch(setAuthLoaded(false));
  phaseOut();
  phaseBodyOut();
  await firebase.auth().signOut();
  localStorage.setItem("currentUser", "");
  localStorage.setItem("userType", "");
  console.log("signed out");
};

// Create a reference with an initial file path and name

export const cloudStorage = firebase.storage();

// Create a reference from a Google Cloud Storage URI

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const firedata = firebase.database();

firebase
  .firestore()
  .enablePersistence({ synchronizeTabs: true })
  .catch(function (err: any) {
    if (err.code === "failed-precondition") {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code === "unimplemented") {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  });

// if (process.env.NODE_ENV === "development") {
//   firebase.functions().useFunctionsEmulator("http://localhost:5001");
// }

export const functions = firebase.app().functions("europe-west2");

// export const functions = firebase.functions();

export default firebase;
