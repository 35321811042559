import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { setBuyNow } from "Redux/reducers/reducer.buynow";
import { incrementRouted } from "Redux/reducers/reducer.routed";
import { setTutorial } from "Redux/reducers/reducer.tutorial";
import store from "Redux/store";

// import { signOut } from "../firebaseUtil/firebaseUtil";
import { RootState } from "../Redux/reducers/_index";
import { UserType } from "../Redux/reducers/reducer.currentUser";
import { beginsWith } from "../utils/utils";

// const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching

const checkTutorial = () => {
  if (store.getState().settings.showFirstPage) {
    store.dispatch(setTutorial(true));
  }
};

const checkBuyNow = () => {
  const currentUser = store.getState().currentUser;
  if (!currentUser) {
    return;
  }
  if (
    currentUser.subscriptionType === 0 &&
    Date.now() - currentUser.joined < 72 * 3600000
  ) {
    store.dispatch(setBuyNow(true));
  }
};

function setDocHeight() {
  document.documentElement.style.setProperty("--vh", `${window.innerHeight}px`);
  if (window.innerHeight > 600) {
    document.documentElement.style.setProperty("--logoHeight", `170px`);
    document.documentElement.style.setProperty("--logoPadding", `25px`);
  } else {
    document.documentElement.style.setProperty("--logoHeight", `135px`);
    document.documentElement.style.setProperty("--logoPadding", `0px`);
  }
}

const AuthCheck = () => {
  const location = useLocation();
  const history = useHistory();
  // const posHistory = useRef<any>();

  const currentUser = useSelector<RootState, UserType>(
    (state) => state.currentUser
  );

  const authLoaded = useSelector<RootState, boolean>(
    (state) => state.authLoaded
  );

  // const position = useSelector<RootState, PositionType>(
  //   (state) => state.position
  // );

  useEffect(() => {
    store.dispatch(incrementRouted(null));
  }, [location.pathname, location.search]);

  useEffect(() => {
    setInterval(checkTutorial, 3600000);
    window.addEventListener("resize", setDocHeight);
    window.addEventListener("orientationchange", setDocHeight);

    setDocHeight();
  }, []);

  const userEmail = currentUser?.email;

  useEffect(() => {
    if (userEmail) {
      checkTutorial();
      checkBuyNow();
    }
  }, [userEmail]);

  useEffect(() => {
    if (!authLoaded) {
      return;
    }

    if (beginsWith(location.pathname, "/login/login")) {
      if (currentUser) {
        // signOut();
      }
      return;
    }

    if (beginsWith(location.pathname, "/login/signup")) {
      if (currentUser) {
        // signOut();
      }
      return;
    }

    if (beginsWith(location.pathname, "/login/newpw")) {
      if (currentUser) {
        // signOut();
      }
      return;
    }

    if (beginsWith(location.pathname, "/login/forgotten")) {
      if (currentUser) {
        // signOut();
      }
      return;
    }

    if (beginsWith(location.pathname, "/dashboard")) {
      if (
        currentUser &&
        (currentUser.type === "admin" ||
          currentUser.type === "contributor" ||
          currentUser.type === "voice" ||
          currentUser.type === "subadmin")
      ) {
        //
        return;
      } else {
        history.push("/login/login");
        return;
      }
    }

    history.push("/dashboard");
  }, [location, history, currentUser, authLoaded]);

  return <div />;
};

export default AuthCheck;
