import React, { useState } from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Modal, Button } from "semantic-ui-react";

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    iframe: {
      width: "100%",
      border: "none",
      height: "667px",
    },
    iframeWrapper: { border: "1px solid lightgrey" },
    iframeWrapperMobile: {
      border: "1px solid lightgrey",
      width: "375px",
      textAlign: "center",
      alignSelf: "center",
      margin: "auto",
    },
    titlePlus: { flex: "0 0 auto" },

    titleWrapper: { display: "flex", width: "100%", marginBottom: "3px" },
    title: {
      flex: "1 1 auto",
      fontWeight: "bold",
      marginBottom: "5px",
      fontSize: "16px",
      marginTop: "4px",
    },
  })
);

const DocerDemo = (props: any) => {
  const classes = useStyles();
  const [mobile, setMobile] = useState(true);

  return (
    <React.Fragment>
      <Modal
        size={"small"}
        open={true}
        onClose={() => props.setViewerOpen(false)}
      >
        <Modal.Header>
          Preview
          <Button
            icon="mobile"
            size="tiny"
            color="olive"
            onClick={() => setMobile(true)}
            compact
            inverted={!mobile}
            active={false}
            floated={"right"}
          />
          <Button
            icon="tv"
            size="tiny"
            color="olive"
            compact
            onClick={() => setMobile(false)}
            inverted={mobile}
            active={false}
            floated={"right"}
          />
        </Modal.Header>

        <Modal.Content>
          <div
            className={
              mobile ? classes.iframeWrapperMobile : classes.iframeWrapper
            }
          >
            <iframe
              className={classes.iframe}
              title="preview"
              src={"http://localhost:3001/app/words/demo"}
            />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            negative
            onClick={() => {
              props.setViewerOpen(false);
            }}
          >
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  );
};

export default DocerDemo;
