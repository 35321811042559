import { functions } from "firebaseUtil/firebaseUtil";
import React, { useEffect, useRef, useState } from "react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
// @material-ui/core components

import {
  Form,
  Checkbox,
  Select,
  Modal,
  Button,
  Input,
} from "semantic-ui-react";
// import { makeStyles } from "@material-ui/styles";
import { tx } from "../utils/globalize";
import { toast, SemanticToastContainer } from "react-semantic-toasts";
import { Transition, Segment } from "semantic-ui-react";

const options = [
  { key: "u", text: "Student", value: "user" },
  { key: "c", text: "Contributor", value: "contributor" },
  { key: "v", text: "Voice", value: "voice" },
  { key: "a", text: "Local Admin", value: "localadmin" },
];

const languages = [
  { key: "h", text: "Hungarian", value: "hu" },
  { key: "i", text: "Italian", value: "it" },
  { key: "c", text: "Czech", value: "cs" },
  { key: "p", text: "Polish", value: "pl" },
  { key: "m", text: "LATAM", value: "es" },
];

// const gridStyle = {
//   card: {
//     marginTop: "0px",
//     marginBottom: "0px",
//     minWidth: "450px",
//   },
//   form: {
//     marginBottom: "0px",
//   },
//   container: {
//     minWidth: "400px",
//     padding: "5px 25px 0px 25px",
//   },

//   topHead: {
//     display: "flex",
//     flexDirection: "row",
//     alignItems: "center",
//   },
//   topHeadIcon: {
//     width: "50px",
//   },
//   button: {
//     // boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.4)",
//     borderRadius: "3px",
//     padding: "12px 14px",
//     marginRight: "0px",
//     background: "var(--toolbar-background)",
//     fontFamily: "var(--font-normal)",
//     fontSize: "18px",
//     transformText: "uppercase",
//     color: "var(--toolbar-color)",
//   },
// };

// const useStyles = makeStyles({
//   ...gridStyle,
// } as any);

export default function NewUserForm(props: any) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userType, setuserType] = React.useState("user");
  const [working, setWorking] = useState(false);
  const [sendEmail, setSendEmail] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [language, setLanguage] = useState("en");
  const [expiration, setExpiration] = useState(Date.now());

  const textRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const updateForm = async () => {
      setEmail(props.user?.email || "");
      setFirstName(props.user?.firstName || "");
      setLastName(props.user?.lastName || "");
      setuserType(props.user?.type || "user");
      setLanguage(props.user?.language || "");
      setExpiration(props.user?.expiry || Date.now());
    };

    // if (!props.user?.email) {
    //   let pw = generator.generate({
    //     length: 8,
    //     numbers: true,
    //   });

    //   pw = pw.replace(/l/g, "Z").replace(/I/g, "r");

    //   setPassword(pw);
    // }

    updateForm();
  }, [props.user]);

  const checkEmail = (e: any) => {
    setSendEmail(e.target.checked);
  };

  const checkDisabled = (e: any) => {
    setDisabled(e.target.checked);
  };

  const deleteUser = () => {
    setWorking(true);
    const deleteUser = functions.httpsCallable("deleteUser");
    deleteUser({ uid: props.user.id })
      .then(function (result) {
        toast({
          title: "Success...",
          description: tx("asdfsdf0898wekkkas", "User deleted."),
          type: "success",
          time: 4000,
        });

        setWorking(false);

        props.doSearch();

        props.setOpen(false);
      })
      .catch((error) => {
        toast({
          title: "Error...",
          description: tx(
            "asdfsdf0898wekkkaserr",
            "Error deleting user." + error
          ),
          type: "error",
          time: 4000,
        });

        setWorking(false);
      });
  };

  const addNewUserMult = async (newUser: any) => {
    const createOrUpdateUser = functions.httpsCallable("createOrUpdateUser");

    try {
      await createOrUpdateUser({
        auth: {
          email: newUser.email.toLowerCase(),
          password: newUser.password,
          disabled: newUser.disabled,
        },
        personal: {
          expiry: newUser.expiry,
          firstName: newUser.firstName,
          lastName: newUser.lastName,
          language: newUser.language,
          followers: 0,
          type: newUser.userType,
          email: newUser.email.toLowerCase(),
          joined: Date.now(),
          multiple: true,
        },
        email: sendEmail,
      });
    } catch (error: any) {
      return error.code;
    }
    return "done";
  };

  const csv = require("csvtojson");

  const multiUser = async () => {
    const jsonObj = await csv().fromString(textRef.current?.value || "");

    for (let row of jsonObj) {
      row.userType = row.type;
      // let password = generator.generate({
      //   length: 8,
      //   numbers: true,
      // });

      // password = password.replace(/l/g, "Z").replace(/I/g, "r");

      const password = "abababa";

      row.disabled = false;
      row.password = password;
      row.followers = 0;
      row.expiry = row.expiry || Date.now() + 36000000;

      await addNewUserMult(row);
      // newBuyerMail(row.email, row.books.split("*"));
    }
  };

  const addNewUser = async () => {
    if (textRef.current?.value) {
      multiUser();
      return;
    }
    const createUser = functions.httpsCallable("createOrUpdateUser");
    setWorking(true);

    try {
      await createUser({
        auth: {
          email: email.toLowerCase(),
          password: password,
          disabled: disabled,
        },
        personal: {
          firstName,
          lastName,
          language,
          followers: 0,
          expiry: expiration,

          type: userType,
          email: email.toLowerCase(),
          joined: Date.now(),
        },
        email: sendEmail,
      });
    } catch (error) {
      toast({
        title: "Error...",
        description: tx("asdwerfsdf0898wekkkas", "Error adding user." + error),
        type: "error",
        time: 4000,
      });

      setWorking(false);

      return;
    }
    props.doSearch();
    toast({
      title: "Success...",
      description: tx("asdfsdf0898wekkkasdwas", "New user added."),
      type: "success",
      time: 4000,
    });

    setWorking(false);
  };

  // const newBuyerMail = async (email: string, type: any) => {
  //   const newBuyerMail = functions.httpsCallable("newBuyerMail");

  //   try {
  //     await newBuyerMail({
  //       email: email,
  //       type: userType,
  //     });
  //   } catch (error) {
  //     return error.code;
  //   }
  //   return "done";
  // };

  const sendAlert = async () => {
    const infoEmailToUser = functions.httpsCallable("infoEmailToUser");
    try {
      const res = await infoEmailToUser({
        email: email,
      });
    } catch (error) {
      console.log("error");
      return error;
    }
    return "done";
  };

  const changePassword = (e: any) => {
    setPassword(e.currentTarget.value);
  };

  const changeEmail = (e: any) => {
    setEmail(e.currentTarget.value);
  };

  const changeFirstName = (e: any) => {
    setFirstName(e.currentTarget.value);
  };

  const changeLastName = (e: any) => {
    setLastName(e.currentTarget.value);
  };

  const changeLanguage = async (e: any) => {
    e.persist();
    setLanguage(e.target.value);
  };

  const changeUserType = (event: any) => {
    setuserType(event.target.value);
  };

  const changeExpiration = (event: any, data: any) => {
    setExpiration(new Date(data.value).valueOf());
  };

  // const classes = useStyles();

  return (
    <React.Fragment>
      <Transition visible={props.open} unmountOnHide>
        <Modal
          closeOnDimmerClick={!working}
          closeIcon={!working}
          open={true}
          onClose={() => {
            props.setOpen(false);
          }}
        >
          <Modal.Header>
            {props.user?.email ? "Edit user" : "New user"}
          </Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Segment disabled={working}>
                <Form>
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      id="last_name"
                      label="Last name"
                      type="text"
                      value={lastName}
                      onChange={changeLastName}
                    />

                    <Form.Field
                      control={Input}
                      label="First name"
                      id="first_name"
                      type="text"
                      value={firstName}
                      onChange={changeFirstName}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      label={"E-mail"}
                      id="email_adress"
                      type="email"
                      value={email}
                      onChange={changeEmail}
                    />
                    <Form.Field
                      control={Select}
                      options={options}
                      label={{
                        children: "User Type",
                        htmlFor: "form-select-user-type",
                      }}
                      placeholder="User type..."
                      search
                      searchInput={{ id: "form-select-user-type" }}
                      value={userType}
                      onChange={changeUserType}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      label={"Password"}
                      id="password"
                      type="password"
                      value={password}
                      onChange={changePassword}
                    />
                    <Form.Field
                      control={Select}
                      options={languages}
                      label={{
                        children: "User Type",
                        htmlFor: "form-select-language",
                      }}
                      placeholder="Language..."
                      search
                      searchInput={{ id: "form-select-language" }}
                      value={language}
                      onChange={changeLanguage}
                    />
                  </Form.Group>

                  <Form.Field>
                    <label>Expiration</label>
                    <SemanticDatepicker
                      datePickerOnly={true}
                      onChange={changeExpiration}
                      value={new Date(expiration)}
                    />
                  </Form.Field>

                  <Form.Field
                    control={Checkbox}
                    label="Disabled"
                    checked={disabled}
                    onChange={checkDisabled}
                  />
                  <Form.Field
                    control={Checkbox}
                    label="Send e-mail"
                    checked={sendEmail}
                    onChange={checkEmail}
                  />
                </Form>
              </Segment>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions disabled={working}>
            <Button
              disabled={working}
              onClick={props.user?.email ? addNewUser : addNewUser}
            >
              {props.user?.email ? "Save" : "Create"}
            </Button>
            {props.user?.email ? (
              <React.Fragment>
                <Button disabled={working} onClick={deleteUser}>
                  Delete
                </Button>
              </React.Fragment>
            ) : null}
            {props.user?.email ? (
              <React.Fragment>
                <Button disabled={working} onClick={sendAlert}>
                  Send alert
                </Button>
              </React.Fragment>
            ) : null}
          </Modal.Actions>
        </Modal>
      </Transition>
      <SemanticToastContainer position="top-center" />
    </React.Fragment>
  );
}
