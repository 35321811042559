import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "Redux/reducers/_index";
import { setCreation, setCreationOne } from "Redux/reducers/reducer.creation";
import { setDic, setDicOne } from "Redux/reducers/reducer.dictionary";
import store from "Redux/store";
import { v4 as uuid } from "uuid";

import { createStyles, makeStyles } from "@material-ui/styles";
// import { gong } from './fulllist';

import { firestore } from "../firebaseUtil/firebaseUtil";
import { phaseBodyIn, phaseIn } from "../utils/utils";
import CreatorSentence from "./creator.sentence";
import { Segment, Grid, Icon, Menu, Dropdown, Button } from "semantic-ui-react";
import { setBackdropOpen } from "Redux/reducers/reducer.backdropOpen";
import FormDialog from "./creator.dialog";

var splitter = require("sbd");

const useStyles = makeStyles((theme: any) =>
  createStyles({
    creator: { background: "#eeeeff" },
    wrapper: { borderBottom: "1px solid grey", padding: "15px" },
    sentence: {
      display: "flex",
      width: "100%",
      flexWrap: "wrap",
      flexDirection: "column",
    },
    tadiv: {
      padding: "20px",
      width: "100%",
    },
    ta: {
      width: "100%",
      height: "300px",
    },
    cat: { marginBottom: "0px !important" },
    adminHeader: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    headerIcon: {
      width: "50px",
    },
    headerText: {
      flex: "1 1 auto",
    },
    topSegment: {
      marginBottom: "0px !important",
      borderBottom: "0px solid white !important",
      paddingTop: "8px !important",
      paddingBottom: "8px !important",
      height: "54px",
      display: "flex !important",
      alignItems: "center !important",
      borderBottomLeftRadius: "0px !important",
      borderBottomRightRadius: "0px !important",
    },
    cardIconTitle: {
      paddingTop: "0px",
      marginBottom: "0px",
      width: "100%",
      marginTop: "0px",
    },
    flexWrapper: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      minWidth: "1024px",
      position: "absolute",
      top: 55,
      bottom: 0,
      paddingTop: "10px",
      paddingBottom: "10px",
      background: "#f5f5f5",
    },
    buttons: {
      display: "flex",
      width: "100%",
      flex: "0 0 30px",
    },
    button: { flex: "1 1 auto", padding: "3px" },
    changed: {
      position: "absolute",
      right: 5,
      color: "red",
      fontWeight: "bold",
      fontSize: "13px",
    },
    controls: {
      flex: "0 0 250px",
      padding: "0px 15px 0px 15px",
      display: "flex",
      flexDirection: "column",
      marginRight: "8px",
    },
    editor: {
      flex: "1 1 auto",
      position: "absolute",
      marginLeft: "10px !important",
      marginRight: "10px !important",
      padding: "10px",
      overflow: "auto",
      marginTop: "0px !important",
    },
    catWrapper: {
      position: "relative",
      overflow: "hidden",
      flex: "1 1 auto",
      height: "100%",
      paddingBottom: "10px",
    },
    titleWrapper: { display: "flex", width: "100%", marginBottom: "3px" },
    title: {
      flex: "1 1 auto",
      fontWeight: "bold",
      marginBottom: "5px",
      fontSize: "16px",
      marginTop: "4px",
    },
  })
);

// const getItem = (str: string) => {
//   const temp = str.split("#");

//   const word = temp[0];
//   const baseWord = temp[0].split("+")[0].split("/")[0];
//   const rest = temp[1].split("*");
//   const id = rest[1];
//   const d = rest[0];

//   return { id, word, baseWord, num: 1, d, source: "any" };
// };

const prepDic = (gong: any) => {
  const obj: any = {};

  gong.forEach((item: any) => {
    if (item.id && item.word) {
      const hwords = item.word.split("+")[0].split("/");
      const thisItem = item;
      hwords.forEach((w: any) => {
        w = w.toLowerCase();
        if (obj[w]) {
          obj[w].push(thisItem.id);
        } else {
          obj[w] = [thisItem.id];
        }
      });
      obj[thisItem.id] = {
        ...thisItem,
        short: item.word.split("+")[0],
        head: item.word.split("+")[0].split("/")[0],
        withId: true,
      };
    } else {
      console.log(item);
    }
  });

  return obj;
};

export const expand = (e: string) => {
  return e
    .replace(/I'm/gi, "I am")
    .replace(/He's/gi, "He is")
    .replace(/She's/gi, "She is")
    .replace(/It's/gi, "It is")
    .replace(/'s/gi, " is")
    .replace(/'ll/gi, " will");
};

const createBaseWord = (word: string) => {
  word = word.replace(/[.?!$():;,"…—‘”“]/g, "").toLowerCase();

  return word;
};

const Creator = (props: any) => {
  const classes = useStyles();
  const [loaded, setLoaded] = useState(false);
  // const [lists, setLists] = useState([]);
  const [title, setTitle] = useState("");
  const [open, setOpen] = useState(false);
  const [myLists, setMyLists] = useState<any>([]);
  const [thisList, setThisList] = useState<any>({});
  const [currentId, setCurrentId] = useState("");
  const [dicList, setDicList] = useState<any>({});
  const [currentList, setCurrentList] = useState<any>({ id: "", texts: [] });
  const [currentText, setCurrentText] = useState<any>({
    text: "",
    title: "",
    id: "",
  });

  const currentUser = useSelector<RootState, any>((state) => state.currentUser);

  useEffect(() => {
    const pstart = async () => {
      store.dispatch(setBackdropOpen(true));
      const lists = await firestore.doc("/textlists/list").get();
      setMyLists(lists.data()?.texts || []);

      setThisList(lists.data()?.texts[0]);

      // setLists(lists.data()?.text || []);

      const thisList = await firestore
        .doc("/textlists/" + lists.data()?.texts[0].id)
        .get();

      setCurrentList({
        ...thisList?.data(),
      });

      const words = await firestore.collection("/vocab5").get();

      const result = words.docs.map((item) => {
        const t = item.data();
        return t;
      });

      console.log(result.filter((item) => item?.d?.indexOf("gr-") === 0));

      store.dispatch(setDic(prepDic(result)));

      const resultOne = words.docs.map((item) => {
        const t = { id: item.data().id, word: item.data().word };
        return t;
      });

      setDicList(resultOne);

      setTimeout(() => {
        setLoaded((c: any) => !c);
      }, 0);
      store.dispatch(setBackdropOpen(false));
    };
    if (currentUser) {
      pstart();
    }
  }, [currentUser]);

  const prep = (e: string) => {
    e = e.replace(/\n {1,}/g, "\n").replace(/ {1,}\n/g, "\n");
    e = e
      .replace(/\s{2,}/g, " ")
      .replace(/\n{2,}/g, "\n")
      .trim();

    const s = e.split("\n");

    const newText: any[] = [];
    const newObj: any = {};

    for (let index = 0; index < s.length; index++) {
      let item = s[index];
      if (item.substr(0, 1) === ":") {
        newText.push(item);
        continue;
      }
      const lineWords = item.split(/[ —]/);

      lineWords.forEach((word, wordIndex) => {
        let appear = "";
        let origId = "";
        let grId = "";

        let parts = word.split("{");

        if (parts.length > 1) {
          appear = parts[1].replace("}", "");
          word = parts[0];
        }

        let idParts = word.split("[");

        if (idParts.length > 1) {
          let tempId = idParts[1].replace("]", "").split("*");
          origId = tempId[0];
          grId = tempId[1] || "";
          word = idParts[0];
        }

        const baseWord = createBaseWord(word);

        newObj[index + "-" + wordIndex] = {
          word,
          appear,
          origId,
          baseWord,
          grId,
        };
      });
      newText.push(lineWords);
    }

    return { newText, newObj };
  };

  const sentences = useSelector<RootState, any>(
    (state) => state.creation.sentences
  );

  const makeUnique = (arr: any[]) => {
    let tempText = "";
    const itext = [];

    for (let g = 0; g < arr.length; g++) {
      let line = arr[g];
      if (line.indexOf("{A1}") > -1) {
        continue;
      }
      if (line.indexOf("{A2}") > -1) {
        continue;
      }
      if (line.indexOf("{A3}") > -1) {
        continue;
      }
      if (line.indexOf("{A4}") > -1) {
        continue;
      }
      if (line.indexOf("{B1}") > -1) {
        continue;
      }
      if (line.indexOf("{B2}") > -1) {
        continue;
      }
      const tempLine = line.replace(/\[.*?\]/, "");

      if (tempText.indexOf("|" + tempLine + "|") === -1) {
        tempText = tempText + "|" + tempLine + "|";
        if (itext.length < 50) {
          itext.push(line);
        }
      }
    }

    return itext;
  };

  useEffect(() => {
    phaseIn();
    phaseBodyIn();
  }, []);

  useEffect(() => {
    const basic = prep(`It{It's} is{-} hard to get into college these days.`);

    store.dispatch(setCreation({ sentences: basic.newText, ...basic.newObj }));
  }, []);

  const mergeWord = useCallback((sIndex: number, wIndex: number) => {
    if (store.getState().creation[sIndex + "-" + (wIndex + 1)]) {
      const merged =
        store.getState().creation[sIndex + "-" + wIndex].word +
        " " +
        store.getState().creation[sIndex + "-" + (wIndex + 1)].word;

      store.dispatch(
        setCreationOne({
          id: sIndex + "-" + wIndex,
          value: {
            origId: "",
            word: merged,
            appear: "",
            baseWord: merged.replace(/[".?!$():;,‘…]/g, "").toLowerCase(),
          },
        })
      );
      store.dispatch(
        setCreationOne({
          id: sIndex + "-" + (wIndex + 1),
          value: { origId: "", word: "", appear: "", baseWord: "", head: "" },
        })
      );
    }
  }, []);

  const getSentence = useCallback((sindex: number, windex: number) => {
    const tSent = store
      .getState()
      .creation.sentences[sindex].map((item: any, ind: number) => {
        const w = (windex === ind ? "***" : "") + item.split("[")[0];

        return w;
      });

    const splitted = splitter.sentences(tSent.join(" ").trim());

    let found = splitted.find((item: string) => item.includes("***"));

    let origSentence = found.replace("***", "");
    return origSentence;
  }, []);

  const saveAll = useCallback(
    (temp: boolean = false) => {
      const s = store.getState().creation.sentences;
      const wordData = store.getState().creation;
      let newText = "";
      const addedObj: any = {};
      const wordList = store.getState().dic;

      s.forEach((sentence: any, index: number) => {
        if (typeof sentence === "string") {
          newText = newText + sentence + "\n";
        } else {
          sentence.forEach((word: any, wIndex: number) => {
            const thisWord = wordData[index + "-" + wIndex];
            const d = wordList[thisWord.id]?.d || "";
            newText =
              newText +
              thisWord.word +
              "[" +
              thisWord.id +
              (d.indexOf("gr-") === 0 ? "*" + d : "") +
              "]" +
              (thisWord.appear !== "undefined"
                ? "{" + thisWord.appear + "}"
                : "") +
              " ";

            const sen =
              getSentence(index, wIndex) + "[" + uuid() + "]{" + title + "}";

            if (addedObj[thisWord.id]) {
              addedObj[thisWord.id].push(sen);
            } else {
              addedObj[thisWord.id] = [sen];
            }
          });
          newText = newText.trim() + "\n";
        }
      });

      let repeat = "";
      let newWords: any[] = [];
      const dic = store.getState().dic;
      const words = Object.keys(store.getState().dic);

      words.forEach((word: any) => {
        if (
          addedObj[word] &&
          dic[word].withId &&
          (addedObj[dic[word].id] || dic[word].source === "new") &&
          !repeat.includes("|" + dic[word].id + "|")
        ) {
          repeat = repeat + "|" + dic[word].id + "|";

          newWords.push({
            ...dic[word],
            s: makeUnique([
              ...(dic[word].source === "new" ? [] : [...(dic[word].s || [])]),
              ...addedObj[word],
            ]),
          });
        }
      });

      newWords.forEach((oneWord: any) => {
        if (oneWord.source === "new") {
          oneWord.source = title;
          oneWord.date = 20000;
        }

        delete oneWord.element;
        store.dispatch(setDicOne({ id: oneWord.id, value: { ...oneWord } }));
        if (oneWord.withId) {
          delete oneWord.withId;
        }

        if (temp) {
          oneWord.temp = true;
        } else {
          oneWord.temp = false;
        }

        if (oneWord.id > "A4") {
          oneWord.newtype = true;
        }

        firestore.doc("/vocab5/" + oneWord.id).set(oneWord, { merge: true });
      });

      firestore
        .doc("/texts/" + currentText.id)
        .set({ text: newText }, { merge: true });
    },
    [getSentence, title, currentText.id]
  );

  // const fillVocab = async () => {
  //   for (let g = 0; g < gong.length; g++) {
  //     const thisItem = getItem(gong[g]);

  //     await firestore.doc("/vocab/" + thisItem.id).set({
  //       ...thisItem,
  //       source: thisItem.id.substr(0, 2),
  //     });
  //     console.log(thisItem);
  //   }
  // };

  // const copySentences = async () => {
  //   for (let g = 0; g < gong.length; g++) {
  //     const thisItem = getItem(gong[g]);

  //     await firestore.doc("/vocab/" + thisItem.id).set({
  //       ...thisItem,
  //       source: thisItem.id.substr(0, 2),
  //     });
  //     console.log(thisItem);
  //   }
  // };

  const changeText = async (id: any) => {
    const newText = await firestore.doc("/texts/" + id).get();
    const gText = newText.data()?.text.replace(/\|/g, "\n") || "";
    setCurrentText({
      ...(newText?.data() || {}),
      text: gText,
    });
    setTitle(newText.data()?.id || "");
    const ta = document.getElementById("xta") as HTMLTextAreaElement;
    if (ta) {
      ta.value = gText;
    }
    const basic = prep(gText);
    store.dispatch(setCreation({ sentences: [] }));
    setTimeout(() => {
      store.dispatch(
        setCreation({ sentences: basic.newText, ...basic.newObj })
      );
    }, 0);
  };

  const openDlg = (e: any) => {
    e.persist();

    console.log(e);
    setCurrentId(e.currentTarget.innerHTML);
    setOpen(true);
  };

  const openDlgFix = () => {
    setCurrentId("");
    setOpen(true);
  };

  const changeList = async (item: any) => {
    const thisList = await firestore.doc("/textlists/" + item.id).get();

    setCurrentList({
      ...thisList?.data(),
    });

    setThisList(item);
    setCurrentText({ text: "", id: "", title: "" });

    store.dispatch(setCreationOne({ id: sentences, value: { newText: [] } }));
  };

  return (
    <React.Fragment>
      <Segment className={classes.topSegment} inverted>
        <Grid style={{ width: "100%" }}>
          <Grid.Row>
            <Grid.Column width={16}>
              <div className={classes.adminHeader}>
                <div className={classes.headerIcon}>
                  <Icon name="translate" />
                </div>
                <div className={classes.headerText}>
                  <h4 className={classes.cardIconTitle}>Glossarizer</h4>
                </div>
                <Menu inverted style={{ margin: "0px" }}>
                  <Button onClick={openDlgFix}>Words</Button>
                  <Button onClick={() => saveAll(true)}>Save</Button>
                  <Button onClick={() => saveAll(false)}>Publish</Button>
                  <Dropdown
                    floating
                    direction="left"
                    item
                    text={thisList.title || "Choose a category..."}
                  >
                    <Dropdown.Menu>
                      {myLists.map((item: any) => (
                        <Dropdown.Item
                          key={item.id}
                          active={item.id === thisList.id}
                          onClick={() => changeList(item)}
                        >
                          {item.title}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown
                    floating
                    scrolling
                    direction="left"
                    item
                    text={currentText.title || "Choose a file..."}
                  >
                    <Dropdown.Menu>
                      {(currentList?.texts || []).map((item: any) => (
                        <Dropdown.Item
                          key={item.id}
                          active={item.id === currentText.id}
                          onClick={() => {
                            changeText(item.id);
                          }}
                        >
                          {item.title}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Menu>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <div className={classes.flexWrapper}>
        <Segment
          className={classes.editor}
          color="olive"
          style={{
            overflow: "hidden",
          }}
        >
          <div className={classes.titleWrapper}>
            <div className={classes.title}>Words</div>
          </div>
          <Segment
            style={{
              height: "calc(100% - 25px)",
              overflow: "auto",
              marginTop: "0px",
              background: "white",
              marginRight: "0px !important",
            }}
            basic
          >
            {sentences?.map((item: any, index: number) =>
              typeof item === "string" ? (
                <div key={index} />
              ) : (
                <div className={classes.wrapper} key={title + index}>
                  <div className={classes.sentence}>
                    <CreatorSentence
                      title={title}
                      scount={index}
                      loaded={loaded}
                      sentence={item}
                      mergeWord={mergeWord}
                      getSentence={getSentence}
                      openDlg={openDlg}
                    />
                  </div>
                </div>
              )
            )}
          </Segment>
        </Segment>
      </div>
      <FormDialog
        dic={dicList}
        id={currentId}
        setId={setCurrentId}
        open={open}
        setOpen={setOpen}
      />
    </React.Fragment>
  );
};

export default Creator;
