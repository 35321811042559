import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: string = "";

const ccodeGlobalSlice = createSlice({
  initialState,
  name: "ccodeGlobal",
  reducers: {
    setCcodeGlobal: (state: string, action: PayloadAction<string>) => {
      return action.payload;
    },
  },
});

export const { setCcodeGlobal } = ccodeGlobalSlice.actions;

export default ccodeGlobalSlice.reducer;
