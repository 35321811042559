import "react-quill/dist/quill.snow.css"; // ES6
import "./account.emailEditor.css"; // ES6

import React, { useRef } from "react";
import ReactQuill from "react-quill"; // ES6
import { Button, Modal, Transition } from "semantic-ui-react";

import { createStyles, makeStyles } from "@material-ui/styles";

import { copyFormatted } from "./account.emails";

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    text: {},
  })
);

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

const EmailCustomizer = (props: any) => {
  //eslint-disable-next-line
  const classes = useStyles();

  const quillRef = useRef<any>(null);

  const exportHtml = () => {
    console.log(quillRef);
    copyFormatted(quillRef.current?.getEditor().root.innerHTML);
  };

  // const sendEmail = () => {
  //   window.open(
  //     "mailto:someone@site.com?body=" +
  //       encodeURIComponent(quillRef.current?.getEditor().root.innerHTML),
  //     "email"
  //   );
  // };

  return (
    <React.Fragment>
      <Transition visible={props.open} animation="fade" duration={250}>
        <Modal
          size="small"
          centered={false}
          open={props.open}
          closeIcon
          onClose={() => props.setOpen(false)}
        >
          <Modal.Header style={{ padding: "9px 15px" }}>
            E-mail
            {/* <Button floated="right" size="tiny">
              HTML
            </Button>
            <Button floated="right" size="tiny">
              Text
            </Button> */}
          </Modal.Header>
          <Modal.Content
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "0px",
              height: "450px",
            }}
          >
            <div id="sccont" className="text-editor" style={{ height: "100%" }}>
              <ReactQuill
                ref={quillRef}
                theme="snow"
                modules={modules}
                formats={formats}
                defaultValue={props.email?.text}
              ></ReactQuill>
            </div>
          </Modal.Content>
          <Modal.Actions>
            {/* <Button onClick={sendEmail}>Send</Button> */}
            <Button onClick={exportHtml}>Copy E-mail</Button>
          </Modal.Actions>
        </Modal>
      </Transition>
    </React.Fragment>
  );
};

export default EmailCustomizer;
