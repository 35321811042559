import React, { useEffect, useState } from "react";
import { Form, Segment, Message, Image, Input } from "semantic-ui-react";
// import { makeStyles, createStyles } from "@material-ui/styles";
import { useDropzone } from "react-dropzone";
import { cloudStorage } from "../firebaseUtil/firebaseUtil";

// export const useStyles = makeStyles((theme: any) =>
//   createStyles({
//     segment: { padding: "0px !important", marginTop: "0px !important" },
//   })
// );

const CorporateTab = (props: any) => {
  // const classes = useStyles();

  const { corporate, ccode, logoFiles, setLogoFiles } = props;

  const [picUrl, setPicUrl] = useState("");

  const { getRootProps, getInputProps } = useDropzone({
    accept: { "image/*": [".png", ".gif", ".jpeg", ".jpg"] },
    onDrop: (acceptedFiles) => {
      setLogoFiles(
        acceptedFiles.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      logoFiles.forEach((file: any) => URL.revokeObjectURL(file.preview));
    },
    [logoFiles]
  );

  useEffect(() => {
    const getPic = async () => {
      setPicUrl(
        logoFiles[0]?.preview ||
          (await getLogoUrl(ccode)) ||
          "/images/imageph.png"
      );
    };

    getPic();
  }, [ccode, logoFiles]);

  // const changeOnScreen = (e: any, b: any) => {
  //   setCustomize({
  //     ...customize,
  //     corporateteacher: { ...corporate, onScreen: b.checked },
  //   });
  // };

  // const changeInMenu = (e: any, b: any) => {
  //   setCustomize({
  //     ...customize,
  //     teacher: { ...teacher, inMenu: b.checked },
  //   });
  // };

  // const setWebpage = () => {
  //   setCustomize({
  //     ...customize,
  //     teacher: { ...teacher, linkType: "webpage" },
  //   });
  // };

  // const setInternal = () => {
  //   setCustomize({
  //     ...customize,
  //     teacher: { ...teacher, linkType: "internal" },
  //   });
  // };

  return (
    <React.Fragment>
      <Form style={{ height: "calc(100% - 55px)", position: "relative" }}>
        <div
          style={{
            overflowY: "auto",
            top: "0px",
            bottom: "0px",
            left: "0px",
            right: "0px",
          }}
        >
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <Message icon style={{ marginBottom: "8px" }}>
              <Image spaced="right" circular size="mini" src={picUrl} />
              <Message.Content>
                <Message.Header>Your logo</Message.Header>
                Drop the logo file here.
              </Message.Content>
            </Message>
          </div>
          <Form.Field>
            <label>App Name</label>
            <Input
              type="text"
              id="corporateAppName"
              placeholder="NetEnglish"
              defaultValue={corporate.appName}
              onChange={() => {}}
            />
          </Form.Field>
          <Form.Field>
            <label>Copyright Text</label>
            <Input
              type="text"
              id="corporateCopyright"
              placeholder="My Company. All Rights Reserved."
              defaultValue={corporate.copyright}
              onChange={() => {}}
            />
          </Form.Field>
          <Form.Field>
            <label>Terms and Conditions</label>
            <Input
              type="url"
              id="corporateTerms"
              label="https://"
              defaultValue={corporate.terms}
              onChange={() => {}}
            />
          </Form.Field>
          <Form.Field>
            <label>Privacy Policy</label>
            <Input
              type="url"
              id="corporatePrivacy"
              label="https://"
              defaultValue={corporate.privacy}
              onChange={() => {}}
            />
          </Form.Field>
          <Form.Field>
            <label>About Us</label>
            <Input
              type="url"
              id="corporateAboutUs"
              label="https://"
              defaultValue={corporate.aboutUs}
              onChange={() => {}}
            />
          </Form.Field>
          <Form.Field>
            <label>Contacts</label>
            <Input
              type="url"
              id="corporateContacts"
              label="https://"
              defaultValue={corporate.contacts}
              onChange={() => {}}
            />
          </Form.Field>
          <Form.Field>
            <label>Buy Link</label>
            <Input
              type="url"
              id="corporateBuy"
              label="https://"
              defaultValue={corporate.buy}
              onChange={() => {}}
            />
          </Form.Field>
        </div>
      </Form>
    </React.Fragment>
  );
};

export default CorporateTab;

const getLogoUrl = async (ccode: string) => {
  let url;

  try {
    url = await cloudStorage
      .refFromURL(
        "gs://netenglishm.appspot.com/profilepics/" + ccode + "-logo.png"
      )
      .getDownloadURL();

    return url;
  } catch (error) {
    return "";
  }
};
