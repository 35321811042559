import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { loadAppTranslation } from "./saver.loadTranslation";
import store from "Redux/store";
import { setAppTransParts } from "Redux/reducers/reducer.appTransParts";
import { phaseBodyIn } from "./utils";
import {
  setProceedMenu,
  setProceedAction,
} from "Redux/reducers/reducer.proceed";

export const useLoadAppTrans = () => {
  const [ready, setReady] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (ready) {
      phaseBodyIn();
      return;
    }

    const startApp = async () => {
      const language =
        location.search?.match(/lang=[^&]*/)?.[0].replace("lang=", "") ||
        localStorage.getItem("language") ||
        "en";

      localStorage.setItem("language", language);
      const appTranslation = await loadAppTranslation(language);

      store.dispatch(setAppTransParts(appTranslation));
      setTimeout(() => {
        setReady(true);
      }, 0);
    };
    store.dispatch(setProceedMenu({ visibleMenu: false }));
    store.dispatch(setProceedAction({ visibleAction: false }));

    startApp();
  }, [location.search, ready]);

  return 0;
};
