import React, { useEffect, useState } from "react";
import { Form, Segment, Message, Image, Radio, Input } from "semantic-ui-react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useDropzone } from "react-dropzone";
import { cloudStorage } from "../firebaseUtil/firebaseUtil";

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    segment: { padding: "0px !important", marginTop: "0px !important" },
  })
);

const TeacherTab = (props: any) => {
  const classes = useStyles();

  const { customize, teacher, setCustomize, ccode, files, setFiles, setSaved } =
    props;

  const [picUrl, setPicUrl] = useState("");

  const { getRootProps, getInputProps } = useDropzone({
    accept: { "image/*": [".png", ".gif", ".jpeg", ".jpg"] },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file: any) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  useEffect(() => {
    const getPic = async () => {
      setPicUrl(
        files[0]?.preview ||
          (await getProfileUrl(ccode)) ||
          "/images/imageph.png"
      );
    };

    getPic();
  }, [ccode, files]);

  const changeOnScreen = (e: any, b: any) => {
    setCustomize({
      ...customize,
      teacher: { ...teacher, onScreen: b.checked },
    });
    setSaved(false);
  };

  const changeInMenu = (e: any, b: any) => {
    setCustomize({
      ...customize,
      teacher: { ...teacher, inMenu: b.checked },
    });
    setSaved(false);
  };

  const setWebpage = () => {
    setCustomize({
      ...customize,
      teacher: { ...teacher, linkType: "webpage" },
    });
    setSaved(false);
  };

  const setInternal = () => {
    setCustomize({
      ...customize,
      teacher: { ...teacher, linkType: "internal" },
    });
    setSaved(false);
  };

  return (
    <React.Fragment>
      <Form
        style={{
          position: "relative",
          flexDirection: "column",
          height: "calc(100% - 55px)",
        }}
      >
        <Form.Group widths="equal">
          <Form.Checkbox
            checked={teacher.onScreen || false}
            onChange={changeOnScreen}
            label="Profile link on the screen"
          />
          <Form.Checkbox
            checked={teacher.inMenu || false}
            onChange={changeInMenu}
            label="Profile link in the menu"
          />
        </Form.Group>

        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <Message icon style={{ marginBottom: "8px" }}>
            <Image spaced="right" circular size="mini" src={picUrl} />
            <Message.Content>
              <Message.Header>Your picture</Message.Header>
              Drop your picture here.
            </Message.Content>
          </Message>
        </div>
        <Form.Field>
          <Radio
            label="Links to webpage"
            name="radioGroup"
            value="this"
            checked={!teacher.linkType || teacher.linkType === "webpage"}
            onChange={setWebpage}
          />
        </Form.Field>
        <Form.Field>
          <Input
            type="url"
            id="teacherMyUrl"
            label="https://"
            defaultValue={teacher.myUrl}
            disabled={teacher.linkType === "internal"}
            onChange={() => {}}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            label="Use internal profile"
            name="radioGroup"
            value="that"
            checked={teacher.linkType === "internal"}
            onChange={setInternal}
          />
        </Form.Field>
        <Segment
          basic
          className={classes.segment}
          disabled={teacher.linkType !== "internal"}
        >
          <Form.Field width={16}>
            <label>Name</label>
            <input
              id="teacherName"
              type="text"
              defaultValue={teacher.name}
              onChange={() => {}}
            />
          </Form.Field>
          <Form.TextArea
            width={16}
            id="teacherDescription"
            defaultValue={teacher.description}
            label="Description"
          />
          <Form.Group widths="equal">
            <Form.Field>
              <label>E-mail</label>
              <input
                id="teacherEmail"
                type="email"
                defaultValue={teacher.email}
              ></input>
            </Form.Field>
            <Form.Field>
              <label>Phone</label>
              <input
                id="teacherPhone"
                type="email"
                defaultValue={teacher.phone}
              ></input>
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Skype</label>
              <input
                id="teacherSkype"
                type="email"
                defaultValue={teacher.skype}
              ></input>
            </Form.Field>
            <Form.Field>
              <label>Facebook</label>
              <input
                id="teacherFacebook"
                type="email"
                defaultValue={teacher.facebook}
              ></input>
            </Form.Field>
          </Form.Group>
        </Segment>
      </Form>
    </React.Fragment>
  );
};

export default TeacherTab;

const getProfileUrl = async (ccode: string) => {
  let url;

  try {
    url = await cloudStorage
      .refFromURL("gs://netenglishm.appspot.com/profilepics/" + ccode + ".png")
      .getDownloadURL();

    return url;
  } catch (error) {
    return "";
  }
};
