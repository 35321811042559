import React from "react";
import { Dimmer, Loader, Transition } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { RootState } from "../Redux/reducers/_index";
import store from "Redux/store";
import { setBackdropOpen } from "Redux/reducers/reducer.backdropOpen";
import { makeStyles, createStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    text: {},
  })
);

const DimMe = (props: any) => {
  //eslint-disable-next-line
  const classes = useStyles();

  const backdrop = useSelector<RootState, any>((state) => state.backdropOpen);

  return (
    <React.Fragment>
      <Transition
        visible={backdrop.open}
        animation="fade"
        duration={200}
        unmountOnHide
      >
        <Dimmer
          active={true}
          onClickOutside={() => store.dispatch(setBackdropOpen(false))}
          page
        >
          <Loader>Processing...</Loader>
        </Dimmer>
      </Transition>
    </React.Fragment>
  );
};

export default DimMe;
