import { readXMLLesson } from "./readxml";
import { FreeData } from "firebaseUtil/firebaseUtil";
import store from "Redux/store";
import { firestore } from "../firebaseUtil/firebaseUtil";
import { v4 as uuid } from "uuid";

export const saveFullLessonAsync = async (
  type: string,
  name: string,
  content: string,
  published: boolean = false
) => {
  const updated = Date.now();

  if (!content) {
    content = `<lessondata>
    <number>A1</number>
    <seq>${name}</seq>
    <summary tid="${uuid()}">...</summary>
    <title tid="${uuid()}">...</title>
    <length>0</length>
  </lessondata>`;
  }

  const result = readXMLLesson(content, true);

  if (result.getElementsByTagName("parsererror").length > 0) {
    return true;
  }

  await saveNewLessonDoc(name, published, content, updated, type);

  const lessonProperties = getLessonProperties(content, name);
  const newLessonList = updateLessonList(lessonProperties, name, updated);

  await saveLessonList(newLessonList, type);

  return false;
};

export const saveNewLessonDoc = async (
  name: string,
  published: boolean,
  content: string,
  updated: number,
  type: string
) => {
  const newLessonRef = firestore.doc(`originals/${name}`);

  const newLesson = await newLessonRef.get();

  if (!newLesson.exists) {
    await newLessonRef.set({
      updated,
      published,
      xml: content,
      type,
      seq: name,
    });
  } else {
    await newLessonRef.update({
      updated,
      published,
      xml: content,
      type,
      seq: name,
    });
  }
};

export const getLessonProperties = (content: string, name: string) => {
  const xmlDoc = readXMLLesson(content, true);
  const lessonData = xmlDoc.getElementsByTagName("lessondata")[0];

  return {
    number: lessonData.getElementsByTagName("number")[0].innerHTML,
    seq: name,
    summary: lessonData.getElementsByTagName("summary")[0].innerHTML,
    title: lessonData.getElementsByTagName("title")[0].innerHTML,
  };
};

export const getTranslatedLessonProperties = (
  content: string,
  translation: FreeData
) => {
  const xmlDoc = readXMLLesson(content, true);
  const lessonData = xmlDoc.getElementsByTagName("lessondata")[0];

  return {
    number: lessonData.getElementsByTagName("number")[0].innerHTML,
    seq: lessonData.getElementsByTagName("seq")[0].innerHTML,
    summary:
      translation[
        lessonData.getElementsByTagName("summary")[0].getAttribute("tid") ||
          "gigigig"
      ] || "",
    title:
      translation[
        lessonData.getElementsByTagName("title")[0].getAttribute("tid") ||
          "gigigig"
      ] || "",
  };
};

export const updateLessonList = (
  lessonProperties: FreeData,
  name: string,
  updated: number
) => {
  let newLessonList: FreeData[] = [];
  let origLessonList = [...store.getState().lessonList];
  const thisLesson = origLessonList.find((item) => item.seq === name);

  if (origLessonList.length > 0 && origLessonList[0].seq === "") {
    origLessonList.shift();
  }

  if (thisLesson) {
    newLessonList = origLessonList.map((item) => {
      if (item.seq === name) {
        return { ...item, ...lessonProperties, updated };
      } else {
        return item;
      }
    });
  } else {
    newLessonList = [...origLessonList, { ...lessonProperties, updated }].sort(
      compare
    );
  }

  return newLessonList;
};

export const saveLessonList = async (
  newLessonList: FreeData[],
  type: string
) => {
  const lessonListRef = firestore.doc(`originalslists/${type}`);

  lessonListRef.set({
    items: newLessonList,
  });
};

export function compare(a: any, b: any) {
  if (a.seq < b.seq) {
    return -1;
  }
  if (a.seq > b.seq) {
    return 1;
  }
  return 0;
}
