import TermsDialog from "footer/footer.terms";
import Legal from "legal/legal";
import React, { useState } from "react";
// import { useLoadAppTrans } from "../utils/useLoadAppTrans";
import { useSelector } from "react-redux";

import { createStyles, makeStyles } from "@material-ui/styles";

import { RootState } from "../Redux/reducers/_index";

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    footer: {
      width: "100%",
      paddingTop: "20px",
      borderTop: "1px solid lightgrey",
      fontFamily: "var(--font-normal)",
      fontSize: "16px",
      lineHeight: "145%",
      color: "grey",
      bottom: "15px",
    },
    bullets: { width: "100%", textAlign: "center" },
    copyright: {
      marginTop: "5px",
      textAlign: "center",
      fontFamily: "var(--font-normal)",
    },
    bullet: { cursor: "pointer", fontFamily: "var(--font-medium)" },
    barion: { textAlign: "center", marginTop: "8px" },
    constrains: { maxWidth: "550px", display: "inline-block" },
  })
);

const Footer = (props: any) => {
  const classes = useStyles();
  const [termsOpen, setTermsOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [termsContent, setTermsContent] = useState<any>(
    <React.Fragment></React.Fragment>
  );

  const { tx } = props;

  // eslint-disable-next-line
  const appTransParts = useSelector<RootState, any>(
    (state) => state.appTransParts
  );

  const closeDialog = () => {
    setTermsOpen(false);
  };

  const openDialog = () => {
    setTermsOpen(true);
  };

  const openPrivacy = () => {
    setTermsContent(<Legal language={props.language} doc="privacy" />);
    setTermsOpen(true);
    setTitle(tx("oqlasdasd", "Privacy Policy"));
  };

  const openTerms = () => {
    setTermsContent(<Legal language={props.language} doc="terms" />);
    setTermsOpen(true);
    setTitle(tx("oqlasdasd1", "Terms and Conditions"));
  };

  const openCookies = () => {
    setTermsContent(<Legal language={props.language} doc="cookies" />);
    setTermsOpen(true);
    setTitle(tx("oqlasdasd2", "Cookie Settings"));
  };

  const openImpress = () => {
    setTermsContent(<Legal language={props.language} doc="impress" />);
    setTermsOpen(true);
    setTitle(tx("oqlasdasd3", "About Us"));
  };

  const openSubscriptionTerms = () => {
    setTermsContent(<Legal language={props.language} doc="subscription" />);
    setTermsOpen(true);
    setTitle(tx("oqlasdasd4", "Subscription Policy"));
  };

  const openMoreInfo = () => {
    window.open("https://wizzwords.net/?lang=" + props.language, "_blank");
  };

  return (
    <React.Fragment>
      <div className={classes.footer}>
        <div className={classes.bullets}>
          <div className={classes.constrains}>
            <span className={classes.bullet} onClick={openPrivacy}>
              {tx("japo2", "Privacy Policy")}
            </span>{" "}
            •{" "}
            <span className={classes.bullet} onClick={openTerms}>
              {tx("japo4", "Terms and Conditions")}
            </span>{" "}
            •{" "}
            <span className={classes.bullet} onClick={openSubscriptionTerms}>
              {tx("japo4a", "Subscription Terms")}
            </span>{" "}
            •{" "}
            <span className={classes.bullet} onClick={openCookies}>
              {tx("japo4acookie", "Cookies")}
            </span>{" "}
            •{" "}
            <span className={classes.bullet} onClick={openImpress}>
              {tx("japo4acookies", "About us")}
            </span>{" "}
            •{" "}
            <span className={classes.bullet} onClick={openMoreInfo}>
              {tx("japo5", "More info")}
            </span>
          </div>
        </div>
        <div className={classes.barion}>
          <img src="/images/cardlogos1.png" alt="barion" />
        </div>
        <div className={classes.copyright}>
          © 2022 NetEnglish Solutions OÜ Hungarian PE.
          <br />
          {tx("japo6", "All Rights Reserved.")}
        </div>
        <TermsDialog
          open={termsOpen}
          closeDialog={closeDialog}
          openDialog={openDialog}
          help={termsContent}
          title={title}
          tx={tx}
        />
      </div>
    </React.Fragment>
  );
};

export default Footer;
