import React, { useEffect, useRef, useState } from "react";
import { Icon, Segment, Grid, Button, Tab } from "semantic-ui-react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { firestore, cloudStorage } from "../firebaseUtil/firebaseUtil";
import { useSelector } from "react-redux";
import { RootState } from "../Redux/reducers/_index";
import ColorTab from "./customize.colors";
import TeacherTab from "./customize.teacher";
import CorporateTab from "./customize.corporate";

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    buttonSegment: {
      padding: "5px !important",
      marginTop: "5px !important",
      width: "fit-content",
    },
    tab: {
      flex: "1 1 auto",
      overflowY: "auto",
      overflowX: "hidden",
      paddingLeft: "2px",
      paddingRight: "2px",
    },
    color: {
      width: "100%",
      border: "1px solid lightgrey",
      background: "blue",
      borderRadius: ".28571429rem",
      height: "38px",
    },
    listWrapper: {
      position: "relative",
      overflow: "hidden",
      flex: "1 1 auto",
    },
    adminHeader: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    headerIcon: {
      width: "50px",
    },
    headerText: {
      flex: "1 1 auto",
    },
    topSegment: {
      marginBottom: "0px !important",
      borderBottom: "0px solid white !important",
      paddingTop: "8px !important",
      paddingBottom: "8px !important",
      height: "54px",
      display: "flex !important",
      alignItems: "center !important",
      borderBottomLeftRadius: "0px !important",
      borderBottomRightRadius: "0px !important",
    },
    cardIconTitle: {
      paddingTop: "0px",
      marginBottom: "0px",
      width: "100%",
      marginTop: "0px",
    },
    flexWrapper: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      minWidth: "1024px",
      position: "absolute",
      top: 0,
      bottom: 0,
      paddingTop: "10px",
      paddingBottom: "10px",
      background: "#f5f5f5",
    },
    controls: {
      flex: "0 0 400px",
      padding: "0px 0px 0px 15px",
      display: "flex",
      flexDirection: "column",
      marginRight: "0px",
    },
    preview: {
      flex: "1 1 auto",
      padding: "0px 0px 0px 15px",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      marginRight: "0px",
    },
    titlePlus: { flex: "0 0 auto" },
    custom: {
      flex: "0 0 450px",
      padding: "0px 0px 0px 15px",
      display: "flex",
      flexDirection: "column",
      marginRight: "15px",
      height: "100%",
      minWidth: "450px",
    },
    cat: {
      marginBottom: "0px !important",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      width: "100%",
      overflowY: "hidden",
      overflowX: "hidden",
    },
    wrapper: {
      marginTop: "12px",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      overflow: "auto",
      marginBottom: "0px !important",
    },
    titleWrapper: { display: "flex", width: "100%", marginBottom: "3px" },
    title: {
      flex: "1 1 auto",
      fontWeight: "bold",
      marginBottom: "5px",
      fontSize: "16px",
      marginTop: "4px",
    },
    iframe: {
      width: "100%",
      border: "none",
      height: "calc(var(--vh) - 150px)",
    },
    iframeWrapper: { border: "1px solid lightgrey", lineHeight: 0 },
    iframeWrapperMobile: {
      border: "1px solid lightgrey",
      width: "375px",
      textAlign: "center",
      alignSelf: "center",
    },
  })
);

const Customize = (props: any) => {
  const classes = useStyles();
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const [files, setFiles] = useState<any>([]);
  const [logoFiles, setLogoFiles] = useState<any>([]);

  const [saved, setSaved] = useState(true);

  const [pickers, setPickers] = useState({
    appBarBackground: false,
    appBarColor: false,
  });

  const [mobile, setMobile] = useState(false);

  const currentUser = useSelector<RootState, any>((state) => state.currentUser);

  const ccodeGlobal = useSelector<RootState, any>((state) => state.ccodeGlobal);

  const [customize, setCustomize] = useState<any>({});

  useEffect(() => {
    if (currentUser && ccodeGlobal) {
      firestore
        .doc(`/companies/${ccodeGlobal}/data/customize`)
        .get()
        .then((res) => {
          if (iframeRef.current) {
            if (!iframeRef.current.src) {
              iframeRef.current.src =
                process.env.NODE_ENV === "production"
                  ? "https://app.netenglish.com"
                  : "https://app.netenglish.com";
            }
          }
          firestore
            .doc(`/companies/${ccodeGlobal}/data/customizeDev`)
            .set(res.data() || {}, { merge: true });
          setCustomize(res.data() || {});
          // firestore
          //   .doc(`/companies/${ccodeGlobal}/data/customizeDev`)
          //   .onSnapshot(async (snapShot) => {
          //     setCustomize(snapShot.data());
          //     console.log(iframeRef.current?.src);
          //     if (iframeRef.current) {
          //       if (!iframeRef.current.src) {
          //         iframeRef.current.src = "https://app.netenglish.com";
          //       }
          //     }
          //   });
        });
    }
  }, [currentUser, ccodeGlobal]);

  useEffect(() => {
    if (customize.colors && ccodeGlobal && !saved) {
      firestore
        .doc(`/companies/${ccodeGlobal}/data/customizeDev`)
        .set(customize, { merge: true });
    }
  }, [customize, currentUser, saved, ccodeGlobal]);

  const changeComplete = (color: any, which: string) => {
    setSaved(false);
    setPickers({ ...pickers, [which]: false });
    setCustomize({
      ...customize,
      colors: { ...customize.colors, [which]: color.hex },
    });
  };

  const saveThis = () => {
    if (customize.colors && ccodeGlobal) {
      const teacherObj = {
        name: (document.getElementById("teacherName") as any)?.value,
        myUrl: (document.getElementById("teacherMyUrl") as any)?.value,
        phone: (document.getElementById("teacherPhone") as any)?.value,
        skype: (document.getElementById("teacherSkype") as any)?.value,
        facebook: (document.getElementById("teacherFacebook") as any)?.value,
        email: (document.getElementById("teacherEmail") as any)?.value,
        description: (document.getElementById("teacherDescription") as any)
          ?.value,
      };

      const corporateObj = {
        copyright: (document.getElementById("corporateCopyright") as any)
          ?.value,
        terms: (document.getElementById("corporateTerms") as any)?.value,
        aboutUs: (document.getElementById("corporateAboutUs") as any)?.value,
        privacy: (document.getElementById("corporatePrivacy") as any)?.value,
        contacts: (document.getElementById("corporateContacts") as any)?.value,
        buy: (document.getElementById("corporateBuy") as any)?.value,
        appName: (document.getElementById("corporateAppName") as any)?.value,
      };

      const newCustomize = {
        ...customize,
        teacher: { ...customize.teacher, ...teacherObj },
        corporate: { ...customize.corporate, ...corporateObj },
      };

      firestore
        .doc(`/companies/${ccodeGlobal}/data/customize`)
        .set(newCustomize, { merge: true });

      firestore
        .doc(`/companies/${ccodeGlobal}/data/customizeDev`)
        .set(newCustomize, { merge: true });

      setSaved(true);
      setCustomize(newCustomize);

      if (files[0]) {
        uploadPic(files[0], ccodeGlobal);
      }

      if (logoFiles[0]) {
        uploadPic(logoFiles[0], ccodeGlobal + "-logo");
      }
    }
  };

  const revertThis = () => {
    if (customize.colors && ccodeGlobal) {
      firestore
        .doc(`/companies/${ccodeGlobal}/data/customize`)
        .get()
        .then((res: any) => {
          firestore
            .doc(`/companies/${ccodeGlobal}/data/customizeDev`)
            .set(res.data());

          setSaved(true);
          setCustomize(res.data());
        });
    }
  };

  return (
    <React.Fragment>
      {/* <Segment className={classes.topSegment} inverted>
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <div className={classes.adminHeader}>
                <div className={classes.headerIcon}>
                  <Icon name="address card" />
                </div>
                <div className={classes.headerText}>
                  <h4 className={classes.cardIconTitle}>
                    Customize NetEnglish
                  </h4>
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment> */}
      <div className={classes.flexWrapper}>
        <div className={classes.preview}>
          <Segment className={classes.cat} color="olive">
            <div className={classes.titleWrapper}>
              <div className={classes.title}>Preview</div>
              <div className={classes.titlePlus}>
                <Button
                  icon="mobile"
                  size="tiny"
                  color="olive"
                  onClick={() => setMobile(true)}
                  compact
                  inverted={!mobile}
                  active={false}
                />
                <Button
                  icon="tv"
                  size="tiny"
                  color="olive"
                  compact
                  onClick={() => setMobile(false)}
                  inverted={mobile}
                  active={false}
                />
              </div>
            </div>
            <div
              className={
                mobile ? classes.iframeWrapperMobile : classes.iframeWrapper
              }
            >
              <iframe
                ref={iframeRef}
                className={classes.iframe}
                title="preview"
              />
            </div>
          </Segment>
        </div>
        <div className={classes.custom}>
          <Segment className={classes.cat} color="olive">
            <div className={classes.titleWrapper}>
              <div className={classes.title}>Customization</div>
            </div>
            <Tab
              className={classes.tab}
              renderActiveOnly={false}
              menu={{ secondary: true, pointing: true }}
              panes={[
                {
                  menuItem: "Look",
                  pane: (
                    <Tab.Pane
                      key={1}
                      style={{
                        border: "none",
                        padding: "0px",
                        paddingLeft: "7px",
                      }}
                    >
                      <ColorTab
                        setSaved={setSaved}
                        pickers={pickers}
                        setPickers={setPickers}
                        colors={customize.colors || {}}
                        changeComplete={changeComplete}
                        customize={customize}
                        setCustomize={setCustomize}
                      />
                    </Tab.Pane>
                  ),
                },
                {
                  menuItem: "Teacher",
                  pane: (
                    <Tab.Pane
                      key={2}
                      style={{
                        border: "none",
                        padding: "0px",
                        paddingLeft: "7px",
                        paddingRight: "7px",
                      }}
                    >
                      <TeacherTab
                        setSaved={setSaved}
                        customize={customize}
                        teacher={customize.teacher || {}}
                        setCustomize={setCustomize}
                        ccode={ccodeGlobal}
                        files={files}
                        setFiles={setFiles}
                      />
                    </Tab.Pane>
                  ),
                },
                {
                  menuItem: "Corporate",
                  pane: (
                    <Tab.Pane
                      key={3}
                      style={{ border: "none", padding: "0px 7px" }}
                    >
                      <CorporateTab
                        customize={customize}
                        corporate={customize.corporate || {}}
                        setCustomize={setCustomize}
                        ccode={ccodeGlobal}
                        logoFiles={logoFiles}
                        setLogoFiles={setLogoFiles}
                      />
                    </Tab.Pane>
                  ),
                },
              ]}
            />
            <div style={{ textAlign: "right" }}>
              <Segment
                textAlign="right"
                compact
                className={classes.buttonSegment}
                style={{ marginLeft: "auto" }}
              >
                <Button onClick={revertThis} compact>
                  Revert
                </Button>
                <Button onClick={saveThis} compact>
                  Publish
                </Button>
              </Segment>
            </div>
          </Segment>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Customize;
// AIzaSyA8d9SleAVkuA1BwyrcvBypzmt9rFDXFrA

const uploadPic = (file: any, ccode: any) => {
  cloudStorage
    .ref()
    .child("profilepics/" + ccode + ".png")
    .put(file)
    .then((snapshot) => {
      console.log("Uploaded.");
    })
    .catch((e) => console.log(e));
};
