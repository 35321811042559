import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = 0;

const lessonDataSlice = createSlice({
  initialState,
  name: "lessonData",
  reducers: {
    setLessonDataChange: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setLessonDataChange } = lessonDataSlice.actions;

export default lessonDataSlice.reducer;
