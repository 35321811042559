import React, { useEffect, useRef, useState } from "react";

import { createStyles, makeStyles } from "@material-ui/styles";
import BankdataDlg from "./account.bankdata";
import { Button, Dropdown, Form, Input, Radio } from "semantic-ui-react";
import { firestore } from "firebaseUtil/firebaseUtil";
import { useSelector } from "react-redux";
import { RootState } from "Redux/reducers/_index";
import CustomizePic from "./account.customize.pic";
import { tx } from "utils/globalize";
import store from "Redux/store";

const themes = [
  { text: "Orange", key: "af", value: "redorange" },
  { text: "Lilac", key: "ax", value: "lilac" },
  { text: "Green Idea", key: "al", value: "greenish" },
  { text: "Deep Blue-Green", key: "dz", value: "bluegreen" },
  { text: "Elegant", key: "as", value: "elegant" },
  { text: "Green", key: "ad", value: "green" },
  { text: "Grey/Tan", key: "ao", value: "greytan" },
  { text: "Blue/Tan", key: "ai", value: "bluetan" },
  { text: "Red/Brown", key: "ag", value: "redbrown" },
  { text: "Argentina", key: "ar", value: "ar" },
];

const colors: any = {
  redorange: {
    text: "Orange",
    value: "redorange",
    primaryBackground: "#E76659",
    secondaryBackground: "#FFDEBF",
    deepBackground: "#172B4D",
    primaryText: "#FFFFFF",
    font: "Raleway",
  },
  lilac: {
    text: "Lilac",
    value: "lilac",
    primaryBackground: "#6A779E",
    secondaryBackground: "#B6C3EB",
    deepBackground: "#EBD2CC",
    primaryText: "#FFFFFF",
    font: "Assistant",
  },
  greenish: {
    text: "Green Idea",
    value: "greenish",
    primaryBackground: "#287686",
    secondaryBackground: "#C79FA7",
    deepBackground: "#F6F0E7",
    primaryText: "#FFFFFF",
    font: "Bitter",
  },
  bluegreen: {
    text: "Deep Blue-Green",
    value: "bluegreen",
    primaryBackground: "#183A53",
    secondaryBackground: "#5C97B8",
    deepBackground: "#D1A58A",
    primaryText: "#FFFFFF",
    font: "Catamaran",
  },
  elegant: {
    text: "Elegant",
    value: "elegant",
    primaryBackground: "#003366",
    secondaryBackground: "#71B1C5",
    deepBackground: "#F0F2F3",
    primaryText: "#FFFFFF",
    font: "Exo",
  },
  green: {
    text: "Green",
    value: "green",
    primaryBackground: "#1C4508",
    secondaryBackground: "#29660C",
    deepBackground: "#F7E2C7",
    primaryText: "#EACA8B",
    font: "Heebo",
  },
  greytan: {
    text: "Grey Tan",
    value: "greytan",
    primaryBackground: "#908994",
    secondaryBackground: "#1C799E",
    deepBackground: "#F6F0E7",
    primaryText: "#F6F0E7",
    font: "Inter",
  },
  bluetan: {
    text: "Blue Tan",
    value: "bluetan",
    primaryBackground: "#1C799E",
    secondaryBackground: "#908984",
    deepBackground: "#F6F0E7",
    primaryText: "#F6F0E7",
    font: "Maven Pro",
  },
  redbrown: {
    text: "Red Brown",
    value: "redbrown",
    primaryBackground: "#B83330",
    secondaryBackground: "#908984",
    deepBackground: "#888888",
    primaryText: "#FFFFFF",
    font: "Catamaran",
  },
};

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    cat: {
      marginBottom: "0px !important",
      height: "100%",
      position: "absolute",
      top: "0px",
      bottom: "0px",
      left: "0px",
      right: "0px",
      overflowY: "auto",
      padding: "30px",
      "@media (max-width: 500px)": {
        padding: "15px",
      },
    },
    wrapper: {
      marginTop: "12px",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      overflow: "auto",
      marginBottom: "0px !important",
    },
    titleWrapper: { display: "flex", width: "100%", marginBottom: "3px" },
    texti: { fontSize: "18px", marginTop: "0px" },
    listitem: { display: "flex", marginBottom: "15px" },
    intro: { display: "flex", marginBottom: "15px" },
    number: { flex: "0 0 30px" },
    emails: { display: "flex", marginTop: "15px" },
    company: { fontWeight: "bold", marginTop: "15px", marginBottom: "10px" },
    emaillink: {
      color: "blue",
      textDecoration: "underline",
      paddingLeft: "30px",
    },
    listtext: { flex: "1 1 auto" },
    link: { fontWeight: "bold", marginTop: "10px" },
    title: {
      flex: "1 1 auto",
      fontWeight: "bold",
      marginBottom: "5px",
      fontSize: "16px",
      marginTop: "4px",
    },
    share: { marginTop: "10px", display: "flex" },
    left: { flex: "1 1 auto" },
    right: { flex: "1 1 auto", textAlign: "right" },
    mitem: { padding: "2px !important" },
  })
);

const AccountTipsCustomize = (props: any) => {
  const classes = useStyles();

  const [bankDlgOpen, setBankDlgOpen] = useState(false);
  const [proHelp, setProHelp] = useState(0);
  const [customize, setCustomize] = useState<any>({});
  const ccodeGlobal = useSelector<RootState, any>((state) => state.ccodeGlobal);
  const saveCustomizeRef = useRef<any>();

  useEffect(() => {
    if (ccodeGlobal) {
      firestore
        .doc("/companies/" + ccodeGlobal + "/data/customize")
        .get()
        .then((res) => {
          if (res.data()) {
            setCustomize(res.data());
            console.log(res.data());
            if (!res.data()?.teacher?.inMenu) {
              setProHelp(0);
              return;
            }

            if (res.data()?.teacher.linkType === "internal") {
              setProHelp(2);
            } else {
              setProHelp(1);
            }
          }
        });
    }
  }, [ccodeGlobal]);

  const changeTheme = async (e: any, b: any) => {
    console.log(b);

    await firestore
      .doc("/users/" + store.getState().currentUser?.id + "/data/settings")
      .set({ theme: colors[b.value] }, { merge: true });

    await firestore
      .doc("/companies/" + ccodeGlobal + "/data/account")
      .set({ theme: b.value }, { merge: true });
  };

  // const changeRecommend = async (e: any) => {
  //   props.setAccount({ ...props.account, recommend: e.target.value });
  //   // await firestore
  //   //   .doc("/companies/" + ccodeGlobal + "/data/account")
  //   //   .set({ theme: b.value }, { merge: true });
  // };

  const changeCustomize = async (e: any) => {
    const id =
      e.target.id === "teachermyUrl"
        ? "myUrl"
        : e.target.id.replace("teacher", "").toLowerCase();

    setCustomize({
      ...customize,
      teacher: {
        ...customize.teacher,
        [id]: e.target.value,
      },
    });
  };

  const saveCustomize = async (pic: string = "") => {
    await firestore.doc("/companies/" + ccodeGlobal + "/data/customize").set({
      ...customize,
      teacher: pic
        ? { ...(customize.teacher || {}), pic: pic }
        : { ...(customize.teacher || {}) },
    });

    if (pic) {
      setCustomize({
        ...customize,
        teacher: pic
          ? { ...(customize.teacher || {}), pic: pic }
          : { ...(customize.teacher || {}) },
      });
    }
  };

  saveCustomizeRef.current = saveCustomize;

  const onBlur = () => {
    saveCustomizeRef.current();
  };

  const changeShowOnScreen = async (e: any, b: any) => {
    let newCustomize = { ...customize };

    setCustomize({
      ...customize,
      teacher: { ...customize.teacher, onScreen: b.checked },
    });
    newCustomize.teacher.onScreen = b.checked;

    await firestore
      .doc("/companies/" + ccodeGlobal + "/data/customize")
      .set({ ...newCustomize });
  };

  const changeProHelp = async (res: any) => {
    setProHelp(res);
    let newCustomize = { teacher: {}, ...customize };

    if (res === 0) {
      setCustomize({
        ...customize,
        teacher: { ...customize.teacher, inMenu: false, onScreen: false },
      });
      newCustomize.teacher.inMenu = false;
      newCustomize.teacher.onScreen = false;
    } else if (res === 1) {
      setCustomize({
        ...customize,
        teacher: { ...customize.teacher, inMenu: true, linkType: "link" },
      });
      newCustomize.teacher.inMenu = true;
      newCustomize.teacher.linkType = "link";
    } else if (res === 2) {
      setCustomize({
        ...customize,
        teacher: { ...customize.teacher, inMenu: true, linkType: "internal" },
      });
      newCustomize.teacher.inMenu = true;
      newCustomize.teacher.linkType = "internal";
    }

    await firestore
      .doc("/companies/" + ccodeGlobal + "/data/customize")
      .set({ ...newCustomize });
  };

  return (
    <React.Fragment>
      <div className={classes.cat}>
        {/* <div className={classes.titleWrapper}>
          <div className={classes.title}>Info and Tips</div>
        </div> */}

        <div className={classes.texti}>
          <div className={classes.intro}>
            {tx(
              "a12345f01",
              "A cool thing in WizzWords is that you can customize it to promote you or your company in the app. This is OPTIONAL, but here is how:"
            )}
          </div>
          <div className={classes.listitem}>
            <div className={classes.number}>1.</div>
            <div className={classes.listtext}>
              {tx(
                "a12345f02",
                "Select the color scheme you would like to use for new students."
              )}
              <Form style={{ marginTop: "15px" }}>
                <Form.Field>
                  <Dropdown
                    placeholder="Orange..."
                    search
                    selection
                    options={themes}
                    onChange={changeTheme}
                    name={"country"}
                    value={props.account?.theme || "redorange"}
                  />
                </Form.Field>
                <Form.Field>
                  <Button
                    onClick={() =>
                      window.open("https://app.wizzwords.net/", "_blank")
                    }
                  >
                    {tx("a12345f03", "Try in the App")}
                  </Button>
                  {tx(
                    "a12345f04",
                    "(Use the same email address and password as here.)"
                  )}
                </Form.Field>
              </Form>
            </div>
          </div>
          {/* <div className={classes.listitem}>
            <div className={classes.number}>2.</div>
            <div className={classes.listtext}>
              {tx(
                "a12345f05",
                "Type in any recommendations you would like to add to the sales page at the top (if any)."
              )}{" "}
              {tx(
                "a12345f06",
                "For example you can write: Recommended by Joe Blow."
              )}{" "}
              {tx("a12345f06a", "(Leave empty if you don't need anything.)")}
              <Form style={{ marginTop: "15px" }}>
                <Form.Group>
                  <Form.Field style={{ width: "100%" }}>
                    <input
                      id="recommend"
                      type="text"
                      value={props.account?.recommend || ""}
                      onChange={changeRecommend}
                      style={{ width: "100%" }}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </div>
          </div> */}
          <div className={classes.listitem}>
            <div className={classes.number}>2.</div>
            <div className={classes.listtext}>
              {tx(
                "a12345f07",
                "Do you want to have a Pro Help option available in the menu of your app for your students?"
              )}{" "}
              {tx(
                "a12345f09",
                "This option will either bring them to your own homepage or it will open them a page in the app with your contact data."
              )}
              <Form style={{ marginTop: "15px" }}>
                <Form.Group>
                  <Form.Field>
                    <Radio
                      label={tx("a12345f010", "No pro help option")}
                      name="radioGroup"
                      value={proHelp}
                      checked={proHelp === 0}
                      onChange={() => changeProHelp(0)}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Radio
                      label={tx("a12345f011", "Pro Help leads to my webpage")}
                      name="radioGroup"
                      value={proHelp}
                      checked={proHelp === 1}
                      onChange={() => changeProHelp(1)}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Radio
                      label={tx(
                        "a12345f012",
                        "Pro Help opens internal profile"
                      )}
                      name="radioGroup"
                      value={proHelp}
                      checked={proHelp === 2}
                      onChange={() => changeProHelp(2)}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
              <div
                style={{
                  minWidth: "250px",
                  width: "50%",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <img
                  src={"/images/" + props.language + "/call.png"}
                  alt="example"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          </div>

          {proHelp > 0 ? (
            <div className={classes.listitem}>
              <div className={classes.number}>3.</div>
              <div className={classes.listtext}>
                {tx("a12345f013", "Show Pro Help image on dashboard.")}{" "}
                {tx(
                  "a12345f014",
                  "(It's a bit intrusive, but could be good promo. Student can turn it off.)"
                )}
                <Form style={{ marginTop: "15px" }}>
                  <Form.Field>
                    <Radio
                      toggle
                      onChange={(e: any, b: any) => changeShowOnScreen(e, b)}
                    />
                  </Form.Field>
                </Form>
                <div
                  style={{
                    minWidth: "250px",
                    width: "50%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <img
                    src={"/images/" + props.language + "/head.png"}
                    alt="example"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
          ) : null}
          {proHelp === 1 ? (
            <div className={classes.listitem}>
              <div className={classes.number}>4.</div>
              <div className={classes.listtext}>
                {tx("a12345f015", "Webpage to open if Pro Help clicked:")}
                <Form style={{ marginTop: "15px" }}>
                  {/* <Form.Field>
          <Radio
            label="Links to webpage"
            name="radioGroup"
            value="this"
            checked={!teacher.linkType || teacher.linkType === "webpage"}
            onChange={setWebpage}
          />
        </Form.Field> */}
                  <Form.Field>
                    <Input
                      type="url"
                      id="teachermyUrl"
                      label="https://"
                      value={customize.teacher?.myUrl || ""}
                      onChange={changeCustomize}
                      onBlur={onBlur}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>
                      {tx("a12345f016", "Profile picture or company logo")}
                    </label>
                    <CustomizePic
                      saveCustomize={saveCustomizeRef}
                      pic={customize?.teacher?.pic}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Button
                      onClick={() =>
                        window.open("https://app.wizzwords.net/", "_blank")
                      }
                    >
                      {tx("a12345f017", "Try in the App")}
                    </Button>
                    {tx(
                      "a12345f018",
                      "(Use the same email address and password as here.)"
                    )}
                  </Form.Field>
                </Form>
              </div>
            </div>
          ) : null}
          {proHelp === 2 ? (
            <div className={classes.listitem}>
              <div className={classes.number}>4.</div>
              <div className={classes.listtext}>
                {/* <Form.Field>
          <Radio
            label="Use internal profile"
            name="radioGroup"
            value="that"
            checked={teacher.linkType === "internal"}
            onChange={setInternal}
          />
        </Form.Field> */}
                <Form>
                  <Form.Field required width={16}>
                    <label>{tx("a12345f019", "Name to show")}</label>
                    <input
                      id="teacherName"
                      type="text"
                      value={customize.teacher?.name || ""}
                      onChange={changeCustomize}
                      onBlur={onBlur}
                    />
                  </Form.Field>
                  <Form.TextArea
                    width={16}
                    required
                    id="teacherDescription"
                    value={customize.teacher?.description || ""}
                    label={tx("a12345f020", "Description")}
                    onChange={changeCustomize}
                    onBlur={onBlur}
                  />
                  <Form.Group widths="equal">
                    <Form.Field>
                      <label>E-mail</label>
                      <input
                        id="teacherEmail"
                        type="email"
                        onChange={changeCustomize}
                        value={customize.teacher?.email || ""}
                        onBlur={onBlur}
                      ></input>
                    </Form.Field>
                    <Form.Field>
                      <label>{tx("a12345f021", "Phone")}</label>
                      <input
                        id="teacherPhone"
                        type="email"
                        onChange={changeCustomize}
                        value={customize.teacher?.phone || ""}
                        onBlur={onBlur}
                      ></input>
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <label>Skype</label>
                      <input
                        id="teacherSkype"
                        type="email"
                        onChange={changeCustomize}
                        value={customize.teacher?.skype || ""}
                        onBlur={onBlur}
                      ></input>
                    </Form.Field>
                    <Form.Field>
                      <label>Facebook</label>
                      <input
                        id="teacherFacebook"
                        type="email"
                        onChange={changeCustomize}
                        value={customize.teacher?.facebook || ""}
                        onBlur={onBlur}
                      ></input>
                    </Form.Field>
                  </Form.Group>
                  <Form.Field>
                    <label>{tx("a12345f024", "Profile picture")}</label>
                    <CustomizePic
                      saveCustomize={saveCustomizeRef}
                      pic={customize?.teacher?.pic}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Button
                      onClick={() =>
                        window.open("https://app.wizzwords.net/", "_blank")
                      }
                    >
                      {tx("a12345f026", "Try in the App")}
                    </Button>
                    {tx(
                      "a12345f027",
                      "(Use the same email address and password as here.)"
                    )}
                  </Form.Field>
                </Form>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <BankdataDlg
        open={bankDlgOpen}
        setOpen={setBankDlgOpen}
        setAccount={props.setAccount}
        account={props.account}
        doSearch={() => {}}
        bank={props.account.bank}
      />
    </React.Fragment>
  );
};

export default AccountTipsCustomize;
// AIzaSyA8d9SleAVkuA1BwyrcvBypzmt9rFDXFrA
