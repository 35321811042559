import store from "Redux/store";
import { setConfirmOpen } from "Redux/reducers/reducer.confirmOpen";

export const confirmUpgrade = async () => {
  // let bookVersion: any;
  let appVersion: any;
  // let fileVersion: any;
  let data: any;

  try {
    const response = await fetch("/version1.json");
    data = await response.json();

    // bookVersion = localStorage.getItem("bookVersion");
    appVersion = localStorage.getItem("appVersion");
    // fileVersion = localStorage.getItem("fileVersion");
  } catch (e) {
    console.log("error fetching version");
    return;
  }

  if (!appVersion) {
    return;
  }

  if (
    // bookVersion !== data.bookVersion ||
    appVersion !== data.appVersion
    // || fileVersion !== data.fileVersion
  ) {
    store.dispatch(setConfirmOpen(true));
  }
};

export const versionCheck = async (type: string) => {
  store.dispatch(setConfirmOpen(false));
  // let bookVersion: any;
  let appVersion: any;
  // let fileVersion: any;
  let data: any;

  try {
    const response = await fetch("/version1.json");

    data = await response.json();

    // bookVersion = localStorage.getItem("bookVersion");
    appVersion = localStorage.getItem("appVersion");
    // fileVersion = localStorage.getItem("fileVersion");
  } catch (e) {
    console.log(e, "error fetching version");
    return;
  }

  // if (!bookVersion) {
  //   localStorage.setItem("bookVersion", data.bookVersion);
  //   localStorage.setItem("appVersion", data.appVersion);
  //   localStorage.setItem("fileVersion", data.fileVersion);

  //   try {
  //     await dbDrills.table("drills").clear();
  //   } catch (e) {
  //     console.log("no drills yet");
  //   }
  //   try {
  //     await dbDrills.table("books").clear();
  //   } catch (e) {
  //     console.log("no books yet");
  //   }
  //   window.location.reload(true);
  //   return;
  // }

  // if (bookVersion !== data.bookVersion) {
  //   localStorage.setItem("bookVersion", data.bookVersion);
  //   localStorage.setItem("appVersion", data.appVersion);
  //   localStorage.setItem("fileVersion", data.fileVersion);
  //   try {
  //     await dbDrills.table("drills").clear();
  //     await dbDrills.table("books").clear();
  //     if (fileVersion !== data.fileVersion) {
  //       await dbDrills.table("files").clear();
  //     }
  //   } catch (e) {
  //     console.log("error removing books");
  //   }
  //   window.location.reload(true);
  //   return;
  // }

  localStorage.setItem("appVersion", data.appVersion);
  if (appVersion && appVersion !== data.appVersion) {
    window.location.reload();
  }
};
