import { compare } from "./saver.saveFullLesson";
import store from "Redux/store";
import { firestore, FreeData } from "firebaseUtil/firebaseUtil";
import firebase from "firebase/app";

export const loadLessonList = async (app: boolean = false, type: string) => {
  const currentUser = store.getState().currentUser;
  let lessonsRef: firebase.firestore.Query<firebase.firestore.DocumentData>;
  let lessonRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  let lessonsData: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>;
  let lessonDoc: firebase.firestore.DocumentData | undefined = undefined;

  if (app) {
    lessonsRef = firestore
      .collection(`translationslists`)
      .where("language", "==", currentUser?.language || "en")
      .where("type", "==", type);
    try {
      lessonsData = await lessonsRef.get();
      if (lessonsData.docs.length) {
        lessonDoc = lessonsData.docs[0].data();
      }
    } catch (e) {
      console.log("No lesson data");
      return [];
    }
  } else {
    lessonRef = firestore.doc(`originalslists/${type}`);
    const lessonDataTemp = await lessonRef.get();
    if (lessonDataTemp.exists) {
      lessonDoc = lessonDataTemp.data() as firebase.firestore.DocumentData;
    } else {
      return [];
    }
  }

  const lessonListOrdered = lessonDoc?.items.sort(compare) || [];
  // store.dispatch(setLessonList(lessonListOrdered));

  return lessonListOrdered;
};

export const loadOrigLessonList = async (
  type: string = "lessons",
  language: string = "en"
) => {
  const currentUser = store.getState().currentUser;

  // get english lessonlist
  const lessonRef = firestore.doc(`originalslists/${type}`);
  let lessonTemp: any;

  try {
    lessonTemp = await lessonRef.get();
  } catch (e) {
    console.log("No lesson data");
    return [];
  }

  const lessonData = lessonTemp.data()?.items || [];

  // get translated lessonlist

  let lessonsRef: firebase.firestore.Query<firebase.firestore.DocumentData>;
  let lessonsData: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>;
  let lessonTransTemp: firebase.firestore.DocumentData | undefined = undefined;

  lessonsRef = firestore
    .collection(`translationslists`)
    .where("language", "==", language || currentUser?.language)
    .where("type", "==", type);
  try {
    lessonsData = await lessonsRef.get();
    if (lessonsData.docs.length) {
      lessonTransTemp = lessonsData.docs[0].data();
    }
  } catch (e) {
    console.log("No lesson data");
    lessonTransTemp = {};
  }

  const lessonTransData = lessonTransTemp?.items || [];

  // console.log(lessonData, lessonTransData);

  const lessonListOrdered = lessonData.map((lesson: FreeData) => {
    const thisLesson = lessonTransData.find(
      (item: FreeData) => item.seq === lesson.seq
    );

    if (!thisLesson) {
      return { ...lesson, color: "red" };
    }
    if (thisLesson.updated < lesson.updated) {
      return { ...lesson, color: "blue" };
    } else {
      return { ...lesson, color: "black" };
    }
  });

  return lessonListOrdered;
};
