import { setAppTransParts } from "Redux/reducers/reducer.appTransParts";
import { setAuthLoaded } from "Redux/reducers/reducer.authLoaded";
import { setCcodeGlobal } from "Redux/reducers/reducer.ccodeGlobal";
import { setCurrentUser } from "Redux/reducers/reducer.currentUser";
import { setSettings, SettingsType } from "Redux/reducers/reducer.settings";
import store from "Redux/store";
import { loadAppTranslation } from "utils/saver.loadTranslation";

import { firestore } from "../firebaseUtil/firebaseUtil";
import firebase from "../firebaseUtil/firebaseUtil";

export const setupListeners = (
  userRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
  userAuth: firebase.User
) => {
  //********************************
  //** User Snapshot
  //********************************

  userRef.onSnapshot(async (snapShot) => {
    const origLanguage = store.getState().currentUser?.language;
    const origExpiry = store.getState().currentUser?.expiry;

    store.dispatch(
      setCurrentUser({
        id: userRef.id,
        ...snapShot.data(),
      })
    );

    if (snapShot.data()?.language !== origLanguage) {
      store.dispatch(
        setAppTransParts(await loadAppTranslation(snapShot.data()?.language))
      );
    }

    if (
      snapShot.data()?.expiry !== origExpiry ||
      snapShot.data()?.language !== origLanguage
    ) {
      userAuth.getIdToken(true);
    }

    if (snapShot.data()?.type !== "admin") {
      localStorage.setItem("language", snapShot.data()?.language || "en");
    }

    localStorage.setItem("currentUser", userRef.id);
    localStorage.setItem("userType", snapShot.data()?.type);
    localStorage.setItem("userLang", snapShot.data()?.language);

    store.dispatch(setCcodeGlobal(snapShot.data()?.ccode || ""));

    store.dispatch(setAuthLoaded(true));
  });

  //********************************
  //** Settings Snapshot
  //********************************
  const settingsRef = firestore.doc(`/users/${userRef.id}/data/settings`);

  const settingsTemp = localStorage.getItem(`ne_settings${userRef.id}`);

  if (settingsTemp) {
    store.dispatch(setSettings(JSON.parse(settingsTemp)));
  }

  settingsRef.onSnapshot(async (snapShot) => {
    const settings = snapShot.data();

    const defaultSettings = {
      testReview: false,
      autoRead: true,
      pronunciation: true,
      writing: true,
      readSpeed: 1,
      pro: true,
      startingLevel: "A",
      showFirstPage: true,
      wordLevel: "A",
      playing: true,
      highlight: true,
      fontSize: 16,
    };

    if (!snapShot.exists) {
      settingsRef.set({
        ...defaultSettings,
      });
      return;
    }
    localStorage.setItem(
      `ne_settings${userRef.id}`,
      JSON.stringify(snapShot.data())
    );

    store.dispatch(
      setSettings({ ...defaultSettings, ...(settings as SettingsType) })
    );
  });
};
