import React, { useEffect, useState } from "react";
import {
  Transition,
  Modal,
  Button,
  List,
  Message,
  Header,
  Segment,
  Form,
  Input,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { functions } from "../firebaseUtil/firebaseUtil";
import { Menu } from "semantic-ui-react";
import store from "../Redux/store";
import { tx } from "utils/globalize";

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    text: {},
  })
);

const NewUserAdd = (props: any) => {
  //eslint-disable-next-line
  const classes = useStyles();

  const [newUsers, setNewUsers] = useState("");
  const [newTrials, setNewTrials] = useState("");
  const [working, setWorking] = useState(false);
  const [added, setAdded] = useState<any[]>([]);
  const [sendEmail, setSendEmail] = useState(true);
  const [type, setType] = useState("new");

  const closeMe = () => {
    props.setOpen(false);
  };

  useEffect(() => {
    const list = document.getElementById("list5");
    if (list) {
      setTimeout(() => {
        list.scrollTop = list.scrollHeight;
      }, 0);
    }
  }, [added]);

  const changeNewUsers = (e: any) => {
    setNewUsers(e.currentTarget.value);
  };

  const changeNewTrials = (e: any) => {
    setNewTrials(e.currentTarget.value);
  };

  const changeCheckbox = (e: any, data: any) => {
    setSendEmail(e.target.checked);
  };

  const addNewUser = async () => {
    const createUser = functions.httpsCallable("createOrUpdateUserPaid");

    let mails = newUsers.split(",").map((item) => item.toLowerCase().trim());
    const wrong: string[] = [];

    let problems: any[] = [];

    mails.forEach((item) => {
      if (!verifyEmail(item)) {
        wrong.push(item);
      }
    });

    if (wrong.length) {
      alert(
        tx("co1weaowowoe13", "These mail addresses are incorrect:") +
          " \n" +
          wrong.join(", ")
      );
      return;
    }

    if (!mails.length) {
      alert(tx("co3weaowowoe13", "No valid email addresses supplied"));
      return;
    }

    mails = [...new Set(mails)];

    setWorking(true);
    setAdded([]);

    for (let i = 0; i < mails.length; i++) {
      let gresult: any;

      try {
        gresult = await createUser({
          ccode: store.getState().ccodeGlobal,
          users: [
            {
              auth: {
                email: mails[i].toLowerCase(),
                disabled: false,
              },
              personal: {
                firstName: "",
                lastName: "",
                language: props.account?.language || "it",
                followers: 0,
                expiry: 86400 * 50000000,
                type: "user",
                email: mails[i].toLowerCase(),
                joined: Date.now(),
              },
            },
          ],
          email: sendEmail || false,
        });
      } catch (error) {
        console.log(error);
        alert(error);

        setWorking(false);
        props.doSearch();
        return;
      }

      problems = [...problems, { ...gresult.data.problems[0] }];

      setAdded([...problems]);
    }
    props.doSearch();

    setWorking(false);
  };

  return (
    <React.Fragment>
      <Transition
        unmountOnHide
        visible={props.open}
        animation="fade"
        duration={250}
        closeOnDimmerClick={!working}
      >
        <Modal size="small" open={true} onClose={() => props.setOpen(false)}>
          <Modal.Header
            style={{ padding: "0px 15px", display: "flex" }}
            color="olive"
          >
            <div style={{ paddingTop: "9px" }}>
              {tx("coweaowowo3e13", "Add users")}
            </div>
            {/* <Menu
              secondary
              style={{
                flex: "1 1 auto",
                textAlign: "right",
                marginTop: "0px",
                padding: "0px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Menu.Item
                disabled={working}
                name="new"
                active={type === "new"}
                onClick={() => setType("new")}
              />
              <Menu.Item
                disabled={working}
                name="trial"
                active={type === "trial"}
                onClick={() => setType("trial")}
              />
            </Menu> */}
          </Modal.Header>
          <Modal.Content
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "20px",
              overflowY: "auto",
              height: "450px",
            }}
            id="list5"
          >
            <Header
              attached="top"
              style={{
                marginTop: "5px",
                background: "#f5f5f5",
                display: "flex",
              }}
            >
              <div>
                {type === "new"
                  ? tx("coweaow34owoe13", "Add Users")
                  : "Send trial link"}
              </div>
              <Form.Field style={{ flex: "1 1 auto", textAlign: "right" }}>
                <Form.Checkbox
                  checked={sendEmail || type === "trial"}
                  disabled={working || type === "trial"}
                  id="sendEmail"
                  label={
                    type === "new"
                      ? tx("cweoweaoswowoe13", "Send notification E-mail")
                      : "Send e-mail about the trial"
                  }
                  onChange={changeCheckbox}
                />
              </Form.Field>
            </Header>
            <Segment attached>
              <Form disabled={working}>
                {type === "new" ? (
                  <React.Fragment>
                    <Form.Field disabled={!props.account?.tba}>
                      <label>{tx("coweaowowoe13", "Give licence to:")}</label>
                      <Input
                        type="text"
                        action={{
                          color: "teal",
                          content: "Add",
                          onClick: addNewUser,
                          disabled: working,
                        }}
                        placeholder="joe@gmailtwo.com"
                        value={newUsers}
                        onChange={changeNewUsers}
                      />
                    </Form.Field>
                    <span
                      style={{ color: !props.account?.tba ? "red" : "black" }}
                    >
                      {tx("coweaowowoe14", "Licences you have to use:")}{" "}
                      {props.account?.tba || 0}{" "}
                    </span>
                    {/* <span
                      onClick={() => props.setActive(0)}
                      style={{
                        color: "blue",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                    >
                      Buy more
                    </span> */}
                  </React.Fragment>
                ) : (
                  <Form.Field>
                    <label>Invite for trial:</label>
                    <Input
                      type="email"
                      action={{
                        color: "teal",
                        content: "Add",
                      }}
                      value={newTrials}
                      onChange={changeNewTrials}
                      placeholder="joe@gmailtwo.com"
                    />
                  </Form.Field>
                )}
              </Form>
            </Segment>
            <Segment
              style={{ flex: "1 1 auto", height: "100%", overflowY: "auto" }}
              id="list5"
            >
              <Dimmer active={working}>
                <Loader size="small">Processing...</Loader>
              </Dimmer>
              <List>
                {added.map((item: any) => (
                  <List.Item key={item.email}>
                    <List.Content>
                      <List.Icon name={item.failure ? "close" : "check"} />
                      <List.Header>{item.email}</List.Header>
                      <List.Description
                        style={{ color: item.failure ? "red" : "green" }}
                      >
                        {item.failure ||
                          tx("coweaowowoe23", "Successfully added")}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                ))}
              </List>
              {!added.length && !props.working ? (
                ""
              ) : (
                <Message positive style={{ minHeight: "auto" }}>
                  <Message.Header>
                    {tx("coweaowowoe33", "Completed")}
                  </Message.Header>
                  <p>{tx("coweaowowoe43", "Adding users finished.")}</p>
                </Message>
              )}
            </Segment>
          </Modal.Content>
          <Modal.Actions disabled={working}>
            <Button
              disabled={props.working}
              loading={props.working}
              onClick={closeMe}
              positive={!props.working}
            >
              {tx("coweaowowoe63", "Close")}
            </Button>
            {/* <Button floated="left">Configure E-mails</Button> */}
          </Modal.Actions>
        </Modal>
      </Transition>
    </React.Fragment>
  );
};

export default NewUserAdd;

const verifyEmail = (value: any) => {
  var emailRex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
};
