import React from "react";
import { useTable } from "react-table";

function InvoiceTable(props: any) {
  // Use the state and functions returned from useTable to build your UI

  const columns = React.useMemo(
    () => [
      { Header: "Date", accessor: "date" },
      { Header: "Order No.", accessor: "order" },
      { Header: "Quantity", accessor: "quantity" },
      { Header: "Amount", accessor: "amount" },
      { Header: "Invoice", accessor: "invoice" },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: props.data,
  });

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default InvoiceTable;
