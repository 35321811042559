import "codemirror/lib/codemirror.css";
import "codemirror/theme/3024-day.css";
import "./codemirror.css";

import React, { useEffect, useRef, useState } from "react";
import { UnControlled as CodeMirror } from "react-codemirror2";
import { arrayMove, arrayRemove, List } from "react-movable";
import { useSelector } from "react-redux";

import { createStyles, makeStyles } from "@material-ui/styles";

import { firestore } from "../firebaseUtil/firebaseUtil";
import { RootState } from "../Redux/reducers/_index";
import { phaseBodyIn, phaseIn } from "../utils/utils";
import {
  Icon,
  Button,
  Segment,
  Grid,
  Confirm,
  Transition,
} from "semantic-ui-react";
import DocerEditCat from "./docer.editCat";
import DocerEditDoc from "./docer.editDoc";
import DocerDemo from "./docer.demo";

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    mini: { transform: "scale(0.5)" },
    flexHorizontal: { display: "flex", width: "100%", paddingBottom: "10px" },
    catTitle: { flex: "1 1 auto" },
    catLevel: { flex: "0 0 120px", paddingLeft: "15px" },
    flexWrapper: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      minWidth: "1024px",
      position: "absolute",
      top: 55,
      bottom: 0,
      paddingTop: "10px",
      paddingBottom: "10px",
      background: "#f5f5f5",
    },
    buttons: {
      display: "flex",
      width: "100%",
      flex: "0 0 30px",
    },
    button: { flex: "1 1 auto", padding: "3px" },
    changed: {
      position: "absolute",
      right: 5,
      color: "red",
      fontWeight: "bold",
      fontSize: "13px",
    },
    controls: {
      flex: "0 0 400px",
      padding: "0px 15px 0px 15px",
      display: "flex",
      flexDirection: "column",
      marginRight: "8px",
    },
    editor: {
      flex: "1 1 auto",
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 10,
      padding: "10px",
      overflow: "auto",
      marginTop: "0px !important",
    },
    formControl: {
      marginBottom: "20px",
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: "5px",
    },
    textButton: {
      textAlign: "left",
      width: "100%",
      height: "30px",
      marginLeft: "5px",
      fontSize: "18px",
      justifyContent: "flex-start",
    },
    wrapper: {
      marginTop: "12px",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      overflow: "auto",
      marginBottom: "0px !important",
    },
    listWrapper: {
      position: "relative",
      overflowY: "auto",
      flex: "1 1 auto",
    },
    catWrapper: {
      position: "relative",
      overflow: "auto",
      flex: "1 1 auto",
      height: "310px",
    },
    titleWrapper: { display: "flex", width: "100%", marginBottom: "3px" },
    titlePlus: { flex: "0 0 auto" },
    title: {
      flex: "1 1 auto",
      fontWeight: "bold",
      marginBottom: "5px",
      fontSize: "16px",
      marginTop: "4px",
    },
    doneword: {
      background: "#ffffee",
      marginBottom: "4px",
      border: "1px solid lightgrey",
      borderRadius: "5px",
      fontSize: "16px",
      paddingRight: "8px",
      paddingLeft: "8px",
      alignItems: "center",
      paddingBottom: "3px",
      display: "flex",
      flexDirection: "row",
      "&.activeList": { background: "#ffeeee" },
      "&.done": { background: "#eeffee" },
      "&.waiting": { background: "#ffffee" },
    },
    selword: {
      background: "#eeeeff",
      marginBottom: "4px",
      border: "1px solid lightgrey",
      borderRadius: "5px",
      fontSize: "16px",
      paddingRight: "8px",
      paddingLeft: "8px",
      alignItems: "baseline",
      paddingBottom: "3px",
      display: "flex",
      flexDirection: "row",
      "&.activeList": { background: "#ffeeee" },
      "&.done": { background: "#eeffee" },
      "&.waiting": { background: "#ffffee" },
    },
    donelist: {
      background: "#eeffee",
      marginBottom: "4px",
      border: "1px solid lightgrey",
      borderRadius: "5px",
      fontSize: "16px",
      paddingRight: "8px",
      paddingLeft: "8px",
      alignItems: "baseline",
      paddingBottom: "3px",
      display: "flex",
      flexDirection: "row",
      "&.activeList": { background: "#ffeeee" },
      "&.done": { background: "#eeffee" },
      "&.waiting": { background: "#ffffee" },
    },
    sellist: {
      background: "#eeeeff",
      marginBottom: "4px",
      border: "1px solid lightgrey",
      borderRadius: "5px",
      fontSize: "16px",
      paddingRight: "8px",
      paddingLeft: "8px",
      alignItems: "baseline",
      paddingBottom: "3px",
      display: "flex",
      flexDirection: "row",
      "&.activeList": { background: "#ffeeee" },
      "&.done": { background: "#eeffee" },
      "&.waiting": { background: "#ffffee" },
    },
    num: {
      fontSize: "13px",
      flex: "0 0 20px",
      paddingTop: "3px",
      paddingRight: "8px",
      paddingBo: "3px",
    },
    Title: { flex: "1 1 auto" },
    cat: { marginBottom: "0px !important" },
    adminHeader: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    headerIcon: {
      width: "50px",
    },
    headerText: {
      flex: "1 1 auto",
    },
    topSegment: {
      marginBottom: "0px !important",
      borderBottom: "0px solid white !important",
      paddingTop: "8px !important",
      paddingBottom: "8px !important",
      height: "54px",
      display: "flex !important",
      alignItems: "center !important",
      borderBottomLeftRadius: "0px !important",
      borderBottomRightRadius: "0px !important",
    },
    cardIconTitle: {
      paddingTop: "0px",
      marginBottom: "0px",
      width: "100%",
      marginTop: "0px",
    },
    bottom: {
      marginTop: "0px !important",
      boxShadow: "none !important",
      height: "100%",
    },
    name: { flex: "1 1 auto" },
  })
);

const Docer = (props: any) => {
  const classes = useStyles();

  const [lists, setLists] = useState<any>([]);
  const [thisListId, setThisListId] = useState<any>("");
  const [thisListTitle, setThisListTitle] = useState<any>("");
  const [thisTitle, setThisTitle] = useState("");
  const [thisLevel, setThisLevel] = useState("A1");
  const [thisId, setThisId] = useState("");
  const [thisPos, setThisPos] = useState(0);
  const [listPos, setListPos] = useState(0);
  const [confirm, setConfirm] = useState<any>({
    open: false,
    text: "",
    confirmed: null,
    cancelText: "Cancel",
    okText: "OK",
  });
  const [editCat, setEditCat] = useState<any>({ open: false, new: false });
  const [editDoc, setEditDoc] = useState<any>({ open: false, new: false });
  const [viewerOpen, setViewerOpen] = useState(false);

  const [mounted, setMounted] = useState(false);

  const [listsChanged, setListsChanged] = useState(false);
  const [currentListChanged, setCurrentListChanged] = useState(false);
  const [textChanged, setTextChanged] = useState(false);

  const [activeList, setActiveList] = useState<any[]>([]);

  const editorRef = useRef<any>(null);

  const currentUser = useSelector<RootState, any>((state) => state.currentUser);
  const ccodeGlobal = useSelector<RootState, any>((state) => state.ccodeGlobal);

  // useEffect(() => {
  //   if (loading) {
  //     editorRef.current.doc.setValue("Loading...");
  //   }
  // }, [loading]);

  useEffect(() => {
    if (!ccodeGlobal || !currentUser) {
      return;
    }
    phaseBodyIn();
    phaseIn();

    if (ccodeGlobal !== "06ab17c7-b847-4c01-aa58-430cb94e0ac2") {
      firestore
        .doc(`/companies/${ccodeGlobal}/data/customize`)
        .get()
        .then((res) => {
          setLists([...(res.data()?.lists?.texts || [])]);
          setThisListId("");
        });
    } else {
      firestore
        .doc(`/textlists/list`)
        .get()
        .then((res) => {
          setLists([...(res.data()?.texts || [])]);
          setThisListId("");
        });
    }
  }, [currentUser, ccodeGlobal]);

  const loadList = async (newId: any) => {
    let index = lists.findIndex((item: any) => item.id === newId);
    let item = lists[index];
    let gone: any;

    if (item) {
      gone = await firestore.doc(`/textlists/${item.id}`).get();
    } else {
      gone = undefined;
    }

    if (gone?.data()) {
      setActiveList(gone.data().texts);
      setThisListId(item.id);
      setThisListTitle(item.title);
    } else {
      setActiveList([]);
      setThisListId(item?.id || "");
      setThisListTitle(item?.title || "");
    }

    setListPos(index);
    setThisTitle("");
    setThisPos(0);
    setThisLevel("A1");
    setThisId("");

    setMounted(false);
    setTimeout(() => editorRef.current.doc.setValue(""), 0);
  };

  const setNewText = async (item: any) => {
    let newText: any;

    newText = await firestore.doc(`/texts/${item.id}`).get();

    setMounted(false);

    setTimeout(() => {
      editorRef.current.doc.setValue(
        (newText.data()?.text || "").replace(/\|/g, "\n")
      );
    }, 0);

    setThisTitle(newText.data()?.title || item.title);
    setThisLevel(newText.data()?.level || item.level);
    setThisPos(activeList.findIndex((text: any) => text.id === item.id));
    setThisId(newText.data()?.id || item.id);
  };

  const onPaste = (editor: any, event: any) => {
    event.preventDefault();

    const clipboardData = event.clipboardData;
    let pastedData: string = clipboardData.getData("Text");
    pastedData = pastedData
      .replace(/[\r\n]/g, "\n")
      .replace(/\n */g, "\n")
      .replace(/ *\n/g, "\n")
      .replace(/\n{2,}/g, "\n");

    editorRef.current.doc.replaceSelection(
      pastedData.replace(/\|/g, "\n"),
      "end"
    );
  };

  const saveListList = async () => {
    if (ccodeGlobal !== "06ab17c7-b847-4c01-aa58-430cb94e0ac2") {
      await firestore.doc(`/companies/${ccodeGlobal}/data/customize`).set(
        {
          lists: {
            texts: lists.map((item: any) => {
              delete item.changed;
              return item;
            }),
          },
        },
        { merge: true }
      );
    } else {
      await firestore.doc(`/textlists/list`).set(
        {
          texts: lists.map((item: any) => {
            delete item.changed;
            return item;
          }),
        },
        { merge: true }
      );
    }
    setListsChanged(false);
  };

  const saveCurrentList = async () => {
    if (thisListId) {
      const batch = firestore.batch();

      batch.set(
        firestore.doc(`/textlists/${thisListId}`),
        {
          id: thisListId,
          ccode: ccodeGlobal,
          texts: activeList.map((item) => {
            batch.set(
              firestore.doc(`/texts/${item.id}`),
              { title: item.title, locked: item.locked || false },
              { merge: true }
            );
            delete item.changed;
            return item;
          }),
        },
        { merge: true }
      );

      await batch.commit();

      setCurrentListChanged(false);
    }
  };

  const saveText = async () => {
    if (thisId) {
      firestore.doc(`/texts/${thisId}`).set(
        {
          ccode: ccodeGlobal,
          id: thisId,
          title: thisTitle,
          level: thisLevel,
          text: editorRef.current.doc.getValue(),
        },
        { merge: true }
      );
      setTextChanged(false);
    }
  };

  // ? Category add and change

  const addCategory = (id: string, title: string, locked: string) => {
    setLists([...lists, { id, title, locked }]);
    setListsChanged(true);
  };

  const changeCategory = (id: string, title: string, locked: string) => {
    setLists(
      lists.map((item: any) => {
        if (item.id === id) {
          return { ...item, title, locked };
        } else {
          return item;
        }
      })
    );

    if (thisListId === id) {
      setThisListTitle(title);
    }

    setListsChanged(true);
  };

  // ? Text add and change

  const addText = (id: string, title: string, level: string) => {
    setActiveList([...activeList, { id, title, level }]);
    setCurrentListChanged(true);
  };

  const changeText = (id: string, title: string, level: string) => {
    setActiveList(
      activeList.map((item: any) => {
        if (item.id === id) {
          return { ...item, title, level };
        } else {
          return item;
        }
      })
    );

    if (thisId === id) {
      setThisTitle(title);
    }
    setCurrentListChanged(true);
  };

  // ? lock list item

  const lockListDone = (value: any) => {
    setLists(
      lists.map((item: any) => {
        if (item.id !== value.id) {
          return item;
        } else {
          return { ...item, locked: !!!item.locked };
        }
      })
    );
    setConfirm({ ...confirm, open: false });
    setListsChanged(true);
  };

  const lockListConfirm = (value: any, e: any) => {
    e.stopPropagation();
    setConfirm({
      open: true,
      text: `Do you really want to ${
        value.locked ? "unlock" : "lock"
      } this list (${value.title})? ${
        value.locked ? "Everybody" : "Only people with authorization"
      } will be able to access it.`,
      confirmed: () => lockListDone(value),
      cancelText: "Cancel",
      okText: value.locked ? "Unlock" : "Lock",
    });
  };

  // ? delete list item

  const deleteListDone = (value: any) => {
    setLists(lists.filter((item: any) => item.id !== value.id));
    setConfirm({ ...confirm, open: false });
    setListsChanged(true);
  };

  const deleteListConfirm = (value: any, e: any) => {
    e.stopPropagation();
    setConfirm({
      open: true,
      text: `Do you really want to delete this list (${value.title})? You will loose all texts connected to it.`,
      confirmed: () => deleteListDone(value),
      cancelText: "Cancel",
      okText: "Delete",
    });
  };

  // ? delete title item

  const deleteTitleDone = (value: any) => {
    setActiveList(activeList.filter((item: any) => item.id !== value.id));
    setConfirm({ ...confirm, open: false });
    setCurrentListChanged(true);
  };

  const deleteTitleConfirm = (value: any, e: any) => {
    e.stopPropagation();
    setConfirm({
      open: true,
      text: `Do you really want to delete this text (${value.title})?`,
      confirmed: () => deleteTitleDone(value),
      cancelText: "Cancel",
      okText: "Delete",
    });
  };

  // ? edit category name

  const editCategory = (value: any, e: any) => {
    e.stopPropagation();
    setEditCat({
      open: true,
      new: false,
      value: value,
      action: changeCategory,
      lists: lists,
    });
  };

  // ? start Add category

  const startAddCategory = () => {
    setEditCat({
      open: true,
      new: true,
      value: {},
      action: addCategory,
      lists: lists,
    });
  };

  // ? edit text name

  const editText = (value: any, e: any) => {
    e.stopPropagation();
    setEditDoc({
      open: true,
      new: false,
      value: value,
      action: changeText,
      activeList: activeList,
    });
  };

  // ? start Add list

  const startAddText = () => {
    setEditDoc({
      open: true,
      new: true,
      value: {},
      action: addText,
      activeList: activeList,
    });
  };

  // ? open viewer

  const openViewer = () => {
    firestore
      .doc(`/texts/${ccodeGlobal}`)
      .set(
        {
          ccode: ccodeGlobal,
          id: thisId,
          title: thisTitle,
          level: thisLevel,
          text: editorRef.current.doc.getValue(),
        },
        { merge: true }
      )
      .then(() => {
        setViewerOpen(true);
      });
  };

  return (
    <React.Fragment>
      <Segment className={classes.topSegment} inverted>
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <div className={classes.adminHeader}>
                <div className={classes.headerIcon}>
                  <Icon name="book" />
                </div>
                <div className={classes.headerText}>
                  <h4 className={classes.cardIconTitle}>Custom Texts</h4>
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <div className={classes.flexWrapper}>
        <div className={classes.controls}>
          <Segment
            className={classes.cat}
            color="olive"
            style={{
              pointerEvents:
                currentListChanged || textChanged ? "none" : "auto",
            }}
            disabled={currentListChanged || textChanged}
          >
            <div className={classes.titleWrapper}>
              <div className={classes.title}>Categories (Max. 10)</div>
              <div className={classes.titlePlus}>
                <Button
                  icon="save"
                  disabled={!listsChanged}
                  onClick={saveListList}
                  size="tiny"
                  color="olive"
                  compact
                />
                <Button
                  icon="add"
                  disabled={lists.length > 9}
                  onClick={startAddCategory}
                  size="tiny"
                  color="olive"
                  compact
                />
              </div>
            </div>
            <div className={classes.catWrapper}>
              <List
                values={lists}
                removableByMove={true}
                onChange={({ oldIndex, newIndex }) => {
                  if (newIndex === -1) {
                    if (oldIndex === lists.length - 1) {
                      const nArray = [...lists];
                      nArray.pop();
                      setTimeout(() => {
                        setLists(nArray);
                      }, 0);
                    } else {
                      setLists(arrayRemove(lists, oldIndex));
                    }
                    if (listPos === oldIndex) {
                      setThisTitle("");
                      setThisId("");
                      setThisLevel("A1");
                      setListPos(0);
                      setThisListId("");
                      loadList("");
                      editorRef.current.doc.setValue("");
                    }
                    if (oldIndex < thisPos) {
                      setListPos(listPos - 1);
                    }
                    return;
                  }
                  setLists(arrayMove(lists, oldIndex, newIndex));

                  if (oldIndex === listPos) {
                    setListPos(newIndex);
                  }
                  if (oldIndex < listPos && newIndex >= listPos) {
                    setListPos(listPos - 1);
                  }
                  if (oldIndex > listPos && newIndex <= listPos) {
                    setThisPos(listPos + 1);
                  }
                }}
                renderList={({ children, props }) => (
                  <div {...props}>{children}</div>
                )}
                renderItem={({ value, props, index }: any) => (
                  <div
                    {...props}
                    key={value.id}
                    className={
                      thisListId && index === listPos
                        ? classes.sellist
                        : classes.donelist
                    }
                    onClick={() => loadList(value.id)}
                  >
                    <div className={classes.num} data-movable-handle>
                      <Icon name="bars" size="small" />
                    </div>
                    <div className={classes.name}>{value.title}</div>
                    <Icon
                      name={"edit"}
                      size="small"
                      link
                      onClick={(e: any) => editCategory(value, e)}
                    />
                    <Icon
                      name={value.locked ? "lock" : "unlock"}
                      size="small"
                      color={value.locked ? "black" : "green"}
                      link
                      onClick={(e: any) => lockListConfirm(value, e)}
                    />
                    <Icon
                      link
                      float="right"
                      name="close"
                      color="red"
                      size="small"
                      onClick={(e: any) => deleteListConfirm(value, e)}
                    />
                  </div>
                )}
              />
            </div>
          </Segment>
          <Segment
            className={classes.wrapper}
            color="olive"
            style={{
              pointerEvents: listsChanged || textChanged ? "none" : "auto",
            }}
            disabled={listsChanged || textChanged}
          >
            {thisListTitle ? (
              <div className={classes.titleWrapper}>
                <div className={classes.title}>{thisListTitle}</div>
                <div className={classes.titlePlus}>
                  <Button
                    icon="save"
                    disabled={!currentListChanged}
                    onClick={saveCurrentList}
                    size="tiny"
                    color="olive"
                    compact
                  />
                  <Button
                    icon="add"
                    onClick={startAddText}
                    size="tiny"
                    color="olive"
                    compact
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            <div className={classes.listWrapper}>
              <List
                values={activeList}
                removableByMove={true}
                onChange={({ oldIndex, newIndex }) => {
                  if (newIndex === -1) {
                    if (oldIndex === activeList.length - 1) {
                      const nArray = [...activeList];
                      nArray.pop();
                      setTimeout(() => {
                        setActiveList(nArray);
                      }, 0);
                    } else {
                      setActiveList(arrayRemove(activeList, oldIndex));
                    }
                    if (thisPos === oldIndex) {
                      setThisTitle("");
                      setThisId("");
                      setThisLevel("A1");
                      setThisPos(0);
                      editorRef.current.doc.setValue("");
                    }
                    if (oldIndex < thisPos) {
                      setThisPos(thisPos - 1);
                    }
                    return;
                  }
                  setActiveList(arrayMove(activeList, oldIndex, newIndex));

                  if (oldIndex === thisPos) {
                    setThisPos(newIndex);
                  }
                  if (oldIndex < thisPos && newIndex >= thisPos) {
                    setThisPos(thisPos - 1);
                  }
                  if (oldIndex > thisPos && newIndex <= thisPos) {
                    setThisPos(thisPos + 1);
                  }
                }}
                renderList={({ children, props }) => (
                  <div {...props}>{children}</div>
                )}
                renderItem={({ value, props, index }: any) => (
                  <div
                    {...props}
                    key={value.id}
                    className={
                      thisId && index === thisPos
                        ? classes.selword
                        : classes.doneword
                    }
                    onClick={() => setNewText(value)}
                  >
                    <div className={classes.num} data-movable-handle>
                      <Icon name="bars" size="small" />
                    </div>
                    <div className={classes.name}>{value.title}</div>
                    <Icon
                      name={"edit"}
                      size="small"
                      link
                      onClick={(e: any) => editText(value, e)}
                    />
                    <Icon
                      link
                      float="right"
                      name="close"
                      color="red"
                      size="small"
                      onClick={(e: any) => deleteTitleConfirm(value, e)}
                    />
                  </div>
                )}
              />
            </div>
          </Segment>
        </div>
        <Segment
          className={classes.editor}
          color="olive"
          style={{
            overflow: "hidden",
            pointerEvents: currentListChanged || listsChanged ? "none" : "auto",
          }}
          disabled={currentListChanged || listsChanged}
        >
          <div className={classes.titleWrapper}>
            <div className={classes.title}>Text</div>
            <div className={classes.titlePlus}>
              <Button
                icon="save"
                disabled={!textChanged}
                onClick={saveText}
                size="tiny"
                color="olive"
                compact
              />
              <Button
                color="olive"
                size="tiny"
                compact
                onClick={openViewer}
                disabled={!thisId}
              >
                <Icon name="eye" />
                Preview
              </Button>
            </div>
          </div>
          {/*<div className={classes.flexHorizontal}>
            <div className={classes.catTitle}>
              <div className={classes.title}>Dokumentum címe</div>
              <Field
                onChange={changeTitle}
                value={thisTitle}
                fullWidth
                id="standard-basic"
                label=""
              />
            </div>
            <div className={classes.catLevel}>
              <div className={classes.title}>Nehézség</div>
              <FormControl fullWidth className={classes.formControl}>
                <Select
                  native
                  value={thisLevel}
                  onChange={changeLevel}
                  inputProps={{
                    name: "cat1",
                    id: "cat1",
                  }}
                >
                  <option value="A1">A1</option>
                  <option value="A2">A2</option>
                  <option value="B1">B1</option>
                  <option value="B2">B2</option>
                </Select>
              </FormControl>
            </div>
          </div>*/}
          <Segment
            style={{ height: "100%", overflow: "auto", marginTop: "0px" }}
          >
            <CodeMirror
              value={""}
              options={{
                mode: "null",
                theme: "3024-day",
                lineNumbers: true,
                lineWrapping: true,
                readOnly: thisId ? false : "nocursor",
              }}
              onChange={(editor, data, value) => {
                if (mounted) {
                  setTextChanged(true);
                } else {
                  setMounted(true);
                }
              }}
              editorDidMount={(editor: any) => (editorRef.current = editor)}
              onPaste={onPaste}
            />
          </Segment>
        </Segment>
        <Transition visible={confirm.open} animation="scale" duration={250}>
          <Confirm
            open={confirm.open}
            onCancel={() => setConfirm({ ...confirm, open: false })}
            onConfirm={confirm.confirmed}
            cancelButton={confirm.cancelText}
            confirmButton={confirm.okText}
            content={confirm.text}
          />
        </Transition>
        <Transition
          unmountOnHide
          visible={editCat.open}
          animation="scale"
          duration={250}
        >
          <DocerEditCat editCat={editCat} setEditCat={setEditCat} />
        </Transition>
        <Transition
          unmountOnHide
          visible={editDoc.open}
          animation="scale"
          duration={250}
        >
          <DocerEditDoc editDoc={editDoc} setEditDoc={setEditDoc} />
        </Transition>
        <Transition
          unmountOnHide
          visible={viewerOpen}
          animation="scale"
          duration={250}
        >
          <DocerDemo viewerOpen={viewerOpen} setViewerOpen={setViewerOpen} />
        </Transition>
      </div>
    </React.Fragment>
  );
};

export default Docer;
