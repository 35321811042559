import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "Redux/reducers/_index";
import { setCreationOne } from "Redux/reducers/reducer.creation";
import { setDicOne } from "Redux/reducers/reducer.dictionary";
import store from "Redux/store";

import { createStyles, makeStyles } from "@material-ui/styles";
import { Popup } from "semantic-ui-react";

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    word: { paddingBottom: "25px", display: "flex", flexDirection: "row" },
    none: { display: "none" },
    eng: {
      fontSize: "13px",
      overflow: "auto",
      display: "flex",
      flexDirection: "row",
      paddingLeft: "25px",
      minWidth: "150px",
      flexWrap: "wrap",
    },
    engvalue: {},
    engItem: { lineHeight: 1.2 },
    hunItem: { lineHeight: 1.4, color: "grey" },
    engPair: {
      paddingBottom: "8px",
      paddingLeft: "8px",
      minWidth: "150px",
      borderLeft: "0.5px solid lightgrey",
    },
    input: {},
    blueinput: { background: "lightblue" },
    orig: { width: "200px", minWidth: "200px" },
    trans: { minWidth: "230px" },
  })
);

const caps = ["I", "OK"];

const CreatorWord = React.memo((props: any) => {
  const classes = useStyles();
  // const [localId, setLocalId] = useState("");
  const [engi, setEngi] = useState("");
  const [hungi, setHungi] = useState("");
  const [entries, setEntries] = useState<any[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const engInputRef = useRef<HTMLInputElement>(null);

  const thisWord = useSelector<RootState, any>(
    (state) => state.creation?.[props.scount + "-" + props.wcount]
  );

  const dicId = useSelector<RootState, any>((state) => state.dic?.[engi]);

  const setLoanId = useCallback(
    (newId: string) => {
      store.dispatch(
        setCreationOne({
          id: props.scount + "-" + props.wcount,
          value: {
            ...store.getState().creation[props.scount + "-" + props.wcount],
            id: newId,
            s:
              store
                .getState()
                .creation[props.scount + "-" + props.wcount]?.s?.slice(0, 40) ||
              [],
          },
        })
      );
    },
    [props.scount, props.wcount]
  );

  useEffect(() => {
    setEntries(
      (dicId || []).map((item: any) => store.getState().dic[item] || [])
    );
  }, [dicId]);

  useEffect(() => {
    let iWord = thisWord.baseWord;
    if (iWord.includes("'s")) {
      if (iWord !== "let's") {
        iWord = iWord.slice(0, -2);
      }
    }

    if (thisWord.origId === "ASNAME") {
      setLoanId(thisWord.origId);
      setEngi(iWord);
      setHungi(iWord);
      setEntries([
        { id: "ASNAME", head: "NAME", d: "NAME", short: "NAME", word: "NAME" },
      ]);
      return;
    }

    if (thisWord.origId === "ASNO") {
      setLoanId(thisWord.origId);
      setEngi(iWord);
      setHungi(iWord);
      setEntries([
        {
          id: "ASNO",
          head: "NOTRANS",
          d: "NOTRANS",
          short: "NOTRANS",
          word: "NOTRANS",
        },
      ]);
      return;
    }

    if (thisWord.origId) {
      setLoanId(thisWord.origId);
      setEngi(store.getState().dic[thisWord.origId]?.head || "");
      setHungi(store.getState().dic[thisWord.origId]?.d || "");
      setEntries(
        store.getState().dic[thisWord.origId]
          ? [store.getState().dic[thisWord.origId]]
          : []
      );
      return;
    }

    if (iWord === "a") {
      iWord = "a, an";
    }

    if (store.getState().dic[iWord]) {
      const temp = store.getState().dic[iWord];

      setLoanId(temp[0]);
      setHungi(store.getState().dic[temp[0]].d);
      setEntries(temp.map((item: any) => store.getState().dic[item]));
      iWord = store.getState().dic[temp[0]].head;
    } else {
      setLoanId("");
      setEntries([]);
      setHungi("");
    }

    setEngi(caps.includes(iWord) ? iWord : iWord.toLowerCase());
  }, [thisWord.origId, thisWord.baseWord, props.loaded, setLoanId]);

  // useEffect(() => {
  //   setLoanId(localId);
  //   if (thisWord.word === "and") {
  //     console.log(store.getState().dic["and"]);
  //   }
  // }, [localId, setLoanId, thisWord.word]);

  const changeEng = (e: any) => {
    const text = e.currentTarget.value;
    const theseIds = store.getState().dic[text];
    setEngi(text);
    setEntries((theseIds || []).map((item: any) => store.getState().dic[item]));

    if (store.getState().dic[text]) {
      setLoanId(theseIds[0]);
      setHungi(store.getState().dic[theseIds[0]].d);
    } else {
      setLoanId("");
    }
  };

  const changeHun = (e: any) => {
    setHungi(e.currentTarget.value.replace(".", ""));
    if (e.currentTarget.value.includes(".")) {
      let newDicWord: any[] = [];

      let changed = false;

      const mySentence = props.getSentence(props.scount, props.wcount);

      (store.getState().dic[engi] || [])
        .map((item: any) => store.getState().dic[item])
        .forEach((item: any) => {
          if (item.element === props.scount * 1000 + props.wcount) {
            changed = true;
            newDicWord.push(
              "AS" + props.title + (1000 * props.scount + props.wcount)
            );
          } else {
            newDicWord.push(item.id);
          }
        });
      if (!changed) {
        newDicWord.push(
          "AS" + props.title + (props.scount * 1000 + props.wcount)
        );
      }

      store.dispatch(setDicOne({ id: engi, value: newDicWord }));

      store.dispatch(
        setDicOne({
          id: "AS" + props.title + (props.scount * 1000 + props.wcount),
          value: {
            id: "AS" + props.title + (props.scount * 1000 + props.wcount),
            word: engi,
            short: engi,
            head: engi,
            d: hungi.replace(".", ""),
            source: "new",
            withId: true,
            element: props.scount * 1000 + props.wcount,
            s: [mySentence + "×"],
          },
        })
      );
      setTimeout(
        () =>
          setEntries(newDicWord.map((item: any) => store.getState().dic[item])),
        0
      );

      setLoanId("AS" + props.title + (props.scount * 1000 + props.wcount));
      // } else {
      //   setLocalId("");
      // }
    }
  };

  const hungiFound = () => {
    let res = false;

    entries.forEach((item) => {
      if (item.d === hungi) {
        res = true;
      }
    });
    return res || thisWord.id === "ASNAME" || thisWord.id === "ASNO";
  };

  const loadDic = (e: any) => {
    window.open(
      `http://szotar.sztaki.hu/search?fromlang=eng&tolang=hun&searchWord=${encodeURI(
        engi
      )}&langcode=hu`,
      "sztaki1"
    );
  };

  const setChoice = (index: number) => {
    setHungi(entries[index].d);
    setLoanId(entries[index].id);
  };

  const newEntries: any[] = [];
  let allEntries = "";

  entries.forEach((item, index) => {
    if (!allEntries.includes("|" + item.id + "|")) {
      newEntries.push({ ...item, index });
      allEntries = allEntries + "|" + item.id + "|";
    }
  });

  const makeName = () => {
    setEngi(thisWord.word.replace(/[‘.?!$():;,"]/g, ""));
    setHungi(thisWord.word.replace(/[‘.?!$():;,"]/g, ""));
    setLoanId("ASNAME");
    setEntries([
      { d: thisWord.word, word: thisWord.word, short: "NAME", id: "ASNAME" },
    ]);
  };

  const makeNoTranslate = () => {
    setEngi(thisWord.word.replace(/[‘.?!$():;,"]/g, ""));
    setHungi(thisWord.word.replace(/[‘.?!$():;,"]/g, ""));
    setLoanId("ASNO");
    setEntries([
      { d: thisWord.word, word: thisWord.word, short: "NOTRANS", id: "ASNO" },
    ]);
  };

  return (
    <React.Fragment>
      <div className={thisWord.word ? classes.word : classes.none}>
        <div
          className={classes.orig}
          onDoubleClick={() => props.openWord(thisWord)}
        >
          {thisWord.word}
          <br />
          <div
            onClick={props.openDlg}
            style={{ fontSize: "11px", color: "grey", userSelect: "auto" }}
          >
            {thisWord.id}
          </div>
        </div>

        <div className={classes.engvalue}>
          <input
            className={newEntries.length ? classes.blueinput : classes.input}
            type="text"
            value={engi}
            onChange={changeEng}
            ref={engInputRef}
            onDoubleClick={loadDic}
          />
        </div>

        <div
          id={"avh" + props.scount + "-" + props.wcount}
          className={classes.trans}
        >
          <input
            onBlur={() => {}}
            className={hungiFound() ? classes.blueinput : classes.input}
            type="text"
            value={hungi}
            onChange={changeHun}
            ref={inputRef}
          />

          <button onClick={makeName}>N</button>
          <button onClick={makeNoTranslate}>X</button>
        </div>
        <div className={classes.eng}>
          {newEntries.map((item, index) => (
            <div
              className={classes.engPair}
              key={index}
              onClick={() => {
                setChoice(item.index);
              }}
            >
              <div className={classes.engItem}>
                {item.short || "-"} {item.word?.split("+")[1]?.split("*")?.[0]}
              </div>
              <Popup
                content={
                  <React.Fragment>
                    {[
                      ...(item?.cards || []).map((i: any) => i.english),
                      ...(item?.s || []),
                    ]
                      ?.slice(0, 8)
                      .map((sen: string, i: number) => (
                        <div key={i}>{sen.split("[")[0]}</div>
                      )) || ""}
                  </React.Fragment>
                }
                trigger={<div className={classes.hunItem}>{item.d}</div>}
              />
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
});

export default CreatorWord;
