import React from "react";
import { Form, Dropdown, Segment } from "semantic-ui-react";
import Picker from "./customize.picker";
import FontPicker from "font-picker-react";
// import { makeStyles, createStyles } from "@material-ui/styles";

// export const useStyles = makeStyles((theme: any) =>
//   createStyles({
//     text: {},
//   })
// );

const variableFonts = [
  "Assistant",
  "Bitter",
  "Cabin",
  "Catamaran",
  "Changa",
  "Cinzel",
  "Comfortaa",
  "Commissioner",
  "Crimson Pro",
  "Cuprum",
  "Dancing Script",
  "Domine",
  "Dosis",
  "EB Garamond",
  "Encode Sans",
  "Epilogue",
  "Exo",
  "Exo 2",
  "Faustina",
  "Fira Code",
  "Grandstander",
  "Grenze Gotisch",
  "Heebo",
  "Hepta Slab",
  "Inconsolata",
  "Inter",
  "Josefin Sans",
  "Jost",
  "Jura",
  "Kreon",
  "Kufam",
  "Lemonada",
  "Literata",
  "Lora",
  "Manrope",
  "Manuale",
  "Markazi Text",
  "Maven Pro",
  "Merriweather Sans",
  "Mulish",
  "MuseoModerno",
  "Orbitron",
  "Oswald",
  "Oxanium",
  "Petrona",
  "Piazzolla",
  "Playfair Display",
  "Podkova",
  "Public Sans",
  "Quicksand",
  "Raleway",
  "Recursive",
  "Red Rose",
  "Roboto Mono",
  "Roboto Slab",
  "Rokkitt",
  "Rosario",
  "Rubik",
  "Ruda",
  "Sansita Swashed",
  "Signika",
  "Sora",
  "Spartan",
  "Syne",
  "Trispace",
  "Varta",
  "Vollkorn",
  "Work Sans",
  "Yanone Kaffeesatz",
];

const ColorTab = (props: any) => {
  // const classes = useStyles();

  const {
    pickers,
    setPickers,
    changeComplete,
    colors,
    setCustomize,
    customize,
    setSaved,
  } = props;

  return (
    <React.Fragment>
      <Form>
        <Form.Group widths="equal">
          <Picker
            name="primaryBackground"
            pickers={pickers}
            setPickers={setPickers}
            label="Primary Background"
            changeComplete={changeComplete}
            colors={colors}
          />
          <Picker
            name="primaryText"
            pickers={pickers}
            setPickers={setPickers}
            label="Primary Text"
            changeComplete={changeComplete}
            colors={colors}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Picker
            name="secondaryBackground"
            pickers={pickers}
            setPickers={setPickers}
            label="Secondary Background"
            changeComplete={changeComplete}
            colors={colors}
          />
          <Picker
            name="secondaryText"
            pickers={pickers}
            setPickers={setPickers}
            label="Secondary Text"
            changeComplete={changeComplete}
            colors={colors}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Picker
            name="darkText"
            pickers={pickers}
            setPickers={setPickers}
            label="Dark Text"
            changeComplete={changeComplete}
            colors={colors}
          />
          <Picker
            name="lightText"
            pickers={pickers}
            setPickers={setPickers}
            label="Light Text"
            changeComplete={changeComplete}
            colors={colors}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Picker
            name="deepBackground"
            pickers={pickers}
            setPickers={setPickers}
            label="Deep Background"
            changeComplete={changeComplete}
            colors={colors}
          />
          <Picker
            name="paperColor"
            pickers={pickers}
            setPickers={setPickers}
            label="Paper Color"
            changeComplete={changeComplete}
            colors={colors}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <label>Font</label>
            <FontPicker
              apiKey="AIzaSyA8d9SleAVkuA1BwyrcvBypzmt9rFDXFrA"
              activeFontFamily={colors?.font || "Roboto Slab"}
              onChange={(nextFont: any) => {
                setCustomize({
                  ...customize,
                  colors: { ...colors, font: nextFont.family },
                });
                setSaved(false);
              }}
              families={variableFonts}
            />
          </Form.Field>
          <Form.Field>
            <label>Base Font Size</label>
            <Dropdown
              placeholder="size"
              search
              selection
              options={[12, 13, 14, 15, 16, 17, 18, 19, 20].map((item) => ({
                key: item,
                value: item,
                text: String(item),
              }))}
              value={15}
            />
          </Form.Field>
        </Form.Group>

        <Segment
          className={"apply-font"}
          style={{
            fontSize: "18px",
            paddingTop: "0.5em",
            paddingBottom: "0.5em",
          }}
        >
          AaBbCcDdEeFfGgőű
        </Segment>
      </Form>
    </React.Fragment>
  );
};

export default ColorTab;
