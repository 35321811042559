import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Modal,
  Form,
  Checkbox,
  Button,
  Message,
  Placeholder,
} from "semantic-ui-react";
// import { makeStyles, createStyles } from "@material-ui/styles";
import { Transition } from "semantic-ui-react";

// export const useStyles = makeStyles((theme: any) =>
//   createStyles({
//     text: {},
//   })
// );

const DocerEditCat = (props: any) => {
  // const classes = useStyles();
  const titleRef = useRef<any>(null);
  const idRef = useRef<any>(null);
  const lockedRef = useRef<any>(null);
  const [error, setError] = useState("");

  const workIt = () => {
    props.editCat.action(
      idRef.current.value,
      titleRef.current.value,
      lockedRef.current.state.checked
    );

    props.setEditCat({ ...props.editCat, open: false });
  };

  const checkMe = useCallback(() => {
    let tempError = "";
    if (!titleRef.current.value) {
      tempError = "Please add a valid title.";
    }

    if (
      props.editCat.new &&
      props.editCat.lists.find((item: any) => item.id === idRef.current.value)
    ) {
      tempError = tempError + " Use a unique ID.";
    }

    if (!idRef.current.value) {
      tempError = tempError + " Use a unique ID.";
    }
    setError(tempError);
  }, [props.editCat.lists, props.editCat.new]);

  useEffect(() => {
    checkMe();
  }, [checkMe]);

  return (
    <React.Fragment>
      <Modal
        size={"small"}
        open={props.editCat.open}
        onClose={() => props.setEditCat({ ...props.editCat, open: false })}
      >
        <Modal.Header>
          {props.editCat.new ? "Add new category..." : "Change category..."}
        </Modal.Header>

        <Modal.Content>
          <Form>
            <Form.Field required>
              <label>Category Title</label>
              <input
                ref={titleRef}
                name="title"
                onChange={checkMe}
                placeholder="Title..."
                defaultValue={props.editCat.value.title}
              />
            </Form.Field>

            <Form.Field required>
              <label>Category ID</label>
              <input
                ref={idRef}
                onChange={checkMe}
                disabled={!props.editCat.new}
                name="id"
                placeholder="ID..."
                defaultValue={props.editCat.value.id}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                ref={lockedRef}
                onChange={checkMe}
                name="locked"
                toggle
                label="locked"
                defaultChecked={!!props.editCat.value.locked}
              />
            </Form.Field>
          </Form>
          <Transition visible={!!error}>
            <Message color="red">
              {error || (
                <Placeholder>
                  <Placeholder.Line />
                </Placeholder>
              )}
            </Message>
          </Transition>
        </Modal.Content>
        <Modal.Actions>
          <Button
            negative
            onClick={() => {
              props.setEditCat({ ...props.editCat, open: false });
            }}
          >
            Cancel
          </Button>
          <Button positive onClick={workIt}>
            {props.editCat.new ? "Add" : "Save"}
          </Button>
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  );
};

export default DocerEditCat;
