import store from "Redux/store";
import { setOrigXML } from "Redux/reducers/reducer.origXML";
import { firestore } from "firebaseUtil/firebaseUtil";

export const loadLesson = async (lessonIdentifier: string) => {
  const lessonRef = firestore.doc(`originals/${lessonIdentifier}`);

  let lessonData: any;

  try {
    lessonData = await lessonRef.get();
    if (!lessonData.exists) {
      lessonData = null;
    }
  } catch (e) {
    lessonData = null;
  }

  if (lessonData) {
    store.dispatch(
      setOrigXML([
        lessonData.data().xml,
        lessonData.data().updated,
        lessonData.data().cat || "",
      ])
    );
  }

  return lessonData ? lessonData.data() : {};
};
