import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Modal, Button, Transition } from "semantic-ui-react";

const styles = (theme: any) => ({
  content: { padding: "8px" },
  paper: {
    margin: "10px",
  },
  intro: {
    fontSize: "18px",
    fontFamily: "var(--font-normal)",
    lineHeight: "130%",
  },
  actions: {
    paddingTop: "0px",
    paddingBottom: "0px",
  },
});
const useStyles = makeStyles(styles as any);

const TermsDialog = (props: any) => {
  const classes = useStyles();
  const { tx } = props;

  return (
    <React.Fragment>
      <Transition visible={props.open}>
        <Modal
          open={props.open}
          onClose={props.closeDialog}
          onOpen={props.openDialog}
        >
          <Modal.Header>{props.title}</Modal.Header>
          <Modal.Content scrolling>
            <Modal.Description>
              <div className={classes.intro}>{props.help}</div>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={props.closeDialog} primary>
              {tx("brapo2", "Close")}
            </Button>
          </Modal.Actions>
        </Modal>
      </Transition>
    </React.Fragment>
  );
};

export default TermsDialog;
