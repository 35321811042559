import { combineReducers } from "@reduxjs/toolkit";

import appTransPartsReducer from "./reducer.appTransParts";
import authLoadedReducer from "./reducer.authLoaded";
import backdropOpenReducer from "./reducer.backdropOpen";
import bookOpenReducer from "./reducer.bookOpen";
import bookStatusReducer from "./reducer.bookstatus";
import buyNowReducer from "./reducer.buynow";
import checkoutItemReducer from "./reducer.checkoutItem";
import ccodeGlobalReducer from "./reducer.ccodeGlobal";
import confirmOpenReducer from "./reducer.confirmOpen";
import creationReducer from "./reducer.creation";
import currentLessonReducer from "./reducer.currentLesson";
import currentUserReducer from "./reducer.currentUser";
import currentWordReducer from "./reducer.currentWord";
import dialogReducer from "./reducer.dialog";
import dialogFullReducer from "./reducer.dialogFull";
import dictionaryReducer from "./reducer.dictionary";
import helpReducer from "./reducer.help";
import helpTopicsReducer from "./reducer.helptopic";
import hlReducer from "./reducer.hl";
import lessonDataReducer from "./reducer.lessonData";
import lessonListReducer from "./reducer.lessonList";
import lessonStatusReducer from "./reducer.lessonStatus";
import loadingReducer from "./reducer.loading";
import mouseReducer from "./reducer.mouse";
import origXMLReducer from "./reducer.origXML";
import playSpeedReducer from "./reducer.playSpeed";
import positionReducer from "./reducer.position";
import proceedReducer from "./reducer.proceed";
import programReducer from "./reducer.program";
import quizReducer from "./reducer.quiz";
import quizResultReducer from "./reducer.quizResult";
import readerReducer from "./reducer.reader";
import recordingReducer from "./reducer.recordings";
import routedReducer from "./reducer.routed";
import settingsReducer from "./reducer.settings";
import snackReducer from "./reducer.snack";
import statsReducer from "./reducer.stats";
import statusReducer from "./reducer.status";
import textPosReducer from "./reducer.textPos";
import translationReducer from "./reducer.translation";
import transPartsReducer from "./reducer.transParts";
import tutorialReducer from "./reducer.tutorial";
import viewReducer from "./reducer.view";
import wordBarReducer from "./reducer.wordbar";
import wordReducer from "./reducer.words";
import words3000reducer from "./reducer.words3000";

const rootReducer = combineReducers({
  appTransParts: appTransPartsReducer,
  authLoaded: authLoadedReducer,
  backdropOpen: backdropOpenReducer,
  bookOpen: bookOpenReducer,
  bookStatus: bookStatusReducer,
  buyNow: buyNowReducer,
  ccodeGlobal: ccodeGlobalReducer,
  checkoutItem: checkoutItemReducer,
  confirmOpen: confirmOpenReducer,
  creation: creationReducer,
  currentLesson: currentLessonReducer,
  currentUser: currentUserReducer,
  currentWord: currentWordReducer,
  dialog: dialogReducer,
  dialogFull: dialogFullReducer,
  dic: dictionaryReducer,
  help: helpReducer,
  helpTopics: helpTopicsReducer,
  hl: hlReducer,
  lessonDataChange: lessonDataReducer,
  lessonList: lessonListReducer,
  lessonStatus: lessonStatusReducer,
  loading: loadingReducer,
  mouse: mouseReducer,
  origXML: origXMLReducer,
  playSpeed: playSpeedReducer,
  position: positionReducer,
  proceed: proceedReducer,
  program: programReducer,
  quiz: quizReducer,
  quizResult: quizResultReducer,
  reader: readerReducer,
  recordings: recordingReducer,
  routed: routedReducer,
  settings: settingsReducer,
  snack: snackReducer,
  stats: statsReducer,
  status: statusReducer,
  textPos: textPosReducer,
  translation: translationReducer,
  transParts: transPartsReducer,
  tutorial: tutorialReducer,
  view: viewReducer,
  wordbar: wordBarReducer,
  words: wordReducer,
  words3000: words3000reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
