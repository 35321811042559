import React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Menu } from "semantic-ui-react";
import { useHistory } from "react-router";
import { phaseBodyOut } from "../utils/utils";
import { useSelector } from "react-redux";
import { RootState } from "../Redux/reducers/_index";

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    menu: {
      "&>.item": { color: "white !important" },
      "&>.active.item": { borderColor: "white !important" },
    },
  })
);

const DashboardMenu = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  const userType = useSelector<RootState, any>(
    (state) => state.currentUser?.type
  );

  return (
    <React.Fragment>
      <Menu
        pointing
        secondary
        floated="right"
        className={classes.menu}
        activeIndex={props.active}
      >
        {userType === "admin" ? (
          <Menu.Item
            name="Sell"
            active={props.page === "account"}
            onClick={() => history.push("/dashboard/account")}
          />
        ) : (
          ""
        )}
        {userType === "admin" ? (
          <Menu.Item
            name="users"
            active={props.page === "users"}
            onClick={() => history.push("/dashboard/users")}
          />
        ) : (
          ""
        )}

        {userType === "admin" ? (
          <Menu.Item
            name="texts"
            active={props.page === "texts"}
            onClick={() => history.push("/dashboard/texts")}
          />
        ) : (
          ""
        )}
        {userType === "admin" ? (
          <Menu.Item
            name="customize"
            active={props.page === "customize"}
            onClick={() => history.push("/dashboard/customize")}
          />
        ) : (
          ""
        )}
        {userType === "admin" ? (
          <Menu.Item
            name="editor"
            active={props.page === "editor"}
            onClick={() => history.push("/dashboard/editor")}
          />
        ) : (
          ""
        )}
        {userType === "admin" || userType === "contributor" ? (
          <Menu.Item
            name="translator"
            active={props.page === "translator"}
            onClick={() => history.push("/dashboard/translator")}
          />
        ) : (
          ""
        )}
        {userType === "admin" ? (
          <Menu.Item
            name="Glosser"
            active={props.page === "definer"}
            onClick={() => history.push("/dashboard/definer")}
          />
        ) : (
          ""
        )}

        <Menu.Item
          name="log out"
          active={false}
          onClick={() => {
            phaseBodyOut();
            history.push("/login/login");
          }}
        />
      </Menu>
    </React.Fragment>
  );
};

export default DashboardMenu;
