import React, { useState } from "react";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import { makeStyles, createStyles } from "@material-ui/styles";

import LoginCard from "admin/Login/login.card";
import ForgottenCard from "../admin/Forgotten/forgotten.card";
import Footer from "../footer/footer";
import { tx } from "../utils/globalize";
import ChangePasswordCard from "../admin/PasswordChange/changePW.card";
import Signup from "../admin/Signup/signup";
import { SemanticToastContainer } from "react-semantic-toasts";

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    login: {
      overflowY: "auto",
      overflowX: "hidden",
      position: "relative",
      left: 0,
      right: 0,
      top: 0,
      background: "white",
      padding: "20px",
      paddingTop: "40px",
      zIndex: 1,
      opacity: 1,
      transition: "opacity 0.15s, z-index 0s 0.15s",
      maxWidth: "812px",
      "@media (min-width: 813px)": {
        margin: "25px auto",
        paddingLeft: "35px",
        paddingTop: "40px",
        paddingRight: "35px",
        minHeight: "calc(var(--vh) - 50px)",
      },
      margin: "0 auto",
      backgroundColor: "white",
      minHeight: "var(--vh)",
      paddingBottom: "235px",
    },
    copyright: {
      bottom: 10,
      justifySelf: "end",
      flex: "3 1 auto",
      alignItems: "flex-end",
      fontFamily: "var(--font-normal)",
      color: "#888888",
      fontSize: "15px",
      margin: "0 auto",
      paddingTop: "15px",
      paddingBottom: "10px",
      paddingLeft: "20px",
      paddingRight: "20px",
      lineHeight: "130%",
      background: "white",
      textAlign: "center",
      left: 0,
      right: 0,
      position: "absolute",
    },
  })
);

const LoginUi = () => {
  const classes = useStyles();
  let { path, url } = useRouteMatch();
  const [language, setLanguage] = useState("it");

  return (
    <React.Fragment>
      <div className={classes.login}>
        <Switch>
          <Route path={`${path}/login`}>
            <LoginCard />
          </Route>
          <Route path={`${path}/forgotten`}>
            <ForgottenCard />
          </Route>
          <Route path={`${path}/signup`}>
            <Signup language={language} setLanguage={setLanguage} />
          </Route>
          <Route path={`${path}/newpw`}>
            <ChangePasswordCard />
          </Route>
          <Route path={`${path}`}>
            <Redirect to={`${url}/login`} />
          </Route>
        </Switch>
        <div className={classes.copyright}>
          <Footer tx={tx} language={language} />
        </div>
      </div>
      <SemanticToastContainer position="top-center" />
    </React.Fragment>
  );
};

export default LoginUi;
