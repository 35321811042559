import React, { useEffect, useRef, useState } from "react";
// import { useSelector } from "react-redux";
// import { RootState } from "Redux/reducers/_index";
import {
  setBackdrop,
  setBackdropOpen,
} from "Redux/reducers/reducer.backdropOpen";

import { setLessonList } from "Redux/reducers/reducer.lessonList";
import { setMouse } from "Redux/reducers/reducer.mouse";
import store from "Redux/store";
import { loadLessonList } from "utils/saver.loadLessonList";
import { saveFullLessonAsync } from "utils/saver.saveFullLesson";
import { v4 as uuid } from "uuid";

import { firestore } from "../firebaseUtil/firebaseUtil";
import { tx } from "../utils/globalize";
import { readXMLLesson } from "../utils/readxml";
import NewLessonDialog from "./editor.newLessonDialog";
import { Menu, Button, Dropdown } from "semantic-ui-react";
import { setLessonData } from "app/App";
import { setLessonDataChange } from "Redux/reducers/reducer.lessonData";

var beautify_html = require("js-beautify").html;

const EditorControl = (props: any) => {
  const [newLessonDlgOpen, setNewLessonDlgOpen] = useState<boolean>(false);

  const actionRef = useRef(null);
  const insertRef = useRef(null);
  const convertRef = useRef(null);
  // const classes = useStyles();

  // const mouse = useSelector<RootState, any>((state) => state.mouse);

  const { type, cm, setLesson } = props;

  useEffect(() => {
    const preLoad = async () => {
      store.dispatch(
        setBackdrop({
          open: true,
          text: tx("pasdwwsdaypage", "Processing..."),
        })
      );
      const lessonListTemp = await loadLessonList(false, type);
      store.dispatch(setLessonList([...lessonListTemp]));
      setLesson("----");
      cm?.doc?.setValue("");
      store.dispatch(setBackdropOpen(false));
    };

    preLoad();
  }, [type, cm, setLesson]);

  const insertPart = (e: any, data: any) => {
    e.persist();
    let bInsert = "";

    const selection = props.cm.doc.getSelection();
    switch (data.children) {
      case "Lesson":
        bInsert = `<?xml version="1.0" encoding="UTF-8"?><lesson number="1"><lessondata><number>xx</number><seq>xx</seq><title tid="${uuid()}">xx</title><summary tid="${uuid()}">xx</summary></lessondata>${
          "\n" + (selection || "...") + "\n"
        }</lesson>`;
        break;
      case "Text":
        bInsert = `<part id="${uuid()}" type="text">${
          "\n" + (selection || "...") + "\n"
        }</part>`;
        break;
      case "Table":
        bInsert = `<table>${"\n" + (selection || "...") + "\n"}</table>`;
        break;
      case "Conv. section":
        bInsert = `<section format="static-conversation">${
          "\n" + (selection || "...") + "\n"
        }</section>`;
        break;
      case "Cards":
        bInsert = `<part id="${uuid()}" type="card"><intro>...</intro>${
          "\n" + (selection || "...") + "\n"
        }</part>`;
        break;
      case "Conversation":
        bInsert = `<part id="${uuid()}" type="conversation" src="" speakers="">${
          "\n" + (selection || "...") + "\n"
        }</part>`;
        break;
      case "Quiz":
        bInsert = `<part id="${uuid()}" type="quiz">${
          "\n" + (selection || "...") + "\n"
        }</part>`;
        break;
      case "Video":
        bInsert = `<video source=""/>`;
        break;
    }

    const formattedXML = beautify_html(bInsert.replace(/\n+/g, " "), {
      indent_size: 2,
    });
    props.cm.doc.replaceSelection("\n\n");
    props.cm.doc.replaceSelection(formattedXML, "around");
  };

  const convertPart = (e: any, data: any) => {
    e.persist();
    let bInsert = "";
    let bStart = "";
    let bEnd = "";

    const selection = props.cm.doc.getSelection();
    switch (data.children) {
      // @ts-ignore
      case "Text (full)":
        bStart = `<part id="${uuid()}" type="text">`;
        bEnd = `</part>`;
      // eslint-disable-next-line
      case "Text":
        const textLines = selection.split("\n");
        textLines.forEach((line: string, index: number) => {
          let format = "";
          if (line.substr(0, 2) === "==") {
            format = "bigtitle";
            line = line.replace("==", "").trim();
          }
          if (line.substr(0, 1) === "=") {
            format = "smalltitle";
            line = line.replace("=", "").trim();
          }
          if (line.substr(0, 1) === "^") {
            format = "example";
            line = line.replace("^", "").trim();
          }
          if (line.trim()) {
            if (!line.includes("|")) {
              bInsert =
                bInsert +
                `<t format="${format}" tid="${uuid()}">${line.trim()}</t>`;
            } else {
              line = line.replace("|", "");
              bInsert = bInsert + `<t format="${format}">${line.trim()}</t>`;
            }
          }
        });
        bInsert = bStart + bInsert + bEnd;
        break;
      case "Row":
        const rowCells = selection.split("\n");
        rowCells.forEach((cell: string, index: number) => {
          if (cell.trim()) {
            if (cell.includes("|")) {
              cell = cell.replace("|", "");
              bInsert =
                bInsert + `<tcell tid="${uuid()}">${cell.trim()}</tcell>`;
            } else {
              bInsert =
                bInsert + `<tcell eid="${uuid()}">${cell.trim()}</tcell>`;
            }
          }
        });
        bInsert = "<trow>" + bInsert + "</trow>";
        break;
      case "Rows":
        const rowsCells = selection.split("\n");
        bInsert = bInsert + "<trow>";
        rowsCells.forEach((cell: string, index: number) => {
          if (cell.trim()) {
            if (cell.includes("|")) {
              cell = cell.replace("|", "");
              bInsert =
                bInsert + `<tcell tid="${uuid()}">${cell.trim()}</tcell>`;
            } else {
              bInsert =
                bInsert + `<tcell eid="${uuid()}">${cell.trim()}</tcell>`;
            }
          } else {
            bInsert = bInsert + "</trow><trow>";
          }
        });
        bInsert = bInsert + "</trow>";
        break;
      case "Simple Rows":
        const simpleRowsCells = selection.split("\n");
        bInsert = bInsert + "<table>";
        simpleRowsCells.forEach((cell: string, index: number) => {
          if (cell.trim()) {
            bInsert =
              bInsert +
              `<trow><tcell eid="${uuid()}">${cell.trim()}</tcell><tcell tid="${uuid()}">${cell.trim()}</tcell></trow>`;
          }
        });
        bInsert = bInsert + "</table>";
        break;
      case "Header Row":
        const headerCells = selection.split("\n");
        headerCells.forEach((cell: string, index: number) => {
          if (cell.trim()) {
            if (cell.includes("|")) {
              cell = cell.replace("|", "");
              bInsert =
                bInsert + `<tcell tid="${uuid()}">${cell.trim()}</tcell>`;
            } else {
              bInsert =
                bInsert + `<tcell eid="${uuid()}">${cell.trim()}</tcell>`;
            }
          }
        });
        bInsert = "<thead>" + bInsert + "</thead>";
        break;
      case "Quiz":
        bInsert = "";
        const quizLines = selection.split("\n");
        let correct = 0;
        quizLines.forEach((cell: string, index: number) => {
          if (cell.trim()) {
            if (index % 5 === 0) {
              bInsert =
                bInsert +
                `<quiz><description tid="${uuid()}">${cell.trim()}</description>`;
            }
            if (index % 5 === 1) {
              bInsert =
                bInsert + `<question tid="${uuid()}">${cell.trim()}</question>`;
            }

            if (index % 5 > 1) {
              bInsert =
                bInsert + `<answer>${cell.trim().replace("*", "")}</answer>`;
              if (cell.includes("*")) {
                correct = (index % 5) - 2;
              }
            }
            if (index % 5 === 4) {
              bInsert = bInsert + "<correct>" + correct + "</correct></quiz>";
            }
          }
        });

        break;
      case "Conversation":
        const convCells = selection.split("\n");
        const speakers: string[] = [];
        convCells.forEach((cell: string, index: number) => {
          if (cell.trim()) {
            let sex = "-boy";
            const parts = cell.split(":");
            if (parts[0].includes("*")) {
              sex = "-girl";
            }
            bInsert =
              bInsert +
              `<convline eid="${
                uuid() + sex
              }" tid="${uuid()}" speaker="${parts[0]
                .trim()
                .replace("*", "")}">${parts[1].trim()}</convline>`;
            speakers.push(parts[0].trim());
          }
        });
        bInsert = `<part id="${uuid()}" type="conversation" speakers="${speakers[0].replace(
          "*",
          ""
        )}|${speakers[1].replace("*", "")}">${bInsert}</part>`;
        break;
      // @ts-ignore
      case "Cards (full)":
        bStart = `<part id="${uuid()}" type="card"><title tid="${uuid()}">...</title>`;
        bEnd = `</part>`;
      // eslint-disable-next-line
      case "Cards":
        const cardLines = selection.split("\n");
        cardLines.forEach((line: string, index: number) => {
          if (line.trim()) {
            bInsert =
              bInsert +
              `<e eid="${
                uuid() + (line.includes("*") ? "-girl" : "")
              }" tid="${uuid()}">${line.trim().replace("*", "")}</e>`;
          }
        });
        bInsert = bStart + bInsert + bEnd;
        break;
      case "Selector":
        bInsert = "<selector>";
        const selectorLines = selection.split("\n");

        selectorLines.forEach((line: string, index: number) => {
          if (line.trim()) {
            if (index === 0) {
              bInsert = bInsert + `<e>${line.trim()}</e>`;
            }
            if (index === 1) {
              bInsert = bInsert + `<f tid="${uuid()}">${line.trim()}</f>`;
            }
            if (index === 2) {
              bInsert = bInsert + `<ewords>${line.trim()}</ewords>`;
            }
            if (index === 2) {
              bInsert =
                bInsert + `<fwords tid="${uuid()}">${line.trim()}</fwords>`;
            }
          }
        });

        bInsert = bInsert + "</selector>";
        break;
      case "Help lines":
        const helpLines = selection.replace(/\v{1,}/g, "\n").split("\n");
        helpLines.forEach((cell: string, index: number) => {
          if (cell.trim()) {
            bInsert = bInsert + `<lines tid="${uuid()}">${cell.trim()}</lines>`;
          }
        });
        break;
    }

    const formattedXML = beautify_html(bInsert.replace(/\n+/g, " "), {
      indent_size: 2,
    });
    props.cm.doc.replaceSelection("\n");
    props.cm.doc.replaceSelection(formattedXML, "around");

    closeContext();
  };

  const addNewUuid = () => {
    const cursor = props.cm.getSearchCursor('tid="', props.cm.doc.getCursor());
    const found = cursor.findPrevious();
    closeContext();

    if (found) {
      const cursor1 = props.cm.getSearchCursor('"', cursor.to());
      if (cursor1.findNext()) {
        props.cm.doc.setSelection(cursor.from(), cursor1.to());
        props.cm.doc.replaceSelection(`tid="${uuid()}"`);
      }
    }
  };

  const makeNewLesson = async (name: string, id: string) => {
    setNewLessonDlgOpen(false);

    if (!id) {
      return;
    }
    store.dispatch(
      setBackdrop({
        open: true,
        text: tx("pasdwwsdaypage", "Processing..."),
      })
    );

    if (props.lessonList.find((item: any) => item.seq === id)) {
      alert("Already exists");
      store.dispatch(setBackdropOpen(false));
      return;
    }

    if (await saveFullLessonAsync(props.type, id, "", false)) {
      alert("error parsing");
      store.dispatch(setBackdropOpen(false));
      return;
    }

    const lessonListTemp = await loadLessonList(false, props.type);
    store.dispatch(setLessonList([...lessonListTemp]));

    props.setLesson(id);
    props.cm.doc.setValue("");
    store.dispatch(setBackdropOpen(false));
  };

  const saveFullLesson = async () => {
    store.dispatch(
      setBackdrop({
        open: true,
        text: tx("pasdwwsdaypage", "Processing..."),
      })
    );
    formatDocument();
    setTimeout(async () => {
      if (props.lessonList.find((item: any) => item.seq === props.lesson)) {
        if (
          await saveFullLessonAsync(
            props.type,
            props.lesson,
            props.cm.doc.getValue(),
            false
          )
        ) {
          alert("error parsing");
          store.dispatch(setBackdropOpen(false));
          return;
        }

        const lessonListTemp = await loadLessonList(false, props.type);
        store.dispatch(setLessonList([...lessonListTemp]));
      }

      store.dispatch(setBackdropOpen(false));
    }, 0);
  };

  const publishFullLesson = () => {
    store.dispatch(
      setBackdrop({
        open: true,
        text: tx("pasdwwsdaypage", "Processing..."),
      })
    );
    formatDocument();
    setTimeout(async () => {
      if (props.lessonList.find((item: any) => item.seq === props.lesson)) {
        if (
          await saveFullLessonAsync(
            props.type,
            props.lesson,
            props.cm.doc.getValue(),
            true
          )
        ) {
          alert("error parsing");
          store.dispatch(setBackdropOpen(false));
          return;
        }

        const lessonListTemp = await loadLessonList(false, props.type);
        store.dispatch(setLessonList([...lessonListTemp]));
      }

      store.dispatch(setBackdropOpen(false));
    }, 0);
  };

  const formatDocument = () => {
    const formattedXML = beautify_html(props.cm.doc.getValue(), {
      indent_size: 2,
      preserve_newlines: false,
    });
    props.cm.doc.setValue(formattedXML);
    closeContext();
  };

  const previewPart = () => {
    setLessonData(
      readXMLLesson(
        `<?xml version="1.0" encoding="UTF-8"?><lesson title="Bemutatkozás" number="1">` +
          props.cm.doc.getSelection() +
          "</lesson>"
      )
    );
    store.dispatch(setLessonDataChange(Date.now()));

    closeContext();
  };

  const closeContext = () => {
    store.dispatch(setMouse({ mouseX: null, mouseY: null }));
  };

  return (
    <React.Fragment>
      <Button onClick={() => setNewLessonDlgOpen(true)}>New lesson</Button>
      <Menu inverted style={{ margin: "0px" }}>
        <Dropdown item text="Insert part..." ref={insertRef}>
          <Dropdown.Menu>
            <Dropdown.Item onClick={insertPart}>Lesson</Dropdown.Item>
            <Dropdown.Item onClick={insertPart}>Text</Dropdown.Item>
            <Dropdown.Item onClick={insertPart}>Table</Dropdown.Item>
            <Dropdown.Item onClick={insertPart}>Cards</Dropdown.Item>
            <Dropdown.Item onClick={insertPart}>Conversation</Dropdown.Item>
            <Dropdown.Item onClick={insertPart}>Quiz</Dropdown.Item>
            <Dropdown.Item onClick={insertPart}>Video</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown item text="Convert to..." ref={convertRef}>
          <Dropdown.Menu>
            <Dropdown.Item onClick={convertPart}>Text</Dropdown.Item>
            <Dropdown.Item onClick={convertPart}>Text (full)</Dropdown.Item>
            <Dropdown.Item onClick={convertPart}>Header Row</Dropdown.Item>
            <Dropdown.Item onClick={convertPart}>Row</Dropdown.Item>
            <Dropdown.Item onClick={convertPart}>Rows</Dropdown.Item>
            <Dropdown.Item onClick={convertPart}>Simple Rows</Dropdown.Item>
            <Dropdown.Item onClick={convertPart}>Selector</Dropdown.Item>
            <Dropdown.Item onClick={convertPart}>Conversation</Dropdown.Item>
            <Dropdown.Item onClick={convertPart}>Cards (full)</Dropdown.Item>
            <Dropdown.Item onClick={convertPart}>Quiz</Dropdown.Item>
            <Dropdown.Item onClick={convertPart}>Help lines</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown item text="Actions" ref={actionRef}>
          <Dropdown.Menu>
            <Dropdown.Item onClick={saveFullLesson}>Save</Dropdown.Item>
            <Dropdown.Item onClick={publishFullLesson}>
              Save and Publish
            </Dropdown.Item>
            <Dropdown.Item onClick={formatDocument}>Format XML</Dropdown.Item>
            <Dropdown.Item onClick={previewPart}>Preview</Dropdown.Item>
            <Dropdown.Item onClick={addNewUuid}>New UUId</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Menu>

      <NewLessonDialog
        newLessonDlgOpen={newLessonDlgOpen}
        setOpen={setNewLessonDlgOpen}
        makeNewLesson={makeNewLesson}
      />

      {/* <Button as="div" onClick={createWords}>
        Word Factory
      </Button> */}
    </React.Fragment>
  );
};

export default EditorControl;

//eslint-disable-next-line
const createWords = async () => {
  const wordLists = await firestore
    .collection("originals")
    .where("type", "==", "words")
    .where("seq", ">=", "A3001")
    .where("seq", "<=", "A3003")
    .orderBy("seq")
    .get();

  const parser = new DOMParser();

  let cards: any[] = [];
  let thisId = "";
  let thisTitle = "";
  let thisTrans = "";

  for (let n = 0; n < wordLists.docs.length; n++) {
    let lesson = wordLists.docs[n];
    if (wordLists.docs[n].id === "I5000") {
      continue;
    }
    const xmlDoc = parser.parseFromString(lesson.data().xml, "text/xml");
    const lines = xmlDoc.querySelectorAll(
      "line"
    ) as unknown as NodeListOf<HTMLElement>;

    const source = (xmlDoc.querySelector("batch") as HTMLElement).innerHTML;
    // const translation = lesson.data().translations;
    // const seq = lesson.data().lesson;

    for (let g = 0; g < (lines?.length || 0); g++) {
      const english = lines[g].innerHTML;
      // const eid = lines[g].getAttribute("eid");
      const tid = lines[g].getAttribute("tid");
      const wordid = lines[g].getAttribute("wordid");
      const trans = lines[g].getAttribute("trans");

      if (wordid && g > 0) {
        cards.shift();
        let pron = "";
        if (thisTitle.includes("*")) {
          pron = thisTitle.split("*").pop() || "";
        }
        try {
          await firestore.doc("/vocab5/" + thisId).set({
            s: cards,
            pron,
            id: thisId,
            d: thisTrans,
            word: thisTitle,
            source: thisId.substr(0, 2),
            baseWord: thisTitle.split("+")[0].split("/")[0],
          });
        } catch (e) {
          console.log(e);
        }
      }

      if (wordid) {
        thisTitle = english;
        thisId = wordid || "";
        cards = [];
        thisTrans = trans || "";
      }

      if (!wordid) {
        cards.push(english + "[" + tid + "]{" + source + "}");
      }
    }
  }

  alert("done");
};

// const downloadWords = async () => {
//   console.time("bubu");
//   const words = await firestore.collection("vocab").get();

//   const result = words.docs.map((item) => {
//     const t = item.data();
//     return { ...t, s: (t.s || [])[0] };
//   });

//   const full = words.docs.map((item) => {
//     const t = item.data();
//     return t;
//   });
//   console.log(JSON.stringify(result).length);
//   console.log(JSON.stringify(full).length);

//   console.timeEnd("bubu");
// };
