const tx = (a: any, b: any) => {
  return b;
};

export const getEmails = (ccode: string) => {
  return [
    {
      title: tx("emtr1", "Email for students 1"),
      text: `<p>${tx("em1", "Dear Friend")}</p>
<p>${tx(
        "em1",
        "We have a fantastic new program which will help you a lot."
      )} ${tx("em1", "It works beautifully and you have to try it.")}</p>
<p>${tx("em1", "It's also very cheap.")}</p>
<p>${tx(
        "em1",
        "Check it out here:"
      )} <a href='https://netenglish.com/${ccode}'>https://netenglish.com</a></p>`,
    },
    {
      title: tx("emtr1", "Email for students 2"),
      text: `${tx("em1", "Dear Friend,")}
${tx("em1", "This is something for you.")} ${tx(
        "em1",
        "You will really love it."
      )}
${tx("em1", "We can buy it for nothing.")}
${tx("em1", "Check it out here:")} https://netenglish.com/${ccode}`,
    },
    {
      title: tx("emtr1", "Email for students 3"),
      text: `${tx("em1", "Dear Friend")}
${tx("em1", "I don't need you to buy it right away, but why not.")} ${tx(
        "em1",
        "Get this for your homework."
      )}
${tx("em1", "It's also very cheap.")}
${tx("em1", "Check it out here:")} https://netenglish.com/${ccode}`,
    },
    {
      title: tx("emtr1", "Email for teachers 2"),
      text: `${tx("em1", "Dear Friend")}
${tx("em1", "This is something you need to try")} ${tx(
        "em1",
        "It works beautifully and you have to try it."
      )}
${tx("em1", "It's also very cheap.")}
${tx("em1", "Check it out here:")} https://netenglish.com/${ccode}`,
    },
  ];
};

export function copyFormatted(html: string) {
  // Create container for the HTML
  // [1]
  var container = document.createElement("div");
  container.innerHTML = html;

  // Hide element
  // [2]
  container.style.position = "fixed";
  container.style.pointerEvents = "none";
  container.style.opacity = "0";

  // Detect all style sheets of the page
  var activeSheets = Array.prototype.slice
    .call(document.styleSheets)
    .filter(function (sheet) {
      return !sheet.disabled;
    });

  // Mount the container to the DOM to make `contentWindow` available
  // [3]
  document.body.appendChild(container);

  // Copy to clipboard
  // [4]
  window.getSelection()?.removeAllRanges();

  var range = document.createRange();
  range.selectNode(container);
  window.getSelection()?.addRange(range);

  // [5.1]
  document.execCommand("copy");

  // [5.2]
  for (var i = 0; i < activeSheets.length; i++) activeSheets[i].disabled = true;

  // [5.3]
  document.execCommand("copy");

  // [5.4]
  for (var g = 0; g < activeSheets.length; g++)
    activeSheets[g].disabled = false;

  // Remove the container
  // [6]
  document.body.removeChild(container);
}
