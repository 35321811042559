import React from "react";
import { Transition, Modal, Button, Dimmer, Loader } from "semantic-ui-react";
import { makeStyles, createStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    text: {},
  })
);

const ConfirmAct = (props: any) => {
  //eslint-disable-next-line
  const classes = useStyles();

  const closeMe = () => {
    props.setOpen(false);
  };

  return (
    <React.Fragment>
      <Transition
        unmountOnHide
        visible={props.open}
        animation="fade"
        duration={250}
      >
        <Modal
          size="small"
          centered={false}
          open={true}
          onClose={() => props.setOpen(false)}
        >
          <Dimmer active={props.payLoad}>
            <Loader />
          </Dimmer>
          <Modal.Header style={{ padding: "9px 15px" }}>
            Payment Confirmation
          </Modal.Header>
          <Modal.Content
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "20px",
              overflowY: "auto",
              height: "320px",
              fontSize: "16px",
            }}
            id="list5"
          >
            <Modal.Description>
              <React.Fragment>
                <p>{`You have a card stored: (${
                  props.account?.paymentMethod?.card?.brand +
                  " ending in ..." +
                  props.account?.paymentMethod?.card?.last4
                })`}</p>
                <p>{`If you want to pay with your saved card, press the Pay With Saved Card button.`}</p>
                <p>{`You will be charged ${props.amount} ${props.currency}`}</p>
                <p>{`If you want to use a different card click on the Pay With Different Card button and we will forward you to our Barion payment page.`}</p>
                <p>{`By proceeding to pay you also accept our Terms of Conditions and our Privacy Policy.`}</p>
              </React.Fragment>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={closeMe} negative>
              Cancel
            </Button>

            <React.Fragment>
              <Button onClick={props.onSavedPay} positive>
                Pay With Saved Card
              </Button>
              <Button onClick={props.onNewPay} negative>
                Pay With Different Card
              </Button>
            </React.Fragment>
          </Modal.Actions>
        </Modal>
      </Transition>
    </React.Fragment>
  );
};

export default ConfirmAct;
