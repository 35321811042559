import React from "react";
import { Transition, Modal, Button } from "semantic-ui-react";
import { makeStyles, createStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    text: {},
  })
);

const SignUpWarn = (props: any) => {
  //eslint-disable-next-line
  const classes = useStyles();

  const closeMe = () => {
    props.setOpen(false);
  };

  return (
    <React.Fragment>
      <Transition
        unmountOnHide
        visible={props.open}
        animation="fade"
        duration={250}
      >
        <Modal
          size="small"
          centered={true}
          open={true}
          onClose={() => props.setOpen(false)}
        >
          <Modal.Content>
            <Modal.Description>
              <React.Fragment>
                <p>
                  This email address is already in use. If you want to start
                  your own app with this email address and the address belongs
                  to you log into the app, and choose Account from the menu and
                  click on the Start new app button.
                </p>
                <p>Alternately you can use a different email address.</p>
              </React.Fragment>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={closeMe} negative>
              Cancel
            </Button>
          </Modal.Actions>
        </Modal>
      </Transition>
    </React.Fragment>
  );
};

export default SignUpWarn;
