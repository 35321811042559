import React from "react";
import { Transition, Modal, Button, Dimmer, Loader } from "semantic-ui-react";
import { makeStyles, createStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    text: {},
  })
);

const ConfirmCardStore = (props: any) => {
  //eslint-disable-next-line
  const classes = useStyles();

  const closeMe = () => {
    props.setOpen(false);
  };

  return (
    <React.Fragment>
      <Transition
        unmountOnHide
        visible={props.open}
        animation="fade"
        duration={250}
      >
        <Modal
          size="small"
          centered={false}
          open={true}
          onClose={() => props.setOpen(false)}
        >
          <Dimmer active={props.cardLoad}>
            <Loader />
          </Dimmer>
          <Modal.Header style={{ padding: "9px 15px" }}>
            Adding Payment Method
          </Modal.Header>
          <Modal.Content
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "20px",
              overflowY: "auto",
              height: "320px",
              fontSize: "16px",
            }}
            id="list5"
          >
            <Modal.Description>
              {props.account?.paymentMethodAuto?.card?.brand ? (
                <React.Fragment>
                  <p>{`You already have a card stored. (${
                    props.account?.paymentMethodAuto?.card?.brand +
                    " ending in ..." +
                    props.account?.paymentMethodAuto?.card?.last4
                  })`}</p>
                  <p>{`If you want to use a different card press on the Change Card button below and we forward you to the Payment Processor page to add your card data.`}</p>
                  <p>{`If you want to delete all payment methods select the Delete Card button at the bottom.`}</p>
                  <p>{`Otherwise press Cancel to close this window.`}</p>
                  <p>{`By pressing the Authorize button you also accept our Terms of Conditions and our Privacy Policy.`}</p>
                  <p>{`To check your card we will reserve a small sum temporarily. This will be re-credited to your card within an hour.`}</p>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <p>{`You can store a card with us to make it easier to do your payments in the future. We do not store your card data. Those data are stored by the bank we use. We only get the option to run your card if you give permission to us.`}</p>
                  <p>{`However storing a card can make your life easier as you don't have to fill out your card data every time you want to buy licences.`}</p>
                  <p>{`We will still ask your confirmation before any purchase unless you authorize automatic payments.`}</p>
                  <p>{`To check your card we will reserve a small sum temporarily. This will be re-credited to your card within an hour.`}</p>
                </React.Fragment>
              )}
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={closeMe} negative>
              Cancel
            </Button>
            {props.account?.paymentMethodAuto?.card?.brand ? (
              <React.Fragment>
                <Button onClick={props.onDelete} negative>
                  Delete Card
                </Button>
                <Button onClick={props.onAction} positive>
                  Change Card
                </Button>
              </React.Fragment>
            ) : (
              <Button onClick={props.onAction} positive>
                Add Card
              </Button>
            )}
          </Modal.Actions>
        </Modal>
      </Transition>
    </React.Fragment>
  );
};

export default ConfirmCardStore;
