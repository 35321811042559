import React from "react";

import { createStyles, makeStyles } from "@material-ui/styles";
import { tx } from "utils/globalize";

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    cat: {
      marginBottom: "0px !important",
      height: "100%",
      position: "absolute",
      top: "0px",
      bottom: "0px",
      left: "0px",
      right: "0px",
      overflowY: "auto",
      padding: "30px",
      "@media (max-width: 500px)": {
        padding: "15px",
      },
    },
    wrapper: {
      marginTop: "12px",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      overflow: "auto",
      marginBottom: "0px !important",
    },
    titleWrapper: { display: "flex", width: "100%", marginBottom: "3px" },
    texti: { fontSize: "18px", marginTop: "0px" },
    listitem: { display: "flex", marginBottom: "15px" },
    intro: { display: "flex", marginBottom: "15px" },
    mail: {
      marginTop: "30px",
      marginBottom: "20px",
      color: "blue",
      textAlign: "center",
      userSelect: "text",
      textDecoration: "underline",
      fontSize: "21px",
    },
    number: { flex: "0 0 30px" },
    emails: { display: "flex", marginTop: "15px" },
    company: { fontWeight: "bold", marginTop: "15px", marginBottom: "10px" },
    emaillink: {
      color: "blue",
      textDecoration: "underline",
      paddingLeft: "30px",
    },
    listtext: { flex: "1 1 auto" },
    link: { fontWeight: "bold", marginTop: "10px" },
    title: {
      flex: "1 1 auto",
      fontWeight: "bold",
      marginBottom: "5px",
      fontSize: "16px",
      marginTop: "4px",
    },
    share: { marginTop: "10px", display: "flex" },
    left: { flex: "1 1 auto" },
    right: { flex: "1 1 auto", textAlign: "right" },
    mitem: { padding: "2px !important" },
  })
);

const AccountTipsResell = (props: any) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.cat}>
        <div className={classes.texti}>
          <div className={classes.intro}>
            {tx(
              "a12345g010",
              "You can also become a reseller and sell the WizzWords app in your own webshop."
            )}
          </div>
          <div className={classes.intro}>
            {tx(
              "a12345g011",
              "For resellers we have a special reseller contract and you will receive any help needed to set up your webshop, so that when your customers buy WizzWords they are automatically registered with the app."
            )}
          </div>
          <div className={classes.intro}>
            {tx(
              "a12345g012",
              "If you are a reseller we agree that you will pay within 7 days after each purchase with money transfer based on the invoice we send to you regularly for the purchases we registered."
            )}
          </div>
          <div className={classes.intro}>
            {tx(
              "a12345g013",
              "As with every other type of sales we do NOT use any contact information from your customers for futher marketing."
            )}
          </div>
          <div className={classes.intro}>
            {tx(
              "a12345g014",
              "One of our resellers in Hungary with a mailing list of 40000 made more than 6000 sales in the last year which would mean 72000 Euros net income for you after you paid for the licences if you sold the app for 39 Euros."
            )}
          </div>
          <div className={classes.intro}>
            {tx(
              "a12345g0dwwe15",
              "If you are interested please write to the below email address making sure you include the number xxx in your email:",
              props.account.ccode
            )}
          </div>
          <div className={classes.mail}>
            <a
              href={
                "mailto:resell@wizzwords.net?subject=Reseller (" +
                props.account.ccode +
                ")"
              }
            >
              resell@wizzwords.net
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AccountTipsResell;
// AIzaSyA8d9SleAVkuA1BwyrcvBypzmt9rFDXFrA
