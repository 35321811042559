import React, { useState, useEffect } from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";
import { RootState } from "Redux/reducers/_index";
import EditorControl from "./editor.editorControl";
import CodeEditor from "./editor.codeEditor";
import { LessonListType } from "../Redux/reducers/reducer.lessonList";
import { phaseIn, phaseBodyIn } from "../utils/utils";
import { UserType } from "Redux/reducers/reducer.currentUser";
import { Segment, Grid, Icon } from "semantic-ui-react";
import EditorFiles from "./editor.files";

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    paper1: {
      height: "calc(100% - 7px)",
      marginTop: "7px",
    },
    container: {
      padding: "7px",
      height: "calc(100% - 64px)",
    },
    control: {
      margin: "3px",
      borderRight: "1px solid lightgrey",
      height: "97%",
    },
    table: {
      height: "100%",
    },
    lessonPage: {
      overflow: "auto",
    },
    iphone6: {
      height: "667px",
      width: "375px",
      overflow: "hidden",
      border: "1px solid lightgrey",
      marginLeft: "auto",
      marginRight: "auto",
    },
    phoneheader: {
      height: "35px",
      width: "375px",
      fontWeight: 700,
      marginLeft: "auto",
      marginRight: "auto",
      color: "white",
      paddingTop: "5px",
      textAlign: "center",
    },
    mainFrame: {
      overflow: "auto",
      height: "calc(100% - 86px)",
      background: "white",
      padding: "20px",
    },
    padding: {
      height: "86px",
    },
    mini: { transform: "scale(0.5)" },
    flexHorizontal: { display: "flex", width: "100%", paddingBottom: "10px" },
    catTitle: { flex: "1 1 auto" },
    catLevel: { flex: "0 0 120px", paddingLeft: "15px" },
    flexWrapper: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      minWidth: "1024px",
      position: "absolute",
      top: 55,
      bottom: 0,
      paddingTop: "10px",
      paddingBottom: "10px",
      background: "#f5f5f5",
    },
    buttons: {
      display: "flex",
      width: "100%",
      flex: "0 0 30px",
    },
    button: { flex: "1 1 auto", padding: "3px" },
    changed: {
      position: "absolute",
      right: 5,
      color: "red",
      fontWeight: "bold",
      fontSize: "13px",
    },
    controls: {
      flex: "0 0 250px",
      padding: "0px 15px 0px 15px",
      display: "flex",
      flexDirection: "column",
      marginRight: "8px",
    },
    editor: {
      flex: "1 1 auto",
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 10,
      padding: "10px",
      overflow: "auto",
      marginTop: "0px !important",
    },
    formControl: {
      marginBottom: "20px",
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: "5px",
    },
    textButton: {
      textAlign: "left",
      width: "100%",
      height: "30px",
      marginLeft: "5px",
      fontSize: "18px",
      justifyContent: "flex-start",
    },
    wrapper: {
      marginTop: "12px",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      overflow: "auto",
      marginBottom: "0px !important",
    },
    listWrapper: {
      position: "relative",
      overflowY: "auto",
      flex: "1 1 auto",
    },
    catWrapper: {
      position: "relative",
      overflow: "hidden",
      flex: "1 1 auto",
      height: "100%",
      paddingBottom: "10px",
    },
    titleWrapper: { display: "flex", width: "100%", marginBottom: "3px" },
    titlePlus: { flex: "0 0 auto" },
    title: {
      flex: "1 1 auto",
      fontWeight: "bold",
      marginBottom: "5px",
      fontSize: "16px",
      marginTop: "4px",
    },
    doneword: {
      background: "#ffffee",
      marginBottom: "4px",
      border: "1px solid lightgrey",
      borderRadius: "5px",
      fontSize: "16px",
      paddingRight: "8px",
      paddingLeft: "8px",
      alignItems: "center",
      paddingBottom: "3px",
      display: "flex",
      flexDirection: "row",
      "&.activeList": { background: "#ffeeee" },
      "&.done": { background: "#eeffee" },
      "&.waiting": { background: "#ffffee" },
    },
    selword: {
      background: "#eeeeff",
      marginBottom: "4px",
      border: "1px solid lightgrey",
      borderRadius: "5px",
      fontSize: "16px",
      paddingRight: "8px",
      paddingLeft: "8px",
      alignItems: "baseline",
      paddingBottom: "3px",
      display: "flex",
      flexDirection: "row",
      "&.activeList": { background: "#ffeeee" },
      "&.done": { background: "#eeffee" },
      "&.waiting": { background: "#ffffee" },
    },
    donelist: {
      background: "#eeffee",
      marginBottom: "4px",
      border: "1px solid lightgrey",
      borderRadius: "5px",
      fontSize: "16px",
      paddingRight: "8px",
      paddingLeft: "8px",
      alignItems: "baseline",
      paddingBottom: "3px",
      display: "flex",
      flexDirection: "row",
      "&.activeList": { background: "#ffeeee" },
      "&.done": { background: "#eeffee" },
      "&.waiting": { background: "#ffffee" },
    },
    sellist: {
      background: "#eeeeff",
      marginBottom: "4px",
      border: "1px solid lightgrey",
      borderRadius: "5px",
      fontSize: "16px",
      paddingRight: "8px",
      paddingLeft: "8px",
      alignItems: "baseline",
      paddingBottom: "3px",
      display: "flex",
      flexDirection: "row",
      "&.activeList": { background: "#ffeeee" },
      "&.done": { background: "#eeffee" },
      "&.waiting": { background: "#ffffee" },
    },
    num: {
      fontSize: "13px",
      flex: "0 0 20px",
      paddingTop: "3px",
      paddingRight: "8px",
      paddingBo: "3px",
    },
    Title: { flex: "1 1 auto" },
    cat: { marginBottom: "0px !important" },
    adminHeader: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    headerIcon: {
      width: "50px",
    },
    headerText: {
      flex: "1 1 auto",
    },
    topSegment: {
      marginBottom: "0px !important",
      borderBottom: "0px solid white !important",
      paddingTop: "8px !important",
      paddingBottom: "8px !important",
      height: "54px",
      display: "flex !important",
      alignItems: "center !important",
      borderBottomLeftRadius: "0px !important",
      borderBottomRightRadius: "0px !important",
    },
    cardIconTitle: {
      paddingTop: "0px",
      marginBottom: "0px",
      width: "100%",
      marginTop: "0px",
    },
    bottom: {
      marginTop: "0px !important",
      boxShadow: "none !important",
      height: "100%",
    },
    name: { flex: "1 1 auto" },
  })
);

const Editor = (props: any) => {
  const [cm, setCM] = useState<any>(null);
  const [type, setType] = useState("lessons");
  const [lesson, setLesson] = useState("");

  const lessonList = useSelector<RootState, LessonListType[]>(
    (state) => state.lessonList
  );

  const classes = useStyles();

  const currentUser = useSelector<RootState, UserType>(
    (state) => state.currentUser
  );

  useEffect(() => {
    window.onbeforeunload = function (e: any) {
      var dialogText = "Do you really want to leave this site?";
      e.returnValue = dialogText;
      return dialogText;
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (currentUser) {
        phaseBodyIn();
        phaseIn();
      }
    }, 0);
  }, [currentUser]);

  return (
    <React.Fragment>
      <Segment className={classes.topSegment} inverted>
        <Grid style={{ width: "100%" }}>
          <Grid.Row>
            <Grid.Column width={16}>
              <div className={classes.adminHeader}>
                <div className={classes.headerIcon}>
                  <Icon name="book" />
                </div>
                <div className={classes.headerText}>
                  <h4 className={classes.cardIconTitle}>File Editor</h4>
                </div>
                <EditorControl
                  type={type}
                  lessonList={lessonList}
                  lesson={lesson}
                  cm={cm}
                  setLesson={setLesson}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <div className={classes.flexWrapper}>
        <div className={classes.controls}>
          <Segment
            className={classes.cat}
            color="olive"
            style={{ overflow: "hidden" }}
          >
            <div className={classes.titleWrapper}>
              <div className={classes.title}>Files</div>
            </div>
            <div className={classes.catWrapper}>
              <EditorFiles
                cm={cm}
                lesson={lesson}
                lessonList={lessonList}
                setLesson={setLesson}
                type={type}
                setType={setType}
              />
            </div>
          </Segment>
        </div>
        <Segment
          className={classes.editor}
          color="olive"
          style={{
            overflow: "hidden",
          }}
        >
          <div className={classes.titleWrapper}>
            <div className={classes.title}>Text</div>
          </div>
          <Segment
            style={{
              height: "calc(100% - 25px)",
              overflow: "auto",
              marginTop: "0px",
            }}
          >
            <CodeEditor setCM={setCM} />
          </Segment>
        </Segment>
      </div>
    </React.Fragment>
  );
};

export default Editor;
