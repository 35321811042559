import { firestore } from "firebaseUtil/firebaseUtil";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "Redux/reducers/_index";
import { Header, Segment, Statistic } from "semantic-ui-react";
import { tx } from "utils/globalize";

const AccountSales = (props: any) => {
  const [sales, setSales] = useState<any>({});

  const ccodeGlobal = useSelector<RootState, any>((state) => state.ccodeGlobal);

  useEffect(() => {
    const loadSales = async () => {
      let tempSales: any = {
        pending: 0,
        ready: 0,
        paid: 0,
      };

      const salesData = await firestore
        .doc("/companies/" + ccodeGlobal + "/data/sales")
        .get();

      if (salesData.data()) {
        tempSales.ready =
          (salesData.data()?.ready || 0) +
          (salesData.data()?.pending || []).filter(
            (item: any) => item < Date.now() - 1440 * 14000
          ).length *
            16;
        tempSales.paid = salesData.data()?.paid || 0;
        tempSales.pending =
          (salesData.data()?.pending || []).filter(
            (item: any) => item > Date.now() - 1440 * 14000
          ).length * 16;
      }

      setSales(tempSales);
    };

    if (ccodeGlobal) {
      loadSales();
    }
  }, [ccodeGlobal]);

  return (
    <React.Fragment>
      <Header
        attached="top"
        style={{
          marginTop: "0px",
          background: "#f5f5f5",
          padding: "5px",
          minHeight: "42px",
        }}
      >
        <div style={{ padding: "5px", display: "inline-block" }}>
          {tx("ollkweowe", "Commissions earned")}
        </div>
        {/* <Button
              size="tiny"
              floated="right"
              onClick={() => setUserDlgOpen(true)}
              style={{ minWidth: "100px" }}
            >
              Add User
            </Button> */}
      </Header>
      <Segment attached textAlign="center">
        <Statistic.Group style={{ display: "block" }}>
          <Statistic color="green" style={{ marginRight: "1.5rem" }}>
            <Statistic.Value>{sales.pending || 0}</Statistic.Value>
            <Statistic.Label>{tx("a12345h010", "pending")}</Statistic.Label>
          </Statistic>
          <Statistic color="brown" style={{ marginRight: "2rem" }}>
            <Statistic.Value>{sales.ready || 0}</Statistic.Value>
            <Statistic.Label>{tx("a12345h011", "ready")}</Statistic.Label>
          </Statistic>
          <Statistic color="brown" style={{ marginRight: "1.5rem" }}>
            <Statistic.Value>{sales.paid || 0}</Statistic.Value>
            <Statistic.Label>{tx("a12345h012", "paid")}</Statistic.Label>
          </Statistic>
        </Statistic.Group>
      </Segment>
    </React.Fragment>
  );
};

export default AccountSales;
