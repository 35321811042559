import Customize from "customize/customize";
import React, { Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { setCcodeGlobal } from "Redux/reducers/reducer.ccodeGlobal";
import store from "Redux/store";
import { Container, Dropdown, Grid, Header, Menu } from "semantic-ui-react";
import UserEditor from "UserEditor/userEditor.table";

import { createStyles, makeStyles } from "@material-ui/styles";

import Account from "../account/account";
import Creator from "../creator/creator";
// import { SemanticToastContainer } from "react-semantic-toasts";
import Docer from "../docer/docer";
import Editor from "../Editor/editor";
import { firestore } from "../firebaseUtil/firebaseUtil";
import { RootState } from "../Redux/reducers/_index";
import { phaseBodyIn } from "../utils/utils";
import DimMe from "./dashboard.dimmer";
import DashboardMenu from "./dashboard.menu";
import { useParams, useHistory } from "react-router-dom";
import { setBackdropOpen } from "Redux/reducers/reducer.backdropOpen";
const Translator = React.lazy(() => import("../Translator/translator"));

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    mainFrame: {
      overflow: "hidden",
      position: "fixed",
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      padding: "20px",
      "@media (max-width: 600px)": {
        padding: "0px",
      },
      zIndex: 1,
      opacity: 1,
      transition: "opacity 0.15s, z-index 0s 0.15s",
      background: "#bbb",
    },
    myCont: {
      minWidth: "100%",
    },
    working: {
      position: "fixed",
      background: "white",
      top: 60,
      bottom: 10,
      left: 20,
      right: 20,
      marginLeft: "auto",
      marginRight: "auto",
      "@media(max-width: 600px)": {
        top: 50,
        bottom: 0,
        left: 0,
        right: 0,
      },
      borderRadius: "5px",
      overflow: "hidden",
    },
    appHeader: {
      position: "fixed",
      top: 0,
      width: "100% !important",
      left: 0,
      right: 0,
      height: "50px",
      background: "#033",
      color: "white",
      display: "flex",
      alignItems: "center",
      zIndex: 5000,
    },
    header: {
      color: "white !important",
      alignItems: "center !important",
      display: "flex !important",
    },
  })
);

const pages: any = {
  subadmin: ["account", "customize"],
  contributor: ["translator"],
  admin: [
    "account",
    "users",
    "texts",
    "customize",
    "editor",
    "translator",
    "definer",
  ],
};

const Dashboard = (props: any) => {
  const classes = useStyles();
  const [active, setActive] = useState(5);
  const [account, setAccount] = useState<any>({});
  const [formChanged, setFormChanged] = useState(false);
  const [ccodeList, setCcodeList] = useState<any[]>([]);

  const page = useParams<any>().page;

  useEffect(() => {
    setTimeout(() => phaseBodyIn(), 0);
  }, []);

  const history = useHistory();

  const currentUser = useSelector<RootState, any>((state) => state.currentUser);
  const ccodeGlobal = useSelector<RootState, any>((state) => state.ccodeGlobal);

  useEffect(() => {
    if (!currentUser?.email) {
      return;
    }

    if (currentUser?.type === "user") {
      return;
    }

    if (!page) {
      store.dispatch(setBackdropOpen(false));
      history.push("/dashboard/" + pages[currentUser.type][0]);
    }

    if (!pages[currentUser.type]?.includes(page)) {
      store.dispatch(setBackdropOpen(false));
      history.push("/dashboard/" + pages[currentUser.type][0]);
    }
  }, [page, currentUser, history]);

  useEffect(() => {
    const loadCompanies = async () => {
      try {
        const companies = await firestore.collection("companies").get();

        setCcodeList(
          companies.docs.map((item) => ({
            value: item.id,
            name: item.data()?.name || item.id,
          }))
        );
      } catch (e) {
        console.log(e);
      }
    };

    if (currentUser?.type === "admin") {
      loadCompanies();
    }
  }, [currentUser]);

  useEffect(() => {
    if (ccodeGlobal) {
      firestore
        .doc(`/companies/${ccodeGlobal}/data/account`)
        .onSnapshot(async (snapShot) => {
          console.log("updated");
          setAccount(snapShot.data());
          setFormChanged(false);
        });
    }

    // if (currentUser?.type === "contributor") {
    //   setActive(5);
    // }
  }, [ccodeGlobal]);

  return (
    <React.Fragment>
      <div className={classes.mainFrame}>
        <div className={classes.appHeader}>
          <Container
            className={classes.myCont}
            style={{ height: "50px", width: "100%" }}
          >
            <Grid columns={16}>
              <Grid.Column
                key={0}
                width={5}
                stretched
                style={{ paddingLeft: "28px", paddingBottom: "0px" }}
              >
                <Header
                  icon={{ name: "settings", size: "tiny" }}
                  as="div"
                  size="small"
                  className={classes.header}
                  content={`WizzWords`}
                />
              </Grid.Column>
              <Grid.Column key={1} width={2}>
                {currentUser?.type === "admin" ? (
                  <Menu
                    style={{
                      margin: "0px",
                      zIndex: 5000,
                      background: "#033",
                      fontWeight: "bold",
                      color: "white",
                    }}
                    inverted
                  >
                    <Dropdown item text="Select App">
                      <Dropdown.Menu>
                        {ccodeList.map((item) => (
                          <Dropdown.Item
                            key={item.value}
                            onClick={() => {
                              store.dispatch(setCcodeGlobal(item.value));
                            }}
                          >
                            {item.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Menu>
                ) : (
                  ""
                )}
              </Grid.Column>
              <Grid.Column key={2} textAlign="right" width={9}>
                <DashboardMenu
                  page={page}
                  active={active}
                  setActive={setActive}
                />
              </Grid.Column>
            </Grid>
          </Container>
        </div>
        <div
          className={classes.working}
          style={page === "account" ? { maxWidth: "800px" } : {}}
        >
          {page === "account" ? (
            <Account
              account={account}
              setAccount={setAccount}
              formChanged={formChanged}
              setFormChanged={setFormChanged}
            />
          ) : (
            ""
          )}
          {page === "users" ? (
            <UserEditor account={account} setActive={setActive} />
          ) : (
            ""
          )}
          {page === "texts" ? <Docer /> : ""}
          {page === "customize" ? <Customize /> : ""}
          {page === "editor" ? <Editor /> : ""}
          {page === "translator" ? (
            <Suspense fallback={<div />}>
              <Translator />
            </Suspense>
          ) : (
            ""
          )}
          {page === "definer" ? <Creator /> : ""}
        </div>
      </div>

      <DimMe />
    </React.Fragment>
  );
};

export default Dashboard;
