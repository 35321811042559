import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FreeData } from "firebaseUtil/firebaseUtil";

const initialState: FreeData = {};

const appTransPartsSlice = createSlice({
  initialState,
  name: "appTransParts",
  reducers: {
    setAppTransParts: (state: any, action: PayloadAction<FreeData>) => {
      return action.payload;
    },
  },
});

export const { setAppTransParts } = appTransPartsSlice.actions;

export default appTransPartsSlice.reducer;
