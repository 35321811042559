import React, { useState } from "react";

import { createStyles, makeStyles } from "@material-ui/styles";
import BankdataDlg from "./account.bankdata";
import { Button } from "semantic-ui-react";
import AccountSales from "./account.sales";
import { tx } from "utils/globalize";
import EmailDlg from "./account.emailshow";

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    cat: {
      marginBottom: "0px !important",
      height: "100%",
      position: "absolute",
      top: "0px",
      bottom: "0px",
      left: "0px",
      right: "0px",
      overflowY: "auto",
      padding: "30px",
      "@media (max-width: 500px)": {
        padding: "15px",
      },
    },
    wrapper: {
      marginTop: "12px",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      overflow: "auto",
      marginBottom: "0px !important",
    },
    titleWrapper: { display: "flex", width: "100%", marginBottom: "3px" },
    texti: { fontSize: "18px", marginTop: "0px" },
    listitem: { display: "flex", marginBottom: "15px" },
    intro: { display: "flex", marginBottom: "15px" },
    number: { flex: "0 0 30px" },
    emails: { display: "flex", marginTop: "15px" },
    company: { fontWeight: "bold", marginTop: "15px", marginBottom: "10px" },
    emaillink: {
      color: "blue",
      textDecoration: "underline",
      paddingLeft: "30px",
    },
    listtext: { flex: "1 1 auto" },
    link: { fontWeight: "bold", marginTop: "10px", userSelect: "all" },
    title: {
      flex: "1 1 auto",
      fontWeight: "bold",
      marginBottom: "5px",
      fontSize: "16px",
      marginTop: "4px",
    },
    share: { marginTop: "10px", display: "flex" },
    left: { flex: "1 1 auto" },
    right: { flex: "1 1 auto", textAlign: "right" },
    mitem: { padding: "2px !important" },
  })
);

const AccountTipsRecommend = (props: any) => {
  const classes = useStyles();

  const [bankDlgOpen, setBankDlgOpen] = useState(false);
  const [emailOpen, setEmailOpen] = useState(false);

  return (
    <React.Fragment>
      <div className={classes.cat}>
        {/* <div className={classes.titleWrapper}>
          <div className={classes.title}>Info and Tips</div>
        </div> */}

        <div className={classes.texti}>
          <div className={classes.intro}>
            {tx(
              "a12345b0",
              "You can recommend WizzWords for a commission if you can write an invoice for your commissions received. This is the simplest option."
            )}
          </div>
          <div className={classes.intro}>
            {tx(
              "a12345b01",
              "- If you can't write such an invoice, check out the Buy  option above."
            )}
          </div>
          <div className={classes.intro}>
            {tx(
              "a12345b02a",
              "- If you want to sell it on your own page, check the Resell option above."
            )}
          </div>
          <div className={classes.intro}>
            {tx(
              "a12345b02a",
              "- If you want to sell it on your own page, check the Resell option above."
            )}
          </div>
          <div className={classes.intro} style={{ fontWeight: "bold" }}>
            {tx("a12345b02acmw", "- Comissione: €16/app.")}
          </div>
          <div className={classes.listitem}>
            <div className={classes.number}>1.</div>
            <div className={classes.listtext}>
              {tx(
                "a12345b03",
                "We give you a special link which belongs to you and if someone clicks on it they can buy WizzWords, and it will be credited to you:"
              )}
              <div className={classes.link}>
                https://wizzwords.net/{props.account.ccode}
              </div>
            </div>
          </div>
          <div className={classes.listitem}>
            <div className={classes.number}>2.</div>
            <div className={classes.listtext}>
              {tx(
                "a12345b04",
                "We give you some sample email texts describing the app that you can customize."
              )}
              <div className={classes.emails}>
                <Button onClick={() => setEmailOpen(true)}>
                  {tx("askdoweokwas", "Sample Emails")}
                </Button>
              </div>
            </div>
          </div>

          <div className={classes.listitem}>
            <div className={classes.number}>3.</div>
            <div className={classes.listtext}>
              {tx(
                "a12345b08",
                "Send these out to your email contacts or on social media or wherever you want to and they can buy the app."
              )}
            </div>
          </div>
          <div className={classes.listitem}>
            <div className={classes.number}>4.</div>
            <div className={classes.listtext}>
              <p>
                {tx(
                  "a12345b09",
                  "Here you can see the the status of your commissions in Euros."
                )}{" "}
                {tx(
                  "a12345b010",
                  "Pending means that those purchases happened less than 14 days ago."
                )}{" "}
                {tx(
                  "a12345b011",
                  "(For 14 days a refund may be requested so we will pay any commission after 14 days.)"
                )}
              </p>
              <div style={{ flex: "0 0 auto", marginBottom: "15px" }}>
                <AccountSales />
              </div>
              <p>
                {tx(
                  "a12345b012",
                  "Ready means that you can claim the commission by sending us an invoice as laid out below."
                )}
              </p>
              <p>
                {tx(
                  "a12345b013",
                  "Paid means we transferred the money to you."
                )}
              </p>
            </div>
          </div>
          <div className={classes.listitem}>
            <div className={classes.number}>5.</div>
            <div className={classes.listtext}>
              {tx(
                "a12345b014",
                "If you have any commissions under the READY status write a standard invoice to:"
              )}
              <div className={classes.company}>
                NetEnglish Solutions OU Hungarian PE
                <br />
                9700 Hungary, Szombathely, Deak Ferenc ut 29.
                <br />
                HU27972984
              </div>
              <div>{tx("a12345b0owokcac", "Content:")}</div>
              <div className={classes.company}>
                xxx Euros - WizzWords Language Teaching Tools commission (
                {props.account.ccode})
              </div>
              <div>
                {tx(
                  "a12345wewb015",
                  "(The number in the parentheses is very important. This is how we know it's you.)"
                )}
              </div>
            </div>
          </div>
          <div className={classes.listitem}>
            <div className={classes.number}>6.</div>
            <div className={classes.listtext}>
              {tx("a12345b016", "Send this invoice to:")}{" "}
              <a href="mailto:accounting@wizzwords.net">
                accounting@wizzwords.net
              </a>
            </div>
          </div>
          <div className={classes.listitem}>
            <div className={classes.number}>7.</div>
            <div className={classes.listtext}>
              {tx(
                "a12345b017",
                "Provide here your bank account information where we can send the money."
              )}
              {props.account?.bank?.accountNumber ? (
                <div className={classes.company}>
                  {props.account?.bank?.accountName}
                  <br />
                  {props.account?.bank?.accountNumber}
                  <br />
                  {props.account?.bank?.accountCountry}
                  <br />
                  {props.account?.bank?.accountCity}
                  <br />
                  {props.account?.bank?.accountZip}
                </div>
              ) : null}
              <div>
                <Button
                  onClick={() => setBankDlgOpen(true)}
                  style={{ minWidth: "100px" }}
                >
                  {tx("a12345b018", "Set Bank Account data")}
                </Button>
              </div>
            </div>
          </div>
          <div className={classes.listitem}>
            <div className={classes.number}>8.</div>
            <div className={classes.listtext}>
              {tx(
                "a12345b019",
                "Within 3 working days we are going to transfer your commission to the bank account you provided."
              )}
            </div>
          </div>
        </div>
      </div>
      <BankdataDlg
        open={bankDlgOpen}
        setOpen={setBankDlgOpen}
        setAccount={props.setAccount}
        account={props.account}
        doSearch={() => {}}
        bank={props.account.bank}
      />
      <EmailDlg
        open={emailOpen}
        setOpen={setEmailOpen}
        ccode={props.account.ccode}
      />
    </React.Fragment>
  );
};

export default AccountTipsRecommend;
// AIzaSyA8d9SleAVkuA1BwyrcvBypzmt9rFDXFrA
