import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SettingsType {
  testReview: boolean;
  autoRead: boolean;
  pronunciation: boolean;
  writing: boolean;
  readSpeed: number;
  pro: boolean;
  startingLevel: string;
  showFirstPage: boolean;
  wordLevel: string;
  highlight: boolean;
  playing: boolean;
  fontSize: number;
}

export const initialState: SettingsType = {
  testReview: false,
  autoRead: true,
  pronunciation: true,
  writing: true,
  readSpeed: 1,
  pro: false,
  startingLevel: "A",
  showFirstPage: true,
  wordLevel: "A",
  highlight: true,
  playing: true,
  fontSize: 16,
};

const settingsSlice = createSlice({
  initialState,
  name: "settings",
  reducers: {
    setSettings: (state: SettingsType, action: PayloadAction<SettingsType>) => {
      return action.payload;
    },
    setSettingsTestReview: (
      state: SettingsType,
      action: PayloadAction<boolean>
    ) => {
      state.testReview = action.payload;
    },
    setSettingsAutoRead: (
      state: SettingsType,
      action: PayloadAction<boolean>
    ) => {
      state.autoRead = action.payload;
    },
    setSettingsPronunciation: (
      state: SettingsType,
      action: PayloadAction<boolean>
    ) => {
      state.pronunciation = action.payload;
    },
    setSettingsWriting: (
      state: SettingsType,
      action: PayloadAction<boolean>
    ) => {
      state.writing = action.payload;
    },
    setShowFirstPage: (state: SettingsType, action: PayloadAction<boolean>) => {
      state.showFirstPage = action.payload;
    },
    setPro: (state: SettingsType, action: PayloadAction<boolean>) => {
      state.pro = action.payload;
    },
    setSettingsStartingLevel: (
      state: SettingsType,
      action: PayloadAction<string>
    ) => {
      state.startingLevel = action.payload;
    },
    setSettingsReadSpeed: (
      state: SettingsType,
      action: PayloadAction<number>
    ) => {
      state.readSpeed = action.payload;
    },
    setSettingsWordLevel: (
      state: SettingsType,
      action: PayloadAction<string>
    ) => {
      state.wordLevel = action.payload;
    },
  },
});

export const {
  setSettingsWordLevel,
  setSettings,
  setSettingsTestReview,
  setSettingsAutoRead,
  setSettingsPronunciation,
  setSettingsWriting,
  setSettingsReadSpeed,
  setPro,
  setShowFirstPage,
} = settingsSlice.actions;

export default settingsSlice.reducer;
