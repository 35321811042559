import React from "react";
import { Form, Popup } from "semantic-ui-react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { SketchPicker } from "react-color";

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    color: {
      width: "100%",
      border: "1px solid lightgrey",
      background: "blue",
      borderRadius: ".28571429rem",
      height: "30px",
    },
  })
);

const Picker = (props: any) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Form.Field>
        <label>{props.label}</label>
        <Popup
          content={
            <SketchPicker
              color={props.colors[props.name]}
              onChangeComplete={(color: any) =>
                props.changeComplete(color, props.name)
              }
            />
          }
          on="click"
          trigger={
            <div
              className={classes.color}
              style={{ background: props.colors[props.name] }}
            />
          }
          open={props.pickers[props.name]}
          onClose={() =>
            props.setPickers({ ...props.pickers, [props.name]: false })
          }
          onOpen={() =>
            props.setPickers({ ...props.pickers, [props.name]: true })
          }
        />
      </Form.Field>
    </React.Fragment>
  );
};

export default Picker;
